import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class SetHeaderService {

  private headerSource = new BehaviorSubject('');
  currentHeader = this.headerSource.asObservable();

  constructor() { }

  changeHeader(message: string) { this.headerSource.next(message) }

}
