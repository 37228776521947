<div class="row" style="margin-top:19px!important;">
  <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" style="margin-top:-15px">
    <div style="float: right;">

      <button type="button" class="btn btn-outline" id="outline-btn" (click)="addfilter()"
        style="font-size: 12px; padding: 3px;">Add Filter </button>
    </div>
    <br>
    <hr>
    <h6 *ngIf="sortedColumnFilters.length > 0">User Filter </h6>
    <div cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropFilter($event)" class="example-list"
      style="overflow:auto;">
      <div class="example-box" *ngFor="let i = index; let rlist of sortedColumnFilters" cdkDrag>
        <div class="col-md-12">
          <div class="col-md-12"> <span style="display:flex;">{{rlist?.widget?.label}} &nbsp; <a
                (click)="editFilter(rlist)" class="cursor-css"><i class="far fa-edit" aria-hidden="true"></i></a>&nbsp;
              <a class="cursor-css" (click)="deleteFilter(rlist)"><i class="far fa-trash-alt"
                  aria-hidden="true"></i></a></span></div>
          <!-- {{rlist?.widget?.type}} -->
          <div [ngSwitch]="rlist.widget.type" class="col-md-12 uvsmttree">
            <div *ngSwitchCase="'TextBox'">
              <input #textbox [id]="rlist?.widget?.id" type="text" class="form-control" aria-readonly="true" disabled
                [placeholder]="rlist?.widget?.placeholder" style="width:130px;" />
            </div>
            <div *ngSwitchCase="'DropDown'">
              <div class="input-group">
                <select #dropdown [id]="rlist?.widget?.id" class="form-control" disabled aria-readonly="true"
                  style="width:130px;">
                </select>
              </div>
            </div>
            <div *ngSwitchCase="'StaticDropdown'">
              <div class="input-group">
                <select #dropdown [id]="rlist?.widget?.id" class="form-control" disabled aria-readonly="true"
                  style="width:130px;">
                </select>
              </div>
            </div>
            <div *ngSwitchCase="'FromDate'">
              <input #textbox [id]="rlist?.widget?.id" type="date" class="form-control" aria-readonly="true" disabled
                [placeholder]="rlist?.widget?.placeholder" />
            </div>
            <div *ngSwitchCase="'ToDate'">
              <input #textbox [id]="rlist?.widget?.id" type="date" class="form-control" aria-readonly="true" disabled
                [placeholder]="rlist?.widget?.placeholder" />
            </div>
            <div *ngSwitchCase="'AdvancedFromToDate'">
              <input #textbox [id]="rlist?.widget?.id" type="date" class="form-control" aria-readonly="true" disabled
                [placeholder]="rlist?.widget?.placeholder" />
            </div>
            <div *ngSwitchCase="'Radio'">
              <div class="input-group">
                <label class="form-check-label" style="width:130px;">
                  <input #radio [id]="rlist?.widget?.id" type="radio" class="normal" (click)="this.checked = false;" />
                  {{rlist?.widget?.label}}
                </label>
              </div>
            </div>
            <div *ngSwitchCase="'StaticRadio'">
              <div class="input-group">
                <label class="form-check-label" style="width:130px;">
                  <input #radio [id]="rlist?.widget?.id" type="radio" class="normal" (click)="this.checked = false;" />
                  {{rlist?.widget?.label}}
                </label>
              </div>
            </div>
            <div *ngSwitchCase="'CheckBox'">
              <div class="input-group">
                <label class="form-check-label" style="width:130px;">
                  <input #checkbox [id]="rlist?.widget?.id" type="checkbox" class="normal"
                    (click)="this.checked = false;" />
                  {{rlist?.widget?.label}}
                </label>
              </div>
            </div>
            <div *ngSwitchCase="'StaticCheckBox'">
              <div class="input-group">
                <label class="form-check-label" style="width:130px;">
                  <input #checkbox [id]="rlist?.widget?.id" type="checkbox" class="normal"
                    (click)="this.checked = false;" />
                  {{rlist?.widget?.label}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #filterfield>
    <div class="modal-header" id="bg-color-change">
      <h4 class="modal-title pull-left">Choose Filter Fields</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="formFilter" (ngSubmit)="onFilterSubmit(formFilter.value)">
      <div class="modal-body uvs-spacing" style="padding: 30px;">
        <div class="row">
          <div class="col-md-2">
            <label for="sel1y" class="font-small font-css">colxs </label>
            <input type="number" class="form-control" placeholder="colxs" formControlName="filtercolxs"
              style="padding: 5px;">
          </div>
          <div class="col-md-2">
            <label for="sel1y" class="font-small font-css">colmd </label>
            <input type="number" class="form-control" placeholder="colmd" formControlName="filtercolmd"
              style="padding: 5px;">
          </div>
          <div class="col-md-2">
            <label for="sel1y" class="font-small font-css">collg </label>
            <input type="number" class="form-control" placeholder="collg" formControlName="filtercollg"
              style="padding: 5px;">
          </div>
          <div class="col-md-6">
            <label for="sel1u" class="font-small font-css">Choose Field</label>
            <select class="form-control" id="sel1i" type="text" formControlName="filterFields"
              (change)="onfilterFieldsSelect($event)" [(ngModel)]="selectedFilterFields">
              <option value="" disabled selected>-Select-</option>
              <option *ngFor="let f of valueFieldList;" [value]="f.fd">{{f.fd}}</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="sel1o" class="font-small" class="text-family">Choose Type</label>
            <select class="form-control" id="sel1p" [(ngModel)]="selectedFilterType" formControlName="filterType"
              (change)="onTypeSelected($event)">
              <option value="" disabled selected>-Select-</option>
              <option *ngFor="let f of ddlType;" [value]="f.Name">{{f.Name}}</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="sel1y" class="font-small font-css">Filter Title </label>
            <input type="text" class="form-control" placeholder="Filter Title" formControlName="filterTitle">
          </div>
          <div class="col-md-12" *ngIf="selectedFilterType == 'FromDate' || selectedFilterType == 'ToDate'">
            <label for="sel1u" class="font-small font-css">Date Default Value</label>
            <select class="form-control" id="sel1i" type="text" formControlName="filterDateDefault"
              [(ngModel)]="selectedFilterDateDefault">
              <option value="" disabled selected>-Select-</option>
              <option value="today">today</option>
              <option value="yesterday">yesterday</option>
              <option value="fotm">fotm</option>
              <option value="folm">folm</option>
              <option value="foty">foty</option>
              <option value="foly">foly</option>
              <option value="fotfy">fotfy</option>
              <option value="folfy">folfy</option>
              <option value="fotq">fotq</option>
              <option value="folq">folq</option>
              <option value="7daysbefore">7daysbefore</option>
            </select>
          </div>
          <div
            *ngIf="selectedFilterType == 'DropDown' || selectedFilterType == 'Radio' || selectedFilterType == 'CheckBox'"
            class="col-md-12">
            <div class="row">
              <div class="col-md-12" style="margin-bottom: 10px;"><br />
                <span class="KOL float-style" style="font-weight:normal;">Binding Type</span>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" value="static" (change)="selectTypeHandler($event)"
                      [(ngModel)]="bindingType" #name="ngModel" [ngModelOptions]="{standalone: true}">
                    Static
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" value="dynamic" (change)="selectTypeHandler($event)"
                      [(ngModel)]="bindingType" #name="ngModel" [ngModelOptions]="{standalone: true}">
                    Dynamic
                  </label>
                </div>
              </div>
              <div *ngIf="bindingType =='static'" class="col-md-12">
                <div>
                  <div *ngIf="txtFinalItemList.length>0">
                    <div class="row" style="min-width: 100px;" *ngFor="let item of txtFinalItemList;let i1 = index"
                      style="margin-bottom: 5px;">
                      <div class="col-md-10">
                        <span style="color: #5e6063;"> {{item.name}} : {{item.value}} </span>
                      </div>
                      <div class="col-md-2">
                        <button type="button" class="btn btn-danger btn-sm pull-right" (click)="deleteItem(i1)"><i
                            class="fa fa-trash"></i></button>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="form-group">
                    <div *ngIf="isItemError" class="alert alert-danger">{{itemErrorMsg}}</div>
                    <div class="row">
                      <div class="col-md-5"> <span><label style="margin-right:5px;">Add Item</label>
                          <strong style="color:red;">*</strong>
                        </span>
                        <input type="text" class="mdl-textfield__inputs form-control left-margin-style"
                          placeholder="Item Name" name="ItemName" value="" [(ngModel)]="txtItemName"
                          (keyup)="txtItem=txtItemName" #name="ngModel" [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col-md-5"> <br /> <input type="text"
                          class="mdl-textfield__inputs form-control left-margin-css" placeholder="Item Value"
                          name="Item" [value]="" [(ngModel)]="txtItem" #name="ngModel"
                          [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col-md-2"> <br />
                        <button type="button" class="btn btn-info btn-sm pull-right" style="margin-top: 4px;"
                          (click)="addItem()"><i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div *ngIf="bindingType=='dynamic'" class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-6" *ngIf="appList.length>0">
                    <span><label class="font-css">App List</label></span>
                    <select class="mdl-textfield__input form-control float-csss" name="targetApp"
                      [(ngModel)]="selectedApp" #name="ngModel" (change)="selectAppHandler($event)"
                      [ngModelOptions]="{standalone: true}">
                      <option value="" disabled selected>-- App --</option>
                      <option *ngFor="let t of appList" [value]="t.appname">{{t.appname}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6" *ngIf="classList.length>0">
                    <span><label class="font-css">Class</label><strong style="color:red;">*</strong></span>
                    <select class="mdl-textfield__inputs form-control float-csss" formControlName="filterClass" required
                      [(ngModel)]="selectedFilterClass" (change)="selectFilterClassHandler($event)">
                      <option value="" [disabled]="true">-- Class --</option>
                      <option *ngFor="let c of classList" [value]="c">{{c.split('_')[1]}}
                      </option>
                    </select>

                  </div>
                  <div class="form-group col-md-6" *ngIf="classList.length>0">
                    <span><label class="font-css">Display Field</label><strong style="color:red;">*</strong></span>
                    <!-- <select class="mdl-textfield__inputs form-control float-csss" formControlName="filterDDLField"
                      required [(ngModel)]="selectedFilterField">
                      <option value="" disabled selected>-- Display Field --</option>
                      <option *ngFor="let fl of fieldListFilter" [value]="fl.fd">{{fl.fd}}</option>
                    </select> -->
                    <form [formGroup]="displayPointerForm" (ngSubmit)="addDisplayPointer(displayPointerForm.value)"
                      *ngIf="selectedFilterField.length==0">
                      <div style="width:100%;overflow-y:auto;">
                        <div formArrayName="displayPointerSet"
                          *ngFor="let s of displayPointerForm.get('displayPointerSet')['controls'];let i=index"
                          style="width:100%;">
                          <div [formGroupName]="i" style="width:100%;">
                            <table class="table table-bordered class-uvss" style="margin:0;width:100%;">
                              <tr>
                                <td style="width: 56.5%;">
                                  <input type="hidden" [id]="'hidden'+i" formControlName="classNm">
                                  <select [id]="'pointerr'+i" class="mdl-textfield__inputs form-control  float-csss"
                                    formControlName="displayPointer" (change)="selectDPointerHandler($event,i)">
                                    <option value="" disabled selected>-- Field/Pointer --</option>
                                    <option *ngFor="let p of getDPointerList(s.get('classNm').value)" [value]="p.fd">
                                      {{p.fd}}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div class="text-right" style="width:100%;margin-top: 10px;">
                          <button type="submit" class="btn btn-info" *ngIf="isDisplayOk">Ok</button></div>
                      </div>
                    </form>
                    <table class="col-md-12 table table-bordered" *ngIf="selectedFilterField.length>0">
                      <tbody class="b-table">
                        <tr>
                          <td> <label>{{selectedFilterField}}</label></td>
                          <td> <a class="btn btn-danger" (click)="deleteDPointer()" style="cursor: pointer;">
                              <i class="far fa-trash-alt" aria-hidden="true"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="form-group col-md-6" *ngIf="classList.length>0">
                    <span><label class="font-css">Value Field</label><strong style="color:red;">*</strong></span>
                    <!-- <select class="mdl-textfield__inputs form-control float-csss" formControlName="vDDLfield"
                      [(ngModel)]="selectedFilterValueField">
                      <option value="" disabled selected>-- Value Field --</option>
                      <option *ngFor="let fl of valueFieldListFilter" [value]="fl.fd">{{fl.fd}}</option>
                    </select> -->
                    <form [formGroup]="valuePointerForm" (ngSubmit)="addValuePointer(valuePointerForm.value)"
                      *ngIf="selectedFilterValueField.length==0">
                      <div style="width:100%;overflow-y:auto;">
                        <div formArrayName="valuePointerSet"
                          *ngFor="let s of valuePointerForm.get('valuePointerSet')['controls'];let i=index"
                          style="width:100%;">
                          <div [formGroupName]="i" style="width:100%;">
                            <table class="table table-bordered class-uvss" style="margin:0;width:100%;">
                              <tr>
                                <td style="width: 56.5%;">
                                  <input type="hidden" [id]="'hidden'+i" formControlName="classNm">
                                  <select [id]="'pointerr'+i" class="mdl-textfield__inputs form-control  float-csss"
                                    formControlName="valuePointer" (change)="selectVPointerHandler($event,i)">
                                    <option value="" disabled selected>-- Field/Pointer --</option>
                                    <option *ngFor="let p of getVPointerList(s.get('classNm').value)" [value]="p.fd">
                                      {{p.fd}}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div class="text-right" style="width:100%;margin-top: 10px;">
                          <button type="submit" class="btn btn-info" *ngIf="isValueOk">Ok</button></div>
                      </div>
                    </form>
                    <table class="col-md-12 table table-bordered" *ngIf="selectedFilterValueField.length>0">
                      <tbody class="b-table">
                        <tr>
                          <td> <label>{{selectedFilterValueField}}</label></td>
                          <td> <a class="btn btn-danger" (click)="deleteVPointer()" style="cursor: pointer;">
                              <i class="far fa-trash-alt" aria-hidden="true"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="form-group col-md-6" *ngIf="classList.length>0">
                    <span><label class="font-css">Dropdown Query</label></span>
                    <input type="text" class="mdl-textfield__inputs form-control float-csss"
                      formControlName="queryField" placeholder="Dropdown Query" />
                  </div>
                  <div class="form-group col-md-6" *ngIf="classList.length>0">
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="isPointer"
                          [ngModelOptions]="{standalone: true}">
                      </label>
                    </div>
                    <div class="form-check-inline"><br />
                      <label class="form-check-label font-css" style="margin-left: -8px;">
                        Is Pointer
                      </label>
                    </div>
                    <div *ngIf="selectedFilterType=='DropDown' && dependantShow">
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="checkbox" class="form-check-input" [(ngModel)]="dependantField"
                            (change)="selectDependant($event.target.checked)" [ngModelOptions]="{standalone: true}">
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label font-css" style="margin-left: -8px;">
                          Is Dependant
                        </label>
                      </div>
                      <div *ngIf="dependantField">
                        <div class="form-group">
                          <span><label class="font-css">Parent:</label></span>
                          <select class="mdl-textfield__inputs form-control float-css" name="pField" required
                            [(ngModel)]="pField" #name="ngModel" [ngModelOptions]="{standalone: true}">
                            <option value="" disabled selected>-- Parent --</option>
                            <option *ngFor="let p of pList" [value]="p.widget.id">{{p.widget.valueField}}</option>
                          </select>
                          <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                            <div *ngIf="name.errors?.required">
                              Please select field!
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="controlNm=='checkbox' &&  chkDependantShow">
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="checkbox" class="form-check-input" [(ngModel)]="chkDependantField"
                            (change)="selectChkDependant($event.target.checked)" [ngModelOptions]="{standalone: true}">
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label font-css" style="margin-left: -8px;">
                          Is Dependant
                        </label>
                      </div>
                      <div *ngIf="chkDependField">
                        <div class="form-group">
                          <span><label class="font-css">Parent:</label></span>
                          <select class="mdl-textfield__inputs form-control float-css" name="chkPField" required
                            [(ngModel)]="chkPField" #name="ngModel" [ngModelOptions]="{standalone: true}">
                            <option value="" disabled selected>-- Parent --</option>
                            <option *ngFor="let p of chkPList" [value]="p.id">{{p.valueField}}</option>
                          </select>
                          <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger"
                            style="margin-top: 4.4px;">
                            <div *ngIf="name.errors?.required">
                              Please select field!
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12" *ngIf="valueFieldListFilter.length>0">
                    <h6 class="text-style-css" style="margin-top:10px;">Set Order</h6>
                    <div class="row">
                      <div *ngIf="isOrderError1" class="alert alert-danger">{{orderErrorMsg1}}</div>
                      <div class="col-md-5">
                        <label class="some-padding" class="font-css" style="font-size: 13px!important;">Order
                          by</label>
                        <select name="orderBy" class="form-control placeholder-text"
                          style="padding-left: 0px;font-weight: 500;" [(ngModel)]="selectedOrder1" #name="ngModel"
                          [ngModelOptions]="{standalone: true}">
                          <option>Ascending</option>
                          <option>Descending</option>
                        </select>
                      </div>
                      <div class="col-md-5">
                        <label class="font-small font-css">Field</label>
                        <select name="orderField" class="form-control" type="text" [(ngModel)]="selectedOrderField1"
                          #name="ngModel" [ngModelOptions]="{standalone: true}">
                          <option value="" disabled selected>-Select-</option>
                          <option *ngFor="let f of valueFieldListFilter;" [value]="f.fd">{{f.fd}}</option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <label></label>
                        <div style="display: -webkit-box;">
                          <button type="button" class="btn btn-outline-info"
                            style="margin-left: -15px; margin-top: 7px;" (click)="addOrderList1()">+</button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="orderFieldList1 != null && orderFieldList1.length > 0">
                      <div>
                        <div style="width:410px;overflow-x: auto;">
                          <table class="table table-bordered table-responsive-sm uvs9table-side">
                            <tr>
                              <th>Field</th>
                              <th>Order By</th>
                              <th>Delete</th>
                            </tr>
                            <tr *ngFor="let i = index; let rlist of orderFieldList1">
                              <td>{{rlist?.field}}</td>
                              <td>{{rlist?.order}}</td>
                              <td><a class="btn btn-info" (click)="deleteFieldOrder1(rlist)"><i
                                    class="far fa-trash-alt"></i></a>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="selectedFilterType=='AdvancedFromToDate'">
            <div class="col-md-6">
              <label for="sel1u" class="font-small font-css">From Date</label>
              <div class="form-group">
                <label for="sel1s" class="font-small font-css">Label</label>
                <input type="text" class="form-control" placeholder="Label" name="fLabel" value="" [(ngModel)]="fLabel"
                  #name="ngModel" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="form-group">
                <span><label class="font-css">Time Select</label></span>
                <select class="mdl-textfield__inputs form-control float-csss" name="fshowTimeSelect"
                  [(ngModel)]="fselectedShowTimeSelect" #name="ngModel" required [ngModelOptions]="{standalone:true}">
                  <option value="" disabled selected>--Select--</option>
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="form-group">
                <span><label class="font-css">Only Time</label></span>
                <select class="mdl-textfield__inputs form-control float-csss" name="fshowTimeSelectOnly"
                  [(ngModel)]="fselectedOnlyTimeSelect" #name="ngModel" required [ngModelOptions]="{standalone:true}">
                  <option value="" disabled selected>--Select--</option>
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Min Date</label>
                </span>
                <input type="number" class="mdl-textfield__inputs form-control left-margin-style" placeholder="0"
                  name="fminDate" value="" [(ngModel)]="txtfMinDate" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Max Date</label>
                </span>
                <input type="number" class="mdl-textfield__inputs form-control left-margin-style" placeholder="0"
                  name="fmaxDate" value="" [(ngModel)]="txtfMaxDate" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Time Interval</label>
                </span>
                <input type="number" class="mdl-textfield__inputs form-control left-margin-style" placeholder="0"
                  name="ftimeIntervals" value="" [(ngModel)]="txtfTimeInterval" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Time Caption</label>
                </span>
                <input type="text" class="mdl-textfield__inputs form-control left-margin-style"
                  placeholder="Time Caption" name="ftimeCaption" value="" [(ngModel)]="txtfTimeCaption" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Time Format</label>
                </span>
                <select class="mdl-textfield__inputs form-control float-csss" name="ftimeFormat"
                  [(ngModel)]="txtfTimeFormat" #name="ngModel" required [ngModelOptions]="{standalone:true}">
                  <option value="" disabled selected>--Select--</option>
                  <option value="HH:mm">HH:mm</option>
                  <option value="p">p</option>
                </select>
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Date Format</label>
                </span>
                <select class="mdl-textfield__inputs form-control float-csss" name="fdateFormat"
                  [(ngModel)]="txtfDateFormat" #name="ngModel" required [ngModelOptions]="{standalone:true}">
                  <option value="" disabled selected>--Select--</option>
                  <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                  <option value="yyyy/MM/dd">yyyy/MM/dd</option>
                  <option value="MMMM d, yyyy h:mm aa">MMMM d, yyyy h:mm aa</option>
                  <option value="MM/dd/yyyy h:mm aa">MM/dd/yyyy h:mm aa</option>
                  <option value="dd/MM/yyyy h:mm aa">dd/MM/yyyy h:mm aa</option>
                  <option value="MMMM d, yyyy h:mm aa">MMMM d, yyyy h:mm aa</option>
                  <option value="MM/yyyy">MM/yyyy</option>
                  <option value="yyyy, QQQ">yyyy, QQQ</option>
                  <option value="h:mm aa">h:mm aa</option>
                  <option value="yyyy">yyyy</option>
                  <option value="Pp">Pp</option>
                </select>
              </div>
              <div class="form-group">
                <label for="sel1s" class="font-small font-css">Placeholder</label>
                <input type="text" class="form-control" placeholder="Put placeholder here" name="fPlaceholder" value=""
                  [(ngModel)]="fPlaceholder" #name="ngModel" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="form-group">
                <span><label style="margin-right:45px;" class="col-form-label font-css">Help Body</label>
                </span>
                <input type="text" class="mdl-textfield__inputs form-control left-margin-style" placeholder="help body"
                  name="fhelpBody" value="" [(ngModel)]="txtfHelpBody" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" (click)="openEditorModal()" />
              </div>
              <div class="form-group">
                <span><label style="margin-right: 45px;" class="col-form-label font-css">Help Link</label></span>
                <input type="text" class="mdl-textfield__inputs form-control left-margin-style" placeholder="help link"
                  name="fhelpLink" value="" [(ngModel)]="txtfHelpLink" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <label for="sel1u" class="font-small font-css">To Date</label>
              <div class="form-group">
                <label for="sel1s" class="font-small font-css">Label</label>
                <input type="text" class="form-control" placeholder="Label" name="tLabel" value="" [(ngModel)]="tLabel"
                  #name="ngModel" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="form-group">
                <span><label class="font-css">Time Select</label></span>
                <select class="mdl-textfield__inputs form-control float-csss" name="tshowTimeSelect"
                  [(ngModel)]="tselectedShowTimeSelect" #name="ngModel" required [ngModelOptions]="{standalone:true}">
                  <option value="" disabled selected>--Select--</option>
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="form-group">
                <span><label class="font-css">Only Time</label></span>
                <select class="mdl-textfield__inputs form-control float-csss" name="tshowTimeSelectOnly"
                  [(ngModel)]="tselectedOnlyTimeSelect" #name="ngModel" required [ngModelOptions]="{standalone:true}">
                  <option value="" disabled selected>--Select--</option>
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Min Date</label>
                </span>
                <input type="number" class="mdl-textfield__inputs form-control left-margin-style" placeholder="0"
                  name="tminDate" value="" [(ngModel)]="txttMinDate" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Max Date</label>
                </span>
                <input type="number" class="mdl-textfield__inputs form-control left-margin-style" placeholder="0"
                  name="tmaxDate" value="" [(ngModel)]="txttMaxDate" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Time Interval</label>
                </span>
                <input type="number" class="mdl-textfield__inputs form-control left-margin-style" placeholder="0"
                  name="ttimeIntervals" value="" [(ngModel)]="txttTimeInterval" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Time Caption</label>
                </span>
                <input type="text" class="mdl-textfield__inputs form-control left-margin-style"
                  placeholder="Time Caption" name="timeCaption" value="" [(ngModel)]="txttTimeCaption" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Time Format</label>
                </span>
                <select class="mdl-textfield__inputs form-control float-csss" name="ttimeFormat"
                  [(ngModel)]="txttTimeFormat" #name="ngModel" required [ngModelOptions]="{standalone:true}">
                  <option value="" disabled selected>--Select--</option>
                  <option value="HH:mm">HH:mm</option>
                  <option value="p">p</option>
                </select>
              </div>
              <div class="form-group">
                <span><label style="margin-right:5px;" class="col-form-label font-css">Date Format</label>
                </span>
                <select class="mdl-textfield__inputs form-control float-csss" name="tdateFormat"
                  [(ngModel)]="txttDateFormat" #name="ngModel" required [ngModelOptions]="{standalone:true}">
                  <option value="" disabled selected>--Select--</option>
                  <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                  <option value="yyyy/MM/dd">yyyy/MM/dd</option>
                  <option value="MMMM d, yyyy h:mm aa">MMMM d, yyyy h:mm aa</option>
                  <option value="MM/dd/yyyy h:mm aa">MM/dd/yyyy h:mm aa</option>
                  <option value="dd/MM/yyyy h:mm aa">dd/MM/yyyy h:mm aa</option>
                  <option value="MMMM d, yyyy h:mm aa">MMMM d, yyyy h:mm aa</option>
                  <option value="MM/yyyy">MM/yyyy</option>
                  <option value="yyyy, QQQ">yyyy, QQQ</option>
                  <option value="h:mm aa">h:mm aa</option>
                  <option value="yyyy">yyyy</option>
                  <option value="Pp">Pp</option>
                </select>
              </div>
              <div class="form-group">
                <label for="sel1s" class="font-small font-css">Placeholder</label>
                <input type="text" class="form-control" placeholder="Put placeholder here" name="tPlaceholder" value=""
                  [(ngModel)]="tPlaceholder" #name="ngModel" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="form-group">
                <span><label style="margin-right:45px;" class="col-form-label font-css">Help Body</label>
                </span>
                <input type="text" class="mdl-textfield__inputs form-control left-margin-style" placeholder="help body"
                  name="thelpBody" value="" [(ngModel)]="txttHelpBody" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" (click)="openEditorModal()" />
              </div>
              <div class="form-group">
                <span><label style="margin-right: 45px;" class="col-form-label font-css">Help Link</label></span>
                <input type="text" class="mdl-textfield__inputs form-control left-margin-style" placeholder="help link"
                  name="thelpLink" value="" [(ngModel)]="txttHelpLink" #name="ngModel"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="sel1y" class="font-small font-css">Background Color </label>
            <input type="color" class="form-control" placeholder="Background  Color" formControlName="filterBgColor"
              [(ngModel)]="backColorModel">
          </div>
          <div class="col-md-6">
            <label for="sel1s" class="font-small font-css">Placeholder</label>
            <input type="text" class="form-control" placeholder="Put placeholder here"
              formControlName="filterPlaceholder">
          </div>
          <div class="col-md-6">
          </div>
          <div class="col-md-6">
          </div>
        </div>
        <div class="row">

          <div class="col-md-12">
            <label for="sel1s" class="font-small font-css">Description</label>
            <input type="text" class="form-control" placeholder="Put description here" formControlName="filterDesc">
          </div>
          <!-- <div class="col-md-12">
        <input type="checkbox" id="one" formControlName="filtOrder"  (change)="selectFilterOrder($event.target.checked)"> <span style="font-size: 12px;">&nbsp;&nbsp;Set Order</span>
      </div> -->
          <div class="col-md-12" *ngIf="selectedFilterType == 'TextBox' || selectedFilterType=='AdvancedFromToDate'">
            <input type="checkbox" formControlName="filtAggKey"> <span style="font-size: 12px;">&nbsp;&nbsp;Aggregate
              Key</span>
          </div>
          <div class="col-md-12">
            <label for="sel1s" class="font-small font-css">Where Query</label>
            <app-custom-query-builder [receivedParentMessage]="messageToSendP" (messageToEmit)="getMessage($event)">
            </app-custom-query-builder>
            <input type="text" class="form-control" placeholder="Put query here" formControlName="filterWhereCondition"
              [(ngModel)]='receivedChildMessage' />
            <div class="pull-right help-tip">
              <p>Please edit query for dynamic variables. Use '#variableName#' instead of static values. </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" class="footer-change" style="text-align: center;">
        <button type="submit" class="btn btn-info" style="width: 131px; height: 39px;margin-right: 40px;">
          Submit
        </button>
        <button type="button" class="btn btn-light" (click)="modalRef.hide()"
          style="width:131px; height: 39px;">Cancel</button>
      </div>
    </form>
  </ng-template>