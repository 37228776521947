import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MatProgressBarModule, MatSlideToggleModule } from '@angular/material';;
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { SetHeaderService } from './shared/set-header.service';
import { SetFooterService } from './shared/set-footer.service';
import { SetFooterVersionService } from './shared/set-footer-version.service';
import { NgxPaginationModule } from 'ngx-pagination/dist/ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { AuthGuard } from './auth.guard';
import {
  MatCardModule, MatIconModule, MatCheckboxModule, MatSelectModule, MatButtonModule,
  MatInputModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SafePipe } from './pipe/safe.pipe';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login/angularx-social-login";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OrganizationComponent } from './component/organization/organization.component';
import { DataSharingService } from './shared/data-sharing.service';
import { Controls } from './class/controls';
import { ToastrModule } from 'ngx-toastr';
import { ColorPickerModule } from 'ngx-color-picker/dist/ngx-color-picker';
import { ChartsModule } from 'ng2-charts';
import { Example1Component } from './component/example1/example1.component';
import { Example2Component } from './component/example2/example2.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { MatTableModule, MatTreeModule } from '@angular/material';
import { IgxButtonModule, IgxToggleModule } from "igniteui-angular";
import { MatChipsModule } from '@angular/material/chips';
import { EditProfileComponent } from './component/edit-profile/edit-profile.component';
import { EditOrganizationComponent } from './component/edit-organization/edit-organization.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { CarddashboardComponent } from './component/carddashboard/carddashboard.component';
import { DatePipe } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { Currency } from './class/currencyData';
import { Country } from './class/countryList';
import { CustomLabelComponent } from './component/custom-label/custom-label.component';
import { CustomHtmlComponent } from './component/custom-html/custom-html.component';
import { CustomTableComponent } from './component/custom-table/custom-table.component';
import { Example3Component } from './component/example3/example3.component';
import { ListdashboardComponent } from './component/listdashboard/listdashboard.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#16b4e9',
  bgsPosition: POSITION.centerCenter,
  bgsOpacity: 0.10,
  bgsSize: 60,
  fgsColor: '#16b4e9',
  fgsSize: 60,
  fgsPosition: POSITION.centerCenter,
  bgsType: SPINNER.doubleBounce, // background spinner type
  fgsType: SPINNER.ballSpinClockwiseFadeRotating, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 7, // progress bar thickness
  pbColor: '#16b4e9'
};

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('585324622598-t6o7s4q291qokbb2r28s9djatvdboir4.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('2654453877917395')
  }
]);

export function provideConfig() { return config; }

@NgModule({
  entryComponents: [
    Example1Component,
    Example2Component,
    CarddashboardComponent,
    CustomLabelComponent,
    CustomHtmlComponent,
    CustomTableComponent,
    Example3Component,
    ListdashboardComponent
  ],
  declarations: [
    AppComponent,
    SafePipe,
    OrganizationComponent,
    Example1Component,
    Example2Component,
    EditProfileComponent,
    EditOrganizationComponent,
    ForgotPasswordComponent,
    CarddashboardComponent,
    CustomLabelComponent,
    CustomHtmlComponent,
    CustomTableComponent,
    Example3Component,
    ListdashboardComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatTableModule,
    HttpClientModule,
    MatProgressBarModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgxSpinnerModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxPaginationModule,
    MatCardModule,
    MatIconModule,
    DragDropModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    ColorPickerModule,
    ChartsModule,
    IgxButtonModule,
    IgxToggleModule,
    MatChipsModule,
    NgJsonEditorModule, CKEditorModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true
    }),
    ModalModule.forRoot(),
    AppRoutingModule
  ],

  providers: [SetHeaderService, SetFooterService, SetFooterVersionService, AuthGuard,
    DataSharingService, Controls, DatePipe, Ng4LoadingSpinnerService, Currency, Country],
  bootstrap: [AppComponent]
})
export class AppModule {

}
