import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataSharingService } from 'src/app/shared/data-sharing.service';
import { configuration } from 'src/app/config/config';

@Component({
  selector: 'app-mainapp',
  templateUrl: './mainapp.component.html',
  styleUrls: ['./mainapp.component.css']
})

export class MainappComponent implements OnInit {

  title = 'XAP.AI-Admin'; accessType: string;
  // Define a variable to use for showing/hiding the Login button
  isUserLoggedIn: boolean;

  constructor(private route: ActivatedRoute, private dataSharingService: DataSharingService, private router: Router) {

    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
    });

    this.dataSharingService.userType.subscribe(value => {
      this.accessType = value;
    });

    console.log(this.accessType);

    var url = window.location.hostname;
    console.log(url);
    if (url == 'appcreator.qik.ai') {
      //commenting out this code as it setting localstorage even when its not neeed and interfering with the initial setup
      //localStorage.setItem("serverUrl", configuration.SERVER_URL);
      //localStorage.setItem("parseKey", configuration.PARSE_KEY);
      localStorage.setItem("imgUrl", configuration.IMG_URL);
      localStorage.setItem("urlOnly", configuration.sURL);
      localStorage.setItem("platform", "prod");
      localStorage.setItem("target", configuration.sTarget);
    } else if (url == 'uat-appcreator.qik.ai') {
      localStorage.setItem("serverUrl", configuration.UAT_SERVER_URL);
      localStorage.setItem("parseKey", configuration.UAT_PARSE_KEY);
      localStorage.setItem("imgUrl", configuration.UAT_IMG_URL);
      localStorage.setItem("urlOnly", configuration.uURL);
      localStorage.setItem("platform", "uat");
      localStorage.setItem("target", configuration.uTarget);
    } else {
      localStorage.setItem("serverUrl", configuration.DEV_SERVER_URL);
      localStorage.setItem("parseKey", configuration.DEV_PARSE_KEY);
      localStorage.setItem("imgUrl", configuration.DEV_IMG_URL);
      localStorage.setItem("urlOnly", configuration.dURL);
      localStorage.setItem("platform", "dev");
      localStorage.setItem("target", configuration.dTarget);
    }
    console.log(localStorage.getItem("serverUrl"));
    console.log(localStorage.getItem("parseKey"));
    console.log(localStorage.getItem("imgUrl"));
    console.log(localStorage.getItem("platform"));
    console.log(localStorage.getItem("target"));

  }

  ngOnInit(): void {  }
  
  clickApp() { this.router.navigate(['appInfo']); }

  clickQApp() { this.router.navigate(['queryBuilder']); }

  clickFilterApp() { this.router.navigate(['filterBuilder']); };

  clickMApp() { this.router.navigate(['mobile']); }

  clickSchema() { this.router.navigate(['validateSchemas']) };

  clickEditor() { this.router.navigate(['appEditors']) };

  clickOrg() { this.router.navigate(["orgInfo"]) };

  clickPlans() { this.router.navigate(['plans']) };

}
