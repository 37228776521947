import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AppServiceService {

  constructor(private http: HttpClient) { }

  async loginApi(username:string, password:string){

    const httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id':localStorage.getItem("parseKey")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/login?username=${username}&password=${password}`;
    console.log(url);
    return await this.http.get(url, httpOptions2).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error;
      });

  }

  async getAppList(body:any) {

    const httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id':localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    let url =`${localStorage.getItem("serverUrl")}/functions/Applist`;
    return await this.http.post(url, body, httpOptions2).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error["error"];
      });

  }

  async getMAppList() {

    const httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id':localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    let url =`${localStorage.getItem("serverUrl")}/classes/app_BuildInfo`;
    return await this.http.get(url, httpOptions2).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error["error"];
      });

  }

  async getAppListByOrgRole(orgRoleId:any){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/roles?where={"$relatedTo":{"object":{"__type":"Pointer","className":"_Role","objectId":"${orgRoleId}"},"key":"roles"}}`;
    console.log(url);
    return await this.http.get(url, httpOptions).toPromise().then(
      result => {
        return result["results"];
      }, (error) => {
        return error;
      });
      
  }

  async getAppDetailList(appArray:any){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/classes/w_appinfo?where={"appname":{"$in":${appArray}}}`;
    console.log(url);
    return await this.http.get(url, httpOptions).toPromise().then(
      result => {
        return result["results"];
      }, (error) => {
        return error;
      });
      
  }

async deleteApp(body: any) {

  const httpOptions3 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': localStorage.getItem("parseKey")
    })
  };

  var url = `${localStorage.getItem("serverUrl")}/functions/DeleteApp`;
  return await this.http.post(url, body, httpOptions3).toPromise().then(
    result => {
      console.log(result);
      return result;
    }, (error) => {
      console.log(error);
      return error["error"];
    });

}


}
