import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})

export class DataSharingService {

  public isUserLoggedIn:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(Boolean(localStorage.getItem("loginStatus")));
  public userName:BehaviorSubject<string>=new BehaviorSubject<string>(String(localStorage.getItem("userName")));
  public userType:BehaviorSubject<string>=new BehaviorSubject<string>(String(localStorage.getItem("userType")));
  public orgName:BehaviorSubject<string>=new BehaviorSubject<string>(String(localStorage.getItem("orgName")));

}
