import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as Parse from 'parse';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})

@Injectable({
  providedIn: 'root'
})

export class OrganizationComponent implements OnInit {

  orgForm: FormGroup;

  emailExists: boolean = false; orgExists: boolean = false;

  existsUserId: string = ""; orgId: any = ""; selectedUserName: string = "";

  constructor(private formbuilder: FormBuilder, private router: Router, private http: HttpClient,
    private ngxService: NgxUiLoaderService, private toastr: ToastrService) {
  }

  public loadScript(url: string) {

    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);

  }

  ngOnInit() {

    this.pageLoader();
    Parse.initialize(localStorage.getItem("parseKey"));
    (Parse as any).serverURL = localStorage.getItem("serverUrl");

    this.loadScript('../assets/js/jquery-3.3.1.min.js');
    this.loadScript('../assets/js/reg.js');
    this.loadScript('../assets/js/jquery.steps.js');

    this.orgForm = this.formbuilder.group({
      orgName: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 .-]*$"), Validators.maxLength(100)]],
      contactName: ['', [Validators.required, Validators.maxLength(100), Validators.pattern("^[a-zA-Z ]*$")]],
      emailId: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      ContactNo: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("[0-9]{10}")]],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.maxLength(6)]],
      orgType: [''],
      password: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_@&#.\s]{6,15}$")]],
      confirmPass: ['', Validators.required]
    },
      {
        validator: this.matchingPasswords('password', 'confirmPass')
      });

  }

  pageLoader(): void {

    // start foreground spinner of the master loader with 'default' taskId
    this.ngxService.start();
    // Stop the foreground loading after 5s
    setTimeout(() => {
      // stop foreground spinner of the master loader with 'default' taskId
      this.ngxService.stop();
    }, 2000);

    // OR
    this.ngxService.startBackground('do-background-things');
    // Do something here...
    this.ngxService.stopBackground('do-background-things');

    // start foreground spinner of the loader "loader-01" with 'default' taskId
    this.ngxService.startLoader('loader-01');
    // Stop the foreground loading after 5s
    setTimeout(() => {
      // stop foreground spinner of the loader "loader-01" with 'default' taskId
      this.ngxService.stopLoader('loader-01');
    }, 2000);

  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {

    return (group: any): { [key: string]: any } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return { mismatchedPasswords: true };
      }
    }

  }

  async checkAccExists(emailId: String) {

    const userEmail = Parse.Object.extend("_User");
    const emails = new userEmail();
    const query = new Parse.Query(emails);

    var userId;

    query.equalTo("username", emailId);
    await query.find().then(function (xx) {
      if (xx.length > 0) { userId = xx[0].id; }
      else { userId = null; }
    });

    this.existsUserId = userId;

    if (userId != null) { this.emailExists = true; }
    else { this.emailExists = false; }
  }

  async createOrg(orgForm: NgForm) {

    debugger;

    var message: any;
    var userExists: boolean = false;

    await this.checkOrgExists(orgForm['orgName']);
    if (this.orgExists) { this.toastr.error("Oganization already exists!", "Registration Fails!"); }
    else {
      if (this.selectedUserName == "email") {
        //call method to check if user is already superadmin of another org
        await this.checkAccExists(orgForm['emailId']);
      } else if (this.selectedUserName == "phone") {
        await this.checkAccExists(orgForm["ContactNo"]);
      }
      if (this.emailExists) {
        userExists = await this.checkUserType(this.existsUserId);
        if (userExists) { this.toastr.error("Account with this username already exists!", "Registration Fails!"); }
        else {
          //Get role class     
          const role = Parse.Object.extend("_Role");
          const roles = new role();
          const roleQuery = new Parse.Query(roles);
          this.pageLoader();
          //save org data
          const organization = Parse.Object.extend("w_organization");
          const org = new organization();

          org.set("orgName", orgForm['orgName']),
            org.set("contactName", orgForm['contactName']),
            org.set("emailId", orgForm['emailId']),
            org.set("ContactNo", orgForm['ContactNo']),
            org.set("country", orgForm['country']),
            org.set("state", orgForm['state']),
            org.set("city", orgForm['city']),
            org.set("address", orgForm['address']),
            org.set("pincode", orgForm['pincode']),
            org.set("orgType", orgForm['orgType'])
          await org.save()
            .then(async (res: any) => {
              this.orgId = res.id;

              if (this.existsUserId != null) {
                var orgName = orgForm['orgName'].replace(' ', '-');
                // crete org role
                var orgRoleName = orgName + "_org";

                var userRelation: any = {
                  "__op": "AddRelation",
                  "objects": [
                    {
                      "__type": "Pointer",
                      "className": "_User",
                      "objectId": this.existsUserId
                    }]
                }
                const roleACL = new Parse.ACL();
                roleACL.setPublicReadAccess(true);
                roleACL.setPublicWriteAccess(true);
                const role = new Parse.Object("_Role");
                role.set("name", orgRoleName);
                role.set("users", userRelation);
                role.setACL(roleACL);
                await role.save();

                debugger;
                //save user & org relation with accessType
                const userOrgInfo = Parse.Object.extend("w_userOrgRelation");
                const userOrg = new userOrgInfo();

                var orgPointer = {
                  __type: 'Pointer',
                  className: 'w_organization',
                  objectId: this.orgId
                }

                var userPointer = {
                  __type: 'Pointer',
                  className: '_User',
                  objectId: this.existsUserId
                }

                userOrg.set("orgId", orgPointer);
                userOrg.set("userId", userPointer);
                userOrg.set("accessType", "superAdmin");
                userOrg.set("isActive", true);
                await userOrg.save();
                message = "success";
              } else { message = "User not created!"; }

              if (message == "success") {
                this.toastr.success('Registration Successfull!!! You will be able to login once your registration is approve!', 'Success!!!');
                this.router.navigate(['login']);
              } else { this.toastr.error('Error in registration: ' + message, 'Error'); }
            }, (error: any) => { this.toastr.error('Error in registration: ' + error.message, 'Error'); });
        }
      } else {
        //Get role class     
        const role = Parse.Object.extend("_Role");
        const roles = new role();
        const roleQuery = new Parse.Query(roles);
        this.pageLoader();
        //save org data
        const organization = Parse.Object.extend("w_organization");
        const org = new organization();

        org.set("orgName", orgForm['orgName']),
          org.set("contactName", orgForm['contactName']),
          org.set("emailId", orgForm['emailId']),
          org.set("ContactNo", orgForm['ContactNo']),
          org.set("country", orgForm['country']),
          org.set("state", orgForm['state']),
          org.set("city", orgForm['city']),
          org.set("address", orgForm['address']),
          org.set("pincode", orgForm['pincode']),
          org.set("orgType", orgForm['orgType'])
        await org.save()
          .then(async (res: any) => {
            this.orgId = res.id;
            // If Success, save data to User 
            const user = Parse.Object.extend("_User");
            const users = new user();

            var userName = orgForm['emailId'];
            if (this.selectedUserName == "email") { userName = orgForm['emailId']; }
            else if (this.selectedUserName == "phone") { userName = orgForm['ContactNo']; }

            users.set("username", userName),
              users.set("password", orgForm['password']),
              users.set("email", orgForm['emailId']),
              users.set("phone", "" + orgForm['ContactNo'] + ""),
              users.set("name", orgForm['contactName'])
            await users.save()
              .then(async (res1: any) => {
                this.existsUserId = res1.id;
              });

            if (this.existsUserId != null) {
              var orgName = orgForm['orgName'].replace(' ', '-');
              // crete org role
              var orgRoleName = orgName + "_org";

              var userRelation: any = {
                "__op": "AddRelation",
                "objects": [
                  {
                    "__type": "Pointer",
                    "className": "_User",
                    "objectId": this.existsUserId
                  }]
              }
              const roleACL = new Parse.ACL();
              roleACL.setPublicReadAccess(true);
              roleACL.setPublicWriteAccess(true);
              const role = new Parse.Object("_Role");
              role.set("name", orgRoleName);
              role.set("users", userRelation);
              role.setACL(roleACL);
              await role.save();

              debugger;
              //save user & org relation with accessType
              const userOrgInfo = Parse.Object.extend("w_userOrgRelation");
              const userOrg = new userOrgInfo();

              var orgPointer = {
                __type: 'Pointer',
                className: 'w_organization',
                objectId: this.orgId
              }

              var userPointer = {
                __type: 'Pointer',
                className: '_User',
                objectId: this.existsUserId
              }

              userOrg.set("orgId", orgPointer);
              userOrg.set("userId", userPointer);
              userOrg.set("accessType", "superAdmin");
              userOrg.set("isActive", true);
              await userOrg.save();
              message = "success";
            } else { message = "User not created!"; }

            if (message == "success") {
              this.toastr.success('Registration Successfull!!! You will be able to login once your registration is approve!', 'Success!!!');
              this.router.navigate(['login']);
            } else { this.toastr.error('Error in registration: ' + message, 'Error'); }
          }, (error: any) => { this.toastr.error('Error in registration: ' + error.message, 'Error'); });
      }
    }

  }

  async checkOrgExists(orgName: String) {

    const userOrg = Parse.Object.extend("w_organization");
    const orgDet = new userOrg();
    const query = new Parse.Query(orgDet);
    var orgId;

    query.equalTo("orgName", orgName);
    await query.find().then(function (xx) {
      if (xx.length > 0) { orgId = xx[0].id; }
      else { orgId = null; }
    });

    if (orgId != null) { this.orgExists = true; }
    else { this.orgExists = false; }

  }

  async checkUserType(userId: string) {

    const userInfo = Parse.Object.extend("w_userOrgRelation");
    const userDet = new userInfo();
    const query = new Parse.Query(userDet);

    var isExists;
    var userPointer = { "__type": "Pointer", "className": "_User", "objectId": userId };

    query.equalTo("userId", userPointer);
    query.equalTo("accessType", "superAdmin");
    await query.find().then(function (xx) {
      if (xx.length > 0) { isExists = true; }
      else { isExists = false; }
    });
    return isExists;

  }

}
