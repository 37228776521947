<div class="container GTRY">
  <div class="row">
    <div class="main_head">
      <div class="main-logo">
        <img src="../assets/img/mainlogin_logo.svg" width="100%" />
      </div>
    </div>
    <div class="col-sm-6 KLO">
      <h2>Welcome Back !</h2>
      <span class="KNLO">Reset Your Password</span>
      <form [formGroup]="emailForm">
        <div class="card card-box">
          <div class="card-body QWE">
            <label for="UserName">Email Address</label>
            <input formControlName="Username" type="email" class="form-control" placeholder="" required
              pattern="\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*" />
            <div *ngIf="emailForm.controls.Username.touched && emailForm.controls.Username.errors"
              class="alert alert-danger">
              <span *ngIf="emailForm.controls.Username.hasError('required')">Username is required.</span>
              <span *ngIf="emailForm.controls.Username.hasError('pattern')">Invalid Username</span>
            </div>
          </div>
        </div>
        <div class="SYTU">
          <div class="col-sm-6">
            <button class="btn btn-info btn-block" (click)="onFormSubmit(emailForm.value)"
              [disabled]="emailForm.invalid">Submit</button>
          </div>
          <div class="col-sm-6">
            <a routerLink="/login">Login</a>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-6 KLO1">
      <img src="../assets/img/login.svg" width="100%" />
    </div>
  </div>
</div>