<div class="Rectangle-42" *ngIf="isUserLoggedIn">
  <div class="main-logo">
    <img src="../assets/img/main_logo.svg" width="100%" />
  </div>
  <div class="dropdown pull-right BGTH">
    <span style="color:#fff">Account Type: {{accessType}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a href="#" style="color:#fff;" data-toggle="collapse" data-target="#demouvs2">
      <i class="fa fa-user"></i> {{userName}} <i class="fa fa-caret-down"></i>
    </a>
    <div id="demouvs2" class="dropdown-menu dropdown-menu-right collapse">
      <a class="dropdown-item" href="javascript:void(0)">{{orgName}}</a>
      <a *ngIf="accessType=='superAdmin' || accessType=='platformAdmin'" class="dropdown-item" href="javascript:void(0)"
        (click)="clickOrgInfo()"><i class="fas fa-building" aria-hidden="true"></i> Edit Organization</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="clickProfile()"><i class="fas fa-address-book"
          aria-hidden="true"></i> Edit Profile</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="changePass()"><i class="fas fa-key"
          aria-hidden="true"></i> Change Password</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"><i class="fas fa-sign-out-alt"
          aria-hidden="true"></i>
        Logout</a>
    </div>
  </div>
</div>
<nav class="navbar  navbar-inverse" *ngIf="isUserLoggedIn">
  <ul class="nav navbar-nav">
    <li><a href="javascript:void(0)" data-activates="slide-out" id="bars" class="button-collapse black-text"
        onclick="openNav()" style="font-size:22px;"><i class="fa fa-bars"></i></a></li>
  </ul>
  <div style="display: flex;">
    <ul class="nav navbar-nav navbar-right acce_btn" style="margin-right:11px;" *ngIf="accessUrl!='#'">
      <li><a (click)="onBuildApk()" id="link-css-style">Build APK</a></li>
    </ul>
    <ul class="nav navbar-nav navbar-right acce_btn" style="margin-right:11px" *ngIf="accessUrl!='#'">
      <li><a (click)="onPublishApk()" id="link-css-style">Publish App</a></li>
    </ul>
    <ul class="nav navbar-nav navbar-right acce_btn" *ngIf="accessUrl!='#' && isAccessBtnDisabled ==true">
      <li><a target="_blank" class="isDisabled">Access
          Application</a>
      </li>
    </ul>
    <ul class="nav navbar-nav navbar-right acce_btn" *ngIf="accessUrl!='#' && isAccessBtnDisabled ==false">
      <li><a [href]="accessUrl" target="_blank">Access
          Application</a>
      </li>
    </ul>
  </div>
</nav>

<ng-template #addbuildmtemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ModalTitle}}</h4>
    <div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
      <a (click)="editBuildApk()" style="margin-right: 14px;font-size: 17px; color: #16b4e9;" *ngIf="isEditShow"><i
          class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
      <a (click)="showBuildHistory()" style="margin-right: 14px;font-size: 17px; color: #16b4e9; cursor:pointer"
        *ngIf="isShow"><i class="fa fa-history" aria-hidden="true"></i></a>
    </div>
  </div>
  <div class="modal-body" style="padding: 0px;padding-left: 30px;
    padding-right: 30px;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <div id="div_buildHistory" style="margin-top: 14px;" *ngIf="isHistoryShow">
        <div class="card card-body">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Sr. No</th>
                <th scope="col">Build By</th>
                <th scope="col">Build Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let i = index; let rlist of dbList | paginate: { itemsPerPage: 2, currentPage: p } ">
                <td scope="row">{{ (p-1)*2+(i+1) }}</td>
                <td>{{rlist?.loginUserId.name}}</td>
                <td>{{rlist.createdAt | date: 'dd/MM/yyyy'}}</td>
              </tr>
            </tbody>
          </table>
          <p style="text-align: center;" *ngIf="dbList.length==0">
            No history to display!
          </p>
          <p class="pagination NBVC" *ngIf="dbList.length>2">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </p>
        </div>
      </div>
      <div id="div_triggerBuild" *ngIf="isTriggerApkShow">
        <form [formGroup]="formApp" (ngSubmit)="onBuildSubmit(formApp.value)">
          <div class="hidden">
            <input type="text" formControlName="objectId" placeholder="objectId" name="objectId" class="form-control"
              id="objectId">
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-12">
              <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                style="padding: 0px 0px;">
                <label for="app-name" class="font-css" style="margin-top: 25px; font-size: 14px;">mApp Name <strong
                    style="color: red; ">*</strong></label>
                <input class="mdl-textfield__input form-control" type="text" minlength="1" maxlength="30"
                  formControlName="appname" placeholder="App Name">
              </div>
              <div
                *ngIf="formApp.controls['appname'].invalid && (formApp.controls['appname'].dirty || formApp.controls['appname'].touched)"
                class="alert alert-danger">
                <div *ngIf="formApp.controls['appname'].errors.required">
                  App Name is required!
                </div>
                <div *ngIf="formApp.controls['appname'].errors.pattern">
                  1] Valid characters: AlphaNumerics, dash, underscore,@!
                </div>
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="font-css" style="margin-top: 25px;">Fb App ID</label>
                <input type="text" class="mdl-textfield__input form-control" placeholder="Fb App ID"
                  formControlName="fbAppId">
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="font-css" style="margin-top: 25px;">Color Accent</label>
                <input type="text" class="mdl-textfield__input form-control" placeholder="Color Accent"
                  formControlName="colorAccent">
              </div>
              <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                style="padding: 0px 0px;">
                <label for="email" class="font-css" style="margin-top: 25px; font-size: 14px;">Send APK To</label>
                <!-- <p style="color: darkgray;font-size: 11px; margin: 0px;">(Multiple emails should be seperated by comma
                (,))</p> -->
                <textarea class="form-control" formControlName="emailID" rows="2" value="emailID" placeholder="Enter email ID (Multiple emails should be seperated by comma
                  (,))"></textarea>
              </div>
              <!-- <mat-form-field class="example-chip-list" style="display: inline!important;">
                <label class="font-css">Send APK To</label>
                <p style="color: darkgray;font-size: 11px; margin: 0px;">(Multiple emails should be seperated by comma
                  (,))</p>
                <mat-chip-list #chipList aria-label="Fruit selection">
                  <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(fruit)">
                    {{fruit.name}}
                    <mat-icon matChipRemove *ngIf="removable" style="margin-bottom: 0px!important ;">cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)" style="margin: 7px;">
                </mat-chip-list>
              </mat-form-field> -->
              <div class="row">
                <div class="col-md-6">
                  <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                    <label for="sel1" class="text-font-css" style="margin-top: 25px;">App Logo <strong
                        style="color:red;">*</strong></label><br>
                    <span style="color: darkgray;font-size: 11px;">(Size should be in 512 x 512 format!)</span>
                    <input type="file" accept=".png, .jpg, .jpeg" (change)="onFileChanged($event)"
                      [disabled]="appdisabled" />
                  </div>
                </div>
                <div class="col-md-6 margin-top-css-style">
                  <div>
                    <div class="col-md-7" style="font-size: smaller;">
                      <img [src]="imageFile" height="55px" igxButton="raised" (click)="toggleContent()"
                        style="cursor: pointer;">
                      <div *ngIf="this.istoggle" class="toggle-content outsideClose" igxToggle>
                        <section class="toggle-section " id="manage-look-css" style="z-index:2">
                          <img [src]="imageFile" style="height:150px; width:120px;border: 1px solid red;">
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                    <label for="sel1" class="text-font-css" style="margin-top: 25px;">App Logo Hdpi <strong
                        style="color:red;">*</strong></label><br>
                    <span style="color: darkgray;font-size: 11px;">(Size should be in 72 x 72 format!)</span>
                    <input type="file" accept=".png, .jpg, .jpeg" (change)="onFileHdpiChanged($event)"
                      [disabled]="appdisabled" />
                  </div>
                </div>
                <div class="col-md-6 margin-top-css-style">
                  <div>
                    <div class="col-md-7" style="font-size: smaller;">
                      <img [src]="imageFileHdpi" height="55px" igxButton="raised" (click)="toggleContent1()"
                        style="cursor: pointer;">
                      <div *ngIf="this.istoggle1" class="toggle-content outsideClose" igxToggle>
                        <section class="toggle-section " id="manage-look-css" style="z-index:2">
                          <img [src]="imageFileHdpi" style="height:150px">
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;"
                *ngIf="isAppTypeWeb==true">
                <label for="sel1" class="text-font-css" style="margin-top: 25px;margin-left: 17px;"> <input
                    type="checkbox" class="form-check-input" formControlName="googleLogin" style="margin-top: 4px;">
                  Allow Login with Google Plus </label>
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="text-font-css" style="margin-top: 30px;margin-left: 17px;"><input
                    type="checkbox" class="form-check-input" formControlName="rootDeviceDetector"
                    style="margin-top: 4px;">
                  Root Device Detector </label>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-12">
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="font-css" style="margin-top: 25px;">Package Name <strong
                    style="color:red;">*</strong></label>
                <span style="display: flex;">
                  <label for="sel1" class="text-font-css bold" style="font-size: 16px; margin-right: 5px;
                  margin-top: 2px;">ai.qik.</label> <input type="text" class="form-control"
                    formControlName="packageName" placeholder="Package Name" disabled name="packageName" required>
                </span>
              </div>
              <div
                *ngIf="formApp.controls['packageName'].invalid && (formApp.controls['packageName'].dirty || formApp.controls['packageName'].touched)"
                class="alert alert-danger">
                <div *ngIf="formApp.controls['packageName'].errors.required">
                  Package Name is required!
                </div>
                <div *ngIf="formApp.controls['packageName'].errors.pattern">
                  1] Valid characters: AlphaNumerics, dash, underscore!<br>
                  2] Package name should not contain capital letter!
                </div>
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="font-css" style="margin-top: 25px;">Color Primary</label>
                <input type="text" class="mdl-textfield__input form-control" placeholder="Color Primary"
                  formControlName="colorPrimary">
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="font-css" style="margin-top: 25px;">Text Color</label>
                <input type="text" class="mdl-textfield__input form-control" placeholder="Text Color"
                  formControlName="textColor">
              </div>
              <!-- <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                <label for="sel1" class="font-css">Screen Type</label>
                <select class="form-control" formControlName="screenType">
                  <option value="" disabled selected>-- Select --</option>
                  <option>dashboard</option>
                  <option>native-dashboard</option>
                  <option>report</option>
                  <option>edit</option>
                  <option>kboard</option>
                  <option>kanban</option>
                  <option>view</option>
                  <option>form</option>
                  <option>register</option>
                  <option>bottomnav_mob</option>
                  <option>tabnav_mob</option>
                  <option>notification_mob</option>
                  <option>about_us_mob</option>
                  <option>login_mob</option>
                  <option>signup_mob</option>
                </select>
              </div> -->
              <div class="row">
                <div class="col-md-6">
                  <div class="mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px; ">
                    <label for="sel1" class="text-font-css">Splash Screen Image
                    </label><br>
                    <span style="color: darkgray;font-size: 11px;">(Size should be in 1080x1820 format!)</span>
                    <input type="file" accept=".png, .jpg, .jpeg" (change)="onFileSplashChanged($event)"
                      [disabled]="appdisabled" />
                  </div>
                </div>
                <div class="col-md-6 margin-top-css-style">
                  <div>
                    <div class="col-md-7" style="font-size: smaller;">
                      <img [src]="imageFileSplash" height="55px" igxButton="raised" (click)="toggleContent3()"
                        style="cursor: pointer;">
                      <div *ngIf="this.istoggle3" class="toggle-content outsideClose" igxToggle>
                        <section class="toggle-section " id="manage-look-css" style="z-index:2">
                          <img [src]="imageFileSplash" style="height:150px">
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                    <label for="sel1" class="text-font-css" style="margin-top: 25px;">App Logo xhdpi <strong
                        style="color:red;">*</strong></label><br>
                    <span style="color: darkgray;font-size: 11px;">(Size should be in 96 x 96 format!)</span>
                    <input type="file" accept=".png, .jpg, .jpeg" (change)="onFilexhdpiChanged($event)"
                      [disabled]="appdisabled" />
                  </div>
                </div>
                <div class="col-md-6 margin-top-css-style">
                  <div>
                    <div class="col-md-7" style="font-size: smaller;">
                      <img [src]="imageFileXhdpi" height="55px" igxButton="raised" (click)="toggleContent9()"
                        style="cursor: pointer;">
                      <div *ngIf="this.istoggle9" class="toggle-content outsideClose" igxToggle>
                        <section class="toggle-section " id="manage-look-css" style="z-index:2">
                          <img [src]="imageFileXhdpi" style="height:150px">
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                    <label for="sel1" class="text-font-css" style="margin-top: 25px;">App Logo xxxhdpi <strong
                        style="color:red;">*</strong></label><br>
                    <span style="color: darkgray;font-size: 11px;">(Size should be in 192 x 192 format!)</span>
                    <input type="file" accept=".png, .jpg, .jpeg" (change)="onFileXxxhdpiChanged($event)"
                      [disabled]="appdisabled" />
                  </div>
                </div>
                <div class="col-md-6 margin-top-css-style">
                  <div>
                    <div class="col-md-7" style="font-size: smaller;">
                      <img [src]="imageFileXxxhdpi" height="55px" igxButton="raised" (click)="toggleContent4()"
                        style="cursor: pointer;">
                      <div *ngIf="this.istoggle4" class="toggle-content outsideClose" igxToggle>
                        <section class="toggle-section " id="manage-look-css" style="z-index:2">
                          <img [src]="imageFileXxxhdpi" style="height:150px">
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isAppTypeWeb==true" class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                  style="padding: 0px 0px;">
                  <label for="sel1" class="text-font-css" style="margin-top: 25px;margin-left: 32px;"> <input
                      type="checkbox" class="form-check-input" formControlName="fbLogin" style="margin-top: 4px;">
                    Allow Login with Facebook</label>
                </div>
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="text-font-css" style="margin-top: 30px;margin-left: 17px;"><input
                    type="checkbox" class="form-check-input" formControlName="screenShotDetector"
                    style="margin-top: 4px;">
                  Screen Shot Detector </label>
              </div>
              <div *ngIf="isAppTypeWeb!=true" class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                style="padding: 0px 0px;">
                <label for="sel1" class="text-font-css" style="margin-top: 62px;margin-left: 17px;"> <input
                    type="checkbox" class="form-check-input" formControlName="topMenuVisible"
                    (change)="onTnMenuChanged($event)" style="margin-top: 4px;">
                  Top Menu Visible </label>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-12">
              <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                <label for="sel1" class="font-css">App Type <strong style="color:red;">*</strong></label>
                <select class="form-control" formControlName="appType" (change)="onAppTypeSelect($event)">
                  <option value="" disabled selected>-- Select --</option>
                  <option value="web">web</option>
                  <option value="native">native</option>
                  <option value="semi-native">semi-native</option>
                </select>
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="font-css" style="margin-top: 7px;">Color Primary Dark</label>
                <input type="text" class="mdl-textfield__input form-control" placeholder="Color Primary Dark"
                  formControlName="colorPrimaryDark">
              </div>
              <div
                class="mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                style="margin-top: 25px;">
                <label for="sel1" class="font-css">Login Type</label>
                <select class="form-control" id="sel1" formControlName="loginType">
                  <option value="" disabled selected>-- Select --</option>
                  <option>email-psw</option>
                  <option>username-psw</option>
                  <option>phone-psw</option>
                  <option>phoneOTP</option>
                  <option>disabled</option>
                </select>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    style="padding: 0px 0px; margin-top: 15px;">
                    <label for="sel1" class="text-font-css">App Logo Mdpi <strong
                        style="color:red;">*</strong></label><br>
                    <span style="color: darkgray;font-size: 11px;">(Size should be in 48 x 48 format!)</span>
                    <input type="file" accept=".png, .jpg, .jpeg" (change)="onFileMdpiChanged($event)"
                      [disabled]="appdisabled" />
                  </div>
                </div>
                <div class="col-md-6 margin-top-css-style">
                  <div>
                    <div class="col-md-7" style="font-size: smaller;">
                      <img [src]="imageFileMdpi" height="55px" igxButton="raised" (click)="toggleContent5()"
                        style="cursor: pointer;">
                      <div *ngIf="this.istoggle5" class="toggle-content outsideClose" igxToggle>
                        <section class="toggle-section " id="manage-look-css" style="z-index:2">
                          <img [src]="imageFileMdpi" style="height:150px">
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                    <label for="sel1" class="text-font-css" style="margin-top: 25px;">App Logo xxhdpi <strong
                        style="color:red;">*</strong></label><br>
                    <span style="color: darkgray;font-size: 11px;">(Size should be in 144 x 144 format!)</span>
                    <input type="file" accept=".png, .jpg, .jpeg" (change)="onFileXXhdpiChanged($event)"
                      [disabled]="appdisabled" />
                  </div>
                </div>
                <div class="col-md-6 margin-top-css-style">
                  <div>
                    <div class="col-md-7" style="font-size: smaller;">
                      <img [src]="imageFileXXhdpi" height="55px" igxButton="raised" (click)="toggleContent6()"
                        style="cursor: pointer;">
                      <div *ngIf="this.istoggle6" class="toggle-content outsideClose" igxToggle>
                        <section class="toggle-section " id="manage-look-css" style="z-index:2">
                          <img [src]="imageFileXXhdpi" style="height:150px">
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="text-font-css" style="margin-top: 80px;margin-left: 17px;"><input
                    type="checkbox" class="form-check-input" formControlName="allowAppNameOnSplash"
                    style="margin-top: 4px;">
                  App Name on Splash Screen </label>
              </div>
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="text-font-css" style="margin-top: 30px;margin-left: 17px;"><input
                    type="checkbox" class="form-check-input" formControlName="updateAvailable"
                    (change)="onUpdateAvailableChanged($event)" style="margin-top: 4px;">
                  Update Available </label>
              </div>
              <div *ngIf="isUpdateCheckVersion==true"
                class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="text-font-css" style="margin-top: 25px;margin-left: 17px;"><input
                    type="checkbox" class="form-check-input" formControlName="mandatory" style="margin-top: 4px;">
                  Is Mandatory </label>
              </div>
              <div *ngIf="isAppTypeWeb!=true" class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                style="padding: 0px 0px;">
                <label for="sel1" class="text-font-css" style="margin-top: 62px;margin-left: 17px;"> <input
                    type="checkbox" class="form-check-input" formControlName="bottomMenuVisible"
                    style="margin-top: 4px;" (change)="onBnMenuChanged($event)">
                  Bottom Menu Visible </label>
              </div>
              <div *ngIf="selectedMenuType == 'Mobile BN Menu' || selectedMenuType == 'Mobile TN Menu'"
                class="mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                style="margin-top: 4px;">
                <label for="sel1" class="font-css">Menu Type</label>
                <select class="form-control" id="sel1" formControlName="menuType">
                  <option value="" disabled selected>-- Select --</option>
                  <option *ngFor="let f of menuList" [value]="f.id">{{f?.attributes?.menuTitle}}
                  </option>
                </select>
              </div>
            </div>
            <div class="container" *ngIf="isJsonEditorShow==true">
              <div class=" mdl-js-textfield mdl-textfield--floating-label txt-full-width" style="padding: 0px 0px;">
                <label for="sel1" class="text-font-css" style="margin-top: 25px;">Google Services </label>
                <json-editor [options]="editorOptions1" style="min-height: 200px; max-height:500px;"
                  formControlName="google_services">
                </json-editor>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
              <div class="form-group" style="margin-left: 100px;margin-top: 10px;">
                <button type="submit" class="btn btn-info btn-lg" [disabled]="!formApp.valid">
                  Create</button>
              </div>
            </div>
            <div class="col-md-4">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #publishtemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Publish App</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="padding: 0px;padding-left: 20px; padding-right: 20px;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <form [formGroup]="formPublish" (ngSubmit)="onPublishSubmit(formPublish.value)">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
              style="padding: 0px 0px;">
              <label for="uatappname" class="text-font-css" style="margin-top: 25px; font-size: 14px;">UAT App Name
                <strong style="color: red; ">*</strong></label>
              <input class="mdl-textfield__input form-control" type="text" formControlName="uatappname"
                placeholder="Uat App Name" readonly>
            </div>
            <!-- <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
              style="padding: 0px 0px;">
              <label for="prodappname" class="text-font-css" style="margin-top: 25px; font-size: 14px;">Production App
                Name
                <strong style="color: red; ">*</strong></label>
              <input class="mdl-textfield__input form-control" type="text" formControlName="prodappname"
                placeholder="Production App Name">
            </div> 
            <div
              *ngIf="formPublish.controls['prodappname'].invalid && (formPublish.controls['prodappname'].dirty || formPublish.controls['prodappname'].touched)"
              class="alert alert-danger">
              <div *ngIf="formPublish.controls['prodappname'].errors.required">
                Production App Name is required!
              </div>
              <div *ngIf="formPublish.controls['prodappname'].errors.pattern">
                1] Valid characters: AlphaNumerics, dash, underscore!<br>
                2] App name should not contain capital letter!
              </div>
            </div>
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
              style="padding: 0px 0px;">
              <label for="confirmappname" class="text-font-css" style="margin-top: 25px; font-size: 14px;">Confirm
                Confirm Production App Name
                <strong style="color: red; ">*</strong></label>
              <input class="mdl-textfield__input form-control" type="text" formControlName="confirmappname"
                placeholder="Confirm App Name">
            </div>
            <div
              *ngIf="formPublish.controls['confirmappname'].invalid && (formPublish.controls['confirmappname'].dirty || formPublish.controls['confirmappname'].touched)"
              class="alert alert-danger">
              <div *ngIf="formPublish.controls['confirmappname'].errors.required">
                Confirm App Name is required!
              </div>
              <div *ngIf="formPublish.controls['confirmappname'].errors.pattern">
                1] Valid characters: AlphaNumerics, dash, underscore!<br>
                2] App name should not contain capital letter!
              </div>
            </div>
            <div *ngIf="formPublish.controls.confirmappname.touched">
              <div class="alert alert-danger" *ngIf="formPublish.hasError('mismatchedKeys')">
                Production App Name do not match!
              </div>
            </div>-->
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
              style="padding: 0px 0px;">
              <label for="sourcePath" class="text-font-css" style="margin-top: 25px; font-size: 14px;">Source Path
                <strong style="color: red; ">*</strong></label>
              <select class=" form-control" formControlName="sourcePath">
                <!-- <option value="" disabled selected>-- Select --</option> -->
                <option value="$localhost" disabled selected>Localhost</option>
                <!-- <option value="$uat">Uat</option>
                <option value="$production">Production</option> -->
              </select>
            </div>
            <div
              *ngIf="formPublish.controls['sourcePath'].invalid && (formPublish.controls['sourcePath'].dirty || formPublish.controls['sourcePath'].touched)"
              class="alert alert-danger">
              <div *ngIf="formPublish.controls['sourcePath'].errors.required">
                Please select Source!
              </div>
            </div>
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
              style="padding: 0px 0px;">
              <label for="destinationPath" class="text-font-css" style="margin-top: 25px; font-size: 14px;">Destination
                Path
                <strong style="color: red; ">*</strong></label>
              <select class=" form-control" formControlName="destinationPath">
                <option value="" disabled selected>-- Select --</option>
                <option value="$localhost">Localhost</option>
                <option value="$uat">Uat</option>
                <option value="$production">Production</option>
              </select>
            </div>
            <div
              *ngIf="formPublish.controls['destinationPath'].invalid && (formPublish.controls['destinationPath'].dirty || formPublish.controls['destinationPath'].touched)"
              class="alert alert-danger">
              <div *ngIf="formPublish.controls['destinationPath'].errors.required">
                Please select Destination!
              </div>
            </div>
            <div class="form-group" style="margin-left: 100px;margin-top: 10px;">
              <button type="submit" class="btn btn-info btn-lg" [disabled]="!formPublish.valid">
                Create</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #passwordtemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change Password</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef1.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="padding: 0px;padding-left: 20px; padding-right: 20px;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <form [formGroup]="formPass" (ngSubmit)="onPasswordSubmit(formPass.value)">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
              style="padding: 0px 0px;">
              <label for="oldPass" class="text-font-css" style="margin-top: 25px; font-size: 14px;">Old Password
                <strong style="color: red; ">*</strong></label>
              <input class="mdl-textfield__input form-control" type="password" formControlName="oldPass"
                placeholder="Old Password">
            </div>
            <div
              *ngIf="formPass.controls['oldPass'].invalid && (formPass.controls['oldPass'].dirty || formPass.controls['oldPass'].touched)"
              class="alert alert-danger">
              <div *ngIf="formPass.controls['oldPass'].errors.required">
                Please enter old password!
              </div>
            </div>
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
              style="padding: 0px 0px;">
              <label for="newPass" class="text-font-css" style="margin-top: 25px; font-size: 14px;">New Password
                <strong style="color: red; ">*</strong></label>
              <input class="mdl-textfield__input form-control" type="password" formControlName="newPass"
                placeholder="New Password">
            </div>
            <div
              *ngIf="formPass.controls['newPass'].invalid && (formPass.controls['newPass'].dirty || formPass.controls['newPass'].touched)"
              class="alert alert-danger">
              <div *ngIf="formPass.controls['newPass'].errors?.required"> Please enter new Password! </div>
              <div *ngIf="formPass.controls['newPass'].errors?.pattern"> Password length must be between 6 to 15
                characters (Special Characters allowed: _ @ & #)! </div>
            </div>
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
              style="padding: 0px 0px;">
              <label for="confirmPass" class="text-font-css" style="margin-top: 25px; font-size: 14px;">Confirm Password
                <strong style="color: red; ">*</strong></label>
              <input class="mdl-textfield__input form-control" type="password" formControlName="confirmPass"
                placeholder="Confirm Password">
            </div>
            <div *ngIf="formPass.controls['confirmPass'].touched">
              <div class="alert alert-danger" *ngIf="formPass.hasError('mismatchedPasswords')">
                Passwords do not match!
              </div>
            </div>
            <div
              *ngIf="formPass.controls['confirmPass'].invalid && (formPass.controls['confirmPass'].dirty || formPass.controls['confirmPass'].touched)"
              class="alert alert-danger">
              <div *ngIf="formPass.controls['confirmPass'].errors.required">
                Please re-enter new password!
              </div>
            </div>
            <div class="form-group" style="margin-left: 100px;margin-top: 10px;">
              <button type="submit" class="btn btn-info btn-lg">
                Change Password</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>