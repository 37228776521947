import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl, FormArray } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { AccessAppService } from 'src/app/shared/accessApp.service';
import * as Parse from 'parse';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})

export class EditProfileComponent implements OnInit {

  profileForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private ngxService: NgxUiLoaderService,
    private toastr: ToastrService, private accessAppService: AccessAppService) {

    localStorage.setItem("accessUrl", "#");
    localStorage.setItem("appdisabled", "true");
    this.accessAppService.accessUrl.next(String(localStorage.getItem('accessUrl')));
    this.accessAppService.isAccessBtnDisabled.next(true);

  }

  async ngOnInit() {

    $(function () {
      $(".f-div2").hide();
      $(".f-div1").show();
    });

    this.pageLoader();
    Parse.initialize(localStorage.getItem("parseKey"));
    (Parse as any).serverURL = localStorage.getItem("serverUrl");

    this.profileForm = this.formBuilder.group({
      username: [''],
      name: ['', [Validators.required, Validators.maxLength(100), Validators.pattern("^[a-zA-Z ]*$")]],
      phone: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("[0-9]{10}")]]
    });

    if (localStorage.getItem("userObjectId") != undefined) {
      const user = Parse.Object.extend("_User");
      const users = new user();
      const query = new Parse.Query(users);

      // Retrieve the object by id
      await query.get(localStorage.getItem("userObjectId"))
        .then((x) => {
          let res = x.toJSON();
          this.profileForm.patchValue({
            username: res.username,
            name: res.name,
            phone: res.phone
          });
        });
    }

  }

  pageLoader(): void {

    // start foreground spinner of the master loader with 'default' taskId
    this.ngxService.start();

    // Stop the foreground loading after 5s
    setTimeout(() => {
      // stop foreground spinner of the master loader with 'default' taskId
      this.ngxService.stop();
    }, 2000);

    // OR
    this.ngxService.startBackground('do-background-things');

    // Do something here...
    this.ngxService.stopBackground('do-background-things');

    // start foreground spinner of the loader "loader-01" with 'default' taskId
    this.ngxService.startLoader('loader-01');

    // Stop the foreground loading after 5s
    setTimeout(() => {
      // stop foreground spinner of the loader "loader-01" with 'default' taskId
      this.ngxService.stopLoader('loader-01');
    }, 2000);

  }

  async updateProfile(profileForm: NgForm) {

    this.pageLoader();
    if (this.profileForm.valid) {
      const user = Parse.Object.extend("_User");
      const users = new user();
      if (localStorage.getItem("userObjectId") != null) {
        const query = new Parse.Query(users);
        await query.get(localStorage.getItem("userObjectId"))
          .then(async (x) => {
            x.set("phone", "" + profileForm['phone'] + ""),
              x.set("name", profileForm['name'])
            await x.save();
            this.toastr.success('Profile updated!', 'Success!!!');
            this.showDetails();
          });
      } else { this.toastr.error('Error While updating!', 'Error!'); }
    } else {
      this.validateAllFormFields(this.profileForm);
      this.toastr.error("Please fill all mandatory fields!", "Error");
    }

  }

  editDetails() {

    $(function () {
      $(".f-div1").hide();
      $(".f-div2").show();
    });

  }

  showDetails() {

    $(function () {
      $(".f-div2").hide();
      $(".f-div1").show();
    });

  }

  validateAllFormFields(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) { control.markAsTouched({ onlySelf: true }); }
      else if (control instanceof FormGroup) { this.validateAllFormFields(control); }
      else if (control instanceof FormArray) {
        for (const control1 of control.controls) {
          if (control1 instanceof FormControl) {
            control1.markAsTouched({ onlySelf: true });
          }
          if (control1 instanceof FormGroup) { this.validateAllFormFields(control1); }
        }
      }
    });

  }

}
