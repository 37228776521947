import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/index';

@Injectable({
    providedIn: 'root'
})

export class AccessAppService {

    public isAccessBtnShow:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(Boolean(localStorage.getItem('appAccess')));
    public accessUrl: BehaviorSubject<string> = new BehaviorSubject<string>(String(localStorage.getItem("accessUrl")));
    public isAccessBtnDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(Boolean(localStorage.getItem("appdisabled")));

}