import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { OrganizationComponent } from './component/organization/organization.component';
import { EditProfileComponent } from './component/edit-profile/edit-profile.component';
import { CustomQueryBuilderComponent } from './component/custom-query-builder/custom-query-builder.component';
import { FilterBuilderComponent } from './component/filter-builder/filter-builder.component';
import { EditOrganizationComponent } from './component/edit-organization/edit-organization.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { MainappComponent } from './component/mainapp/mainapp.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren : () => import('./component/login-page/login-page.module').then(m => m.LoginPageModule)
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'mainApp',
    loadChildren : () => import('./component/mainapp/mainapp.module').then(m => m.MainappModule),
    canActivate: [AuthGuard]
  },
  {
    path:'',
    component: MainappComponent,
    children :[
  {
    path: 'dashboard_b',
    loadChildren : () => import('./component/dashboard-builder/dashboard-builder.module').then(m => m.DashboardBuilderModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'menu_builder',
    loadChildren : () => import('./component/menu-builder/menu-builder.module').then(m => m.MenuBuilderModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'menuJsonEditor',
    loadChildren : () => import('./component/menu-jsoneditor/menu-jsoneditor.module').then(m => m.MenuJsoneditorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'layout',
    loadChildren : () => import('./component/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'code_editor',
    loadChildren : () => import('./component/code-editor/code-editor.module').then(m => m.CodeEditorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wizard',
    loadChildren : () => import('./component/wizard/wizard.module').then(m => m.WizardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'editor_code',
    loadChildren : () => import('./component/editor-code/editor-code.module').then(m => m.EditorCodeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'diff_editor_code',
    loadChildren : () => import('./component/diff-editor/diff-editor.module').then(m => m.DiffEditorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'formBuilder',
    loadChildren : () => import('./component/form-builder/form-builder.module').then(m => m.FormBuilderModule),
  
    canActivate: [AuthGuard]
  },
  {
    path: 'appInfo',
    loadChildren : () => import('./component/app-info/app-info.module').then(m => m.AppInfoModule),
    canActivate: [AuthGuard]
  },  
  {
    path: 'mobile',
    loadChildren : () => import('./component/mobile-app/mobile-app.module').then(m => m.MobileAppModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reportBuilder',
    loadChildren : () => import('./component/report-builder/report-builder.module').then(m => m.ReportBuilderModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'organization',
    component: OrganizationComponent
  },
  {
    path: 'validateSchemas',
    loadChildren : () => import('./component/validate-schema/validate-schema.module').then(m => m.ValidateSchemaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appEditors',
    loadChildren : () => import('./component/app-editors/app-editors.module').then(m => m.AppEditorsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'editProfile',
    component: EditProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'editOrganization',
    component: EditOrganizationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'orgInfo',
    loadChildren : () => import('./component/org-info/org-info.module').then(m => m.OrgInfoModule),
     canActivate: [AuthGuard]
  },
  {
    path: 'queryBuilder',
    component: CustomQueryBuilderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plans',
    loadChildren : () => import('./component/plans/plans.module').then(m => m.PlansModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'filterBuilder',
    component: FilterBuilderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent
  }]
},
  {
    path: '**', redirectTo: "login", pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
