import { FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { QueryBuilderClassNames, QueryBuilderConfig } from 'angular2-query-builder';
import { ObjectServiceService } from 'src/app/service/object-service.service';
import * as mongoose from 'mongoose';

@Component({
  selector: 'app-custom-query-builder',
  templateUrl: './custom-query-builder.component.html',
  styleUrls: ['./custom-query-builder.component.css']
})

export class CustomQueryBuilderComponent implements OnInit {

  public queryCtrl: FormControl;

  fieldList: any = []; valueFieldList: any = []; obj: any = {}; objNew: any = {};

  messageToSendP: string = ''; fetchedData: string = "";

  @Input() receivedParentMessage: string;
  @Output() messageToEmit = new EventEmitter<string>();

  public bootstrapClassNames: QueryBuilderClassNames = {
    removeIcon: 'fa fa-minus',
    addIcon: 'fa fa-plus',
    arrowIcon: 'fa fa-chevron-right px-2',
    button: 'btn',
    buttonGroup: 'btn-group',
    rightAlign: 'order-12 ml-auto',
    switchRow: 'd-flex px-2',
    switchGroup: 'd-flex align-items-center',
    switchRadio: 'custom-control-input',
    switchLabel: 'custom-control-label',
    switchControl: 'custom-control custom-radio custom-control-inline',
    row: 'row p-2 m-1',
    rule: 'border',
    ruleSet: 'border',
    invalidRuleSet: 'alert alert-danger',
    emptyWarning: 'text-danger mx-auto',
    operatorControl: 'form-control',
    operatorControlSize: 'col-auto pr-0',
    fieldControl: 'form-control',
    fieldControlSize: 'col-auto pr-0',
    entityControl: 'form-control',
    entityControlSize: 'col-auto pr-0',
    inputControl: 'form-control',
    inputControlSize: 'col-auto'
  };

  async ngOnInit() {

    if (localStorage.getItem("className")) {
      await this.loadFieldBinding(localStorage.getItem("className"));
      debugger;
      this.config.fields = this.obj;
      console.log("this.config.fields", this.config.fields);
      this.currentConfig = this.config;
      console.log("query", this.query);
    }

  }

  public query = {
    "condition": "and",
    "rules": []
  };

  public entityConfig: QueryBuilderConfig = {
    entities: {
      physical: { name: 'Physical Attributes' },
      nonphysical: { name: 'Nonphysical Attributes' }
    },
    fields: {
      age: { name: 'Age', type: 'number', entity: 'physical' },
      gender: {
        name: 'Gender',
        entity: 'physical',
        type: 'category',
        options: [
          { name: 'Male', value: 'm' },
          { name: 'Female', value: 'f' }
        ]
      },
      name: { name: 'Name', type: 'string', entity: 'nonphysical' },
      notes: { name: 'Notes', type: 'textarea', operators: ['=', '!='], entity: 'nonphysical' },
      educated: { name: 'College Degree?', type: 'boolean', entity: 'nonphysical' },
      birthday: {
        name: 'Birthday', type: 'date', operators: ['=', '<=', '>'],
        defaultValue: (() => new Date()), entity: 'nonphysical'
      },
      school: { name: 'School', type: 'string', nullable: true, entity: 'nonphysical' },
      occupation: {
        name: 'Occupation',
        entity: 'nonphysical',
        type: 'category',
        options: [
          { name: 'Student', value: 'student' },
          { name: 'Teacher', value: 'teacher' },
          { name: 'Unemployed', value: 'unemployed' },
          { name: 'Scientist', value: 'scientist' }
        ]
      }
    }
  };

  public config: QueryBuilderConfig = {
    fields: {
      age: { name: 'Age', type: 'number' },
      gender: {
        name: 'Gender',
        type: 'category',
        options: [
          { name: 'Male', value: 'm' },
          { name: 'Female', value: 'f' }
        ]
      },
      name: { name: 'Name', type: 'string' },
      notes: { name: 'Notes', type: 'textarea', operators: ['=', '!='] },
      educated: { name: 'College Degree?', type: 'boolean' },
      birthday: {
        name: 'Birthday', type: 'date', operators: ['=', '<=', '>'],
        defaultValue: (() => new Date())
      },
      school: { name: 'School', type: 'string', nullable: true },
      occupation: {
        name: 'Occupation',
        type: 'category',
        options: [
          { name: 'Student', value: 'student' },
          { name: 'Teacher', value: 'teacher' },
          { name: 'Unemployed', value: 'unemployed' },
          { name: 'Scientist', value: 'scientist' }
        ]
      }
    }
  };

  public currentConfig: QueryBuilderConfig;
  public allowRuleset: boolean = true;
  public allowCollapse: boolean;
  public persistValueOnFieldChange: boolean = false;

  constructor(private formBuilder: FormBuilder, private rec: ObjectServiceService) {

    debugger;
    this.queryCtrl = this.formBuilder.control(this.query);

  }

  async loadFieldBinding(classNm: string) {

    try {
      debugger
      const Schema = mongoose.Schema;
      if (classNm != undefined || classNm != null) {
        var className = classNm;
        var classParam = { "className": className }

        await this.rec.getSchemaByClass(classParam).then(result => {
          let ff1List = []; var i1 = {};
          console.log("result.fields", result["result"].fields);
          ff1List.push(result["result"].fields);

          //array of fields
          for (let key of ff1List) {
            //dyanamice key Item
            for (var i in key) {
              //push value of dynamic key
              if (i == 'ACL') {
                //don't push
              } else {
                //i1
                this.obj[i] = { "name": i, "type": key[i].type.toLowerCase() };
                var isReq = key[i].required;
                if (isReq == 'undefined') { isReq = false; }
                else { isReq = true; }
                if (key[i].type == 'Pointer') {
                  this.objNew[i] = { type: mongoose.Schema.Types.ObjectId, ref: key[i].targetClass, required: isReq };
                }
                else { this.objNew[i] = { type: key[i].type, required: isReq }; }
              }
            }
          }
        });
        console.log("this.obj", this.obj);
        console.log("this.objNew", this.objNew);
      }
    } catch { }

  }

  switchModes(event: Event) {

    this.currentConfig = (<HTMLInputElement>event.target).checked ? this.entityConfig : this.config;

  }

  changeDisabled(event: Event) {

    (<HTMLInputElement>event.target).checked ? this.queryCtrl.disable() : this.queryCtrl.enable();

  }

  getQuery() {

    debugger
    const Schema = mongoose.Schema;
    const newSchema = new Schema(this.objNew);
    console.log("newSchema", newSchema);
    const MyModel = mongoose.model('newModel', newSchema);
    const m = new MyModel;

    console.log("m", m);
    console.log("query", this.query);
    var mongoDBQuery = this._convertQuery(this.query, m);

    mongoDBQuery.then(data => {
      console.log(data);
      debugger;
      //check the condition is present at first place
      var conditionKey: any;
      conditionKey = Object.keys(data)[0];
      if (conditionKey == "$and" || conditionKey == "$or") {
        //if exists then fetch the value for that key
        console.log(data[conditionKey]);

        var conditionVal: any;
        conditionVal = data[conditionKey];

        //convert it to object for proper format
        var obj: string = "";
        for (var i in conditionVal) {
          var item: any;
          item = JSON.stringify(conditionVal[i]);

          if (obj.length > 0) { obj = obj + "," + item.substring(1, item.length - 1); }
          else { obj = item.substring(1, item.length - 1); }
        }
        obj = "{" + obj + "}";
        console.log("obj", obj);

        this.fetchedData = obj;
        this.messageToEmit.emit(this.fetchedData);
        console.log("this.fetchedData11", this.fetchedData);
      }
    });

  }

  private _convertQuery = async (query, model) => {

    debugger
    if (!query || !model) { return {}; }

    const conditions = { "and": "$and", "or": "$or" };
    const operators = {
      "=": "$eq",
      "!=": "$ne",
      "<": "$lt",
      "<=": "$lte",
      ">": "$gt",
      ">=": "gte",
      "in": "$in",
      "not in": "$nin",
      "contains": "$regex"
    };
    // Get Mongoose schema type instance of a field
    const getSchemaType = (field) => {
      return model.schema.paths[field] ? model.schema.paths[field].instance : false;
    };
    // Map each rule to a MongoDB query
    const mapRule = (rule) => {
      let field = rule.field;
      let value = rule.value;
      if (!value) { value = null; }
      // Get schema type of current field
      const schemaType = getSchemaType(rule.field);
      // Check if schema type of current field is ObjectId
      if (schemaType === 'ObjectID' && value) {
        // Convert string value to MongoDB ObjectId
        if (Array.isArray(value)) { value.map(val => new Object(val)); }
        else { value = new Object(value); }
        // Check if schema type of current field is Date
      }
      else if (schemaType === 'Date' && value) {
        // Convert string value to ISO date
        console.log(value);
        //value = new Date(value);
      }
      console.log(schemaType);
      console.log(value);
      // Set operator
      const operator = operators[rule.operator] ? operators[rule.operator] : '$eq';
      // Create a MongoDB query
      let mongoDBQuery;
      // Check if operator is $regex
      if (operator === '$regex') {
        // Set case insensitive option
        mongoDBQuery = {
          [field]: {
            [operator]: value,
            '$options': 'i'
          }
        };
      }
      else {
        mongoDBQuery = { [field]: { [operator]: value } };
      }
      return mongoDBQuery;
    };
    const mapRuleSet = (ruleSet) => {
      if (ruleSet.rules.length < 1) { return; }
      // Iterate Rule Set conditions recursively to build database query
      return {
        [conditions[ruleSet.condition]]: ruleSet.rules.map(rule => rule.operator ? mapRule(rule) : mapRuleSet(rule))
      };
    };
    let mongoDbQuery = mapRuleSet(query);
    return mongoDbQuery;

  };

  public get convertQuery() { return this._convertQuery; }

  public set convertQuery(value) { this._convertQuery = value; }
}