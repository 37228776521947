<br>
<query-builder [formControl]='queryCtrl' [config]='currentConfig' [allowRuleset]='allowRuleset'
  [allowCollapse]='allowCollapse' [persistValueOnFieldChange]='persistValueOnFieldChange'>
  <ng-container *queryInput="let rule; type: 'textarea'; let getDisabledState=getDisabledState">
    <textarea class="text-input text-area" [(ngModel)]="rule.value" [disabled]=getDisabledState()
      placeholder="Custom Textarea"></textarea>
  </ng-container>
</query-builder>
<br>
<div>
  <textarea class="output">{{query | json}}</textarea>
</div>
<button type="button" (click)="getQuery()" value="Submit" class="btn btn-primary uvs-csbutton">Create Query</button>