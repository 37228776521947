import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataSharingService } from 'src/app/shared/data-sharing.service';
import * as Parse from 'parse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccessAppService } from 'src/app/shared/accessApp.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { configuration } from 'src/app/config/config';
import { AppServiceService } from 'src/app/service/app-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

export interface Fruit { name: string; }

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})

export class TopMenuComponent implements OnInit {

  // Define a variable to use for showing/hiding the Login button
  isUserLoggedIn: boolean; isGLogin = false; isShow: boolean = false; isJsonEditorShow: boolean = false;
  istoggle: boolean = false; isEditShow: boolean = false; isHistoryShow: boolean = false; isTriggerApkShow: boolean = true;
  istoggle1: boolean = false; istoggle2: boolean = false; istoggle3: boolean = false; istoggle9: boolean = false;
  isAppTypeWeb: boolean = false; istoggle4: boolean = false; istoggle5: boolean = false; istoggle6: boolean = false;

  baseUrl: string; objectid: any; userName: string = ""; autoIncNo: number = 0; selectedMenuType: string;
  orgName: string = ""; accessType: string; ModalTitle: string = "Build Details";

  formApp: FormGroup; formPublish: FormGroup; formPass: FormGroup;

  modalRef2: BsModalRef; modalRef: BsModalRef; modalRef1: BsModalRef;

  menuList: any = [];

  //var declare to display access button
  isAccessBtnShow: boolean = false; isAccessBtnDisabled: boolean = true; accessUrl: any = "#"; dbList: any = [];
  public editorOptions1: JsonEditorOptions; isUpdateCheckVersion: boolean = false;
  //App Logo variables
  selectedFile: File; imageFile: any = "";
  //Mdpi variables
  selectedFileMdpi: File; imageFileMdpi: any = "";
  //Xhdpi variables
  selectedFileXhdpi: File; imageFileXhdpi: any = "";
  //Xhdpi variables
  selectedFileSplash: File; imageFileSplash: any = "";
  //Hdpi variables
  selectedFileHdpi: File; imageFileHdpi: any = "";
  //XXhdpi variables
  selectedFileXXhdpi: File; imageFileXXhdpi: any = "";
  //Xxxhdpi variables
  selectedFileXxxhdpi: File; imageFileXxxhdpi: any = "";

  @ViewChild('addbuildmtemplate') modal4: TemplateRef<any>;
  @ViewChild(JsonEditorComponent, { static: true }) editor: JsonEditorComponent;
  @ViewChild('publishtemplate') modal: TemplateRef<any>;
  @ViewChild('passwordtemplate') passModal: TemplateRef<any>;

  constructor(private router: Router, private dataSharingService: DataSharingService, private rec: AppServiceService,
    private accessAppService: AccessAppService, private toastr: ToastrService, private formBuilder: FormBuilder,
    private modalService: BsModalService, private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) {

    this.spinnerService.show();
    Parse.initialize(localStorage.getItem("parseKey"));
    (Parse as any).serverURL = localStorage.getItem("serverUrl");

    this.editorOptions1 = new JsonEditorOptions();
    this.editorOptions1.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    this.editorOptions1.mode = 'code'; //set only one mode

    // Subscribe here, this will automatically update 
    // "isUserLoggedIn" whenever a change to the subject is made.
    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
    });

    this.formApp = this.formBuilder.group({
      appname: ['', [Validators.required, Validators.pattern("^[^<>'\"/;`%]*$")]],
      packageName: ['', [Validators.required, Validators.pattern("^[a-z][a-zA-Z0-9-_]+$")]],
      fbAppId: [''],
      colorPrimary: ['#3ac9d6'],
      colorPrimaryDark: ['#3ac9d6'],
      colorAccent: ['#3ac9d6'],
      textColor: ['#3ac9d6'],
      appType: ['web', Validators.required],
      applogo: [''],
      googleLogin: [this.isGLogin],
      allowAppNameOnSplash: [this.isGLogin],
      rootDeviceDetector: [false],
      screenShotDetector: [false],
      updateAvailable: [false],
      mandatory: [false],
      fbLogin: [this.isGLogin],
      bottomMenuVisible: [false],
      topMenuVisible: [false],
      loginType: ['', Validators.required],
      menuType: [''],
      emailID: ['', Validators.required],
      objectId: [''],
      google_services: ['']
    });

    try {
      if (this.userName.length == 0) {
        if (localStorage.getItem('loginUser') != null && localStorage.getItem('accessToken') != null) {
          const logUser = JSON.parse(localStorage.getItem('loginUser'));
          this.userName = logUser.name;
        }
      }
    } catch { }

    this.dataSharingService.userName.subscribe(value => {
      this.userName = value;
    });
    this.spinnerService.hide();

  }

  ngOnInit() {

    this.spinnerService.show();

    this.accessAppService.accessUrl.subscribe(value => {
      this.accessUrl = value;
    });

    this.accessAppService.isAccessBtnDisabled.subscribe(value => {
      this.isAccessBtnDisabled = value;
    })

    this.formPublish = this.formBuilder.group({
      uatappname: [''],
      sourcePath: ['$localhost', Validators.required],
      destinationPath: ['', Validators.required]
    });

    this.formPass = this.formBuilder.group({
      oldPass: ['', Validators.required],
      newPass: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_@&#.\s]{6,15}$")]],
      confirmPass: ['', Validators.required]
    },
      {
        validator: this.matchingPasswords({ passwordKey: 'newPass', confirmPasswordKey: 'confirmPass' })
      });

    var orgNm = "";
    this.dataSharingService.orgName.subscribe(value => {
      orgNm = value;
      if (orgNm == "" || orgNm == "null") { this.orgName = "(No Company)"; }
      else { this.orgName = "(" + orgNm + ")"; }
      console.log(orgNm);
    });

    this.dataSharingService.userType.subscribe(value => {
      this.accessType = value;
    });
    this.spinnerService.hide();

  }

  public toggleContent() {

    if (this.istoggle) { this.istoggle = false }
    else { this.istoggle = true }

  }

  public toggleContent1() {

    if (this.istoggle1) { this.istoggle1 = false }
    else { this.istoggle1 = true }

  }

  public toggleContent2() {

    if (this.istoggle2) { this.istoggle2 = false }
    else { this.istoggle2 = true }

  }

  public toggleContent3() {

    if (this.istoggle3) { this.istoggle3 = false }
    else { this.istoggle3 = true }

  }

  public toggleContent9() {

    if (this.istoggle9) { this.istoggle9 = false }
    else { this.istoggle9 = true }

  }

  public toggleContent4() {

    if (this.istoggle4) { this.istoggle4 = false }
    else { this.istoggle4 = true }

  }

  public toggleContent5() {

    if (this.istoggle5) { this.istoggle5 = false }
    else { this.istoggle5 = true }

  }

  public toggleContent6() {

    if (this.istoggle6) { this.istoggle6 = false }
    else { this.istoggle6 = true }

  }

  onBuildApk() {

    this.spinnerService.show();
    if (localStorage.getItem("appId").length > 0) {
      this.modalRef2 = this.modalService.show(this.modal4, { class: 'gray modal-xl' });
      this.imageFile = "https://nxgcreator.s3.amazonaws.com/512PX.png";
      this.imageFileMdpi = "https://nxgcreator.s3.ap-south-1.amazonaws.com/48PX.png";
      this.imageFileHdpi = "https://nxgcreator.s3.ap-south-1.amazonaws.com/72PX.png";
      this.imageFileXhdpi = "https://nxgcreator.s3.ap-south-1.amazonaws.com/96PX.png";
      this.imageFileXXhdpi = "https://nxgcreator.s3.ap-south-1.amazonaws.com/144PX.png";
      this.imageFileXxxhdpi = "https://nxgcreator.s3.ap-south-1.amazonaws.com/192PX.png";
      this.getAppInfoForBuild(localStorage.getItem("appId"));
    }
    this.spinnerService.hide();

  }

  getAppInfoForBuild(appId: string) {

    this.spinnerService.show();

    this.isAppTypeWeb = true;
    var appPointer = {
      __type: 'Pointer',
      className: 'w_appinfo',
      objectId: localStorage.getItem("appId")
    }
    const appQ = Parse.Object.extend('app_BuildInfo');
    const query = new Parse.Query(appQ);
    query.equalTo("wAppInfo", appPointer);
    query.find().then((results) => {
      let data = JSON.stringify(results);
      let parseJson = JSON.parse(data);
      console.log("parseJson", parseJson);
      if (parseJson.length > 0) {
        debugger;
        this.isUpdateCheckVersion = true;
        this.objectid = parseJson[0]["objectId"];
        this.isShow = true; this.isEditShow = true;
        this.formApp.patchValue({ 'appname': parseJson[0]["mobAppName"] });
        this.formApp.patchValue({ 'packageName': parseJson[0]["packageName"] });
        this.formApp.patchValue({ 'appType': parseJson[0]["mobAppType"] });
        if (parseJson[0]["mobAppType"] == 'web') {
          this.formApp.controls['loginType'].disable();
          this.formApp.controls['fbLogin'].disable();
          this.formApp.controls['googleLogin'].disable();
          this.selectedMenuType = "";
        }
        if (parseJson[0]["mobAppType"] == 'semi-native') {
          this.formApp.get('loginType').enable();
          this.formApp.get('fbLogin').enable();
          this.formApp.get('googleLogin').enable();
          if (parseJson[0]["bnMenuVisible"]) {
            this.selectedMenuType = "Mobile BN Menu";
          } else if (parseJson[0]["tnMenuVisible"]) { this.selectedMenuType = "Mobile TN Menu"; }
          else { this.selectedMenuType = ""; }
        }
        this.formApp.patchValue({ 'fbAppId': parseJson[0]["fbAppId"] });
        this.formApp.patchValue({ 'loginType': parseJson[0]["nativeLoginType"] });
        this.formApp.patchValue({ 'emailID': localStorage.getItem("userEmailID") });
        this.formApp.patchValue({ 'allowAppNameOnSplash': parseJson[0]["appNameOnSplashScreen"] });
        this.formApp.patchValue({ 'googleLogin': parseJson[0]["allowLoginWithGoogle"] });
        this.formApp.patchValue({ 'fbLogin': parseJson[0]["allowLoginWithFB"] });
        this.formApp.patchValue({ 'bottomMenuVisible': parseJson[0]["bnMenuVisible"] });
        this.formApp.patchValue({ 'topMenuVisible': parseJson[0]["tnMenuVisible"] });
        this.formApp.patchValue({ 'google_services': JSON.parse(parseJson[0]["google_services"]) });
        this.formApp.patchValue({ 'objectId': parseJson[0]["checkVersionId"]["objectId"] });
        this.autoIncNo = JSON.parse(parseJson[0]["vcode"])
        debugger
        if (parseJson[0]["splash_screen"] != "") { this.imageFileSplash = parseJson[0]["splash_screen"]; }
        if (parseJson[0]["app_logo"] != "") { this.imageFile = parseJson[0]["app_logo"]; }
        if (parseJson[0]["app_icon_mdpi"] != "") { this.imageFileMdpi = parseJson[0]["app_icon_mdpi"]; }
        if (parseJson[0]["app_icon_hdpi"] != "") { this.imageFileHdpi = parseJson[0]["app_icon_hdpi"]; }
        if (parseJson[0]["app_icon_xhdpi"] != "") { this.imageFileXhdpi = parseJson[0]["app_icon_xhdpi"]; }
        if (parseJson[0]["app_icon_xxhdpi"] != "") { this.imageFileXXhdpi = parseJson[0]["app_icon_xxhdpi"]; }
        if (parseJson[0]["app_icon_xxxhdpi"] != "") { this.imageFileXxxhdpi = parseJson[0]["app_icon_xxxhdpi"]; }
      } else {
        this.formApp.patchValue({ 'appname': localStorage.getItem("appTitle") });
        this.formApp.patchValue({ 'emailID': localStorage.getItem("userEmailID") });
        this.formApp.patchValue({ 'packageName': localStorage.getItem("appname") });

        this.formApp.patchValue({ 'colorPrimary': '#3ac9d6' });
        this.formApp.patchValue({ 'colorPrimaryDark': '#3ac9d6' });
        this.formApp.patchValue({ 'colorAccent': '#3ac9d6' });
        this.formApp.patchValue({ 'textColor': '#3ac9d6' });
        var jsonObj = {
          "project_info": {
            "project_number": "499933883994",
            "firebase_url": "https://qik-ai.firebaseio.com",
            "project_id": "qik-ai",
            "storage_bucket": "qik-ai.appspot.com"
          },
          "client": [{
            "client_info": {
              "mobilesdk_app_id": "1:499933883994:android:4247a6814086853f057263",
              "android_client_info": {
                "package_name": "ai.qik" + "." + localStorage.getItem("appname")
              }
            },
            "oauth_client": [{
              "client_id": "499933883994-kn30obtkkpuli3b94qqem5nrq8c5qokn.apps.googleusercontent.com",
              "client_type": 3
            }],
            "api_key": [{
              "current_key": "AIzaSyDwH4PMK33kUC-0dnvtAwCyglBM5TV5jnk"
            }]
          }
          ],
          "configuration_version": "1"
        }
        this.formApp.patchValue({ 'google_services': jsonObj })
      }
    }, (error) => { });
    this.spinnerService.hide();

  }

  editBuildApk() { }

  showBuildHistory() {

    this.spinnerService.show();
    var appPointer = {
      __type: 'Pointer',
      className: 'app_BuildInfo',
      objectId: this.objectid
    }
    const appQ = Parse.Object.extend('app_BuildHistoryInfo');
    const query = new Parse.Query(appQ);
    query.equalTo("appBuildId", appPointer);
    query.include("loginUserId");
    query.descending("createdAt").find().then((results) => {
      let data = JSON.stringify(results);
      let parseJson = JSON.parse(data);
      this.dbList = parseJson;
      console.log("this.dbList", this.dbList);
    }, (error) => { });
    this.isEditShow = false;
    this.ModalTitle = "Build History";
    this.isTriggerApkShow = false;
    this.isHistoryShow = true;
    this.spinnerService.hide();

  }

  logout() {

    this.spinnerService.show();
    Parse.User.logOut().then(() => {
      localStorage.clear();
      var url = window.location.hostname;
      console.log(url);
      if (url == 'appcreator.qik.ai') {
        //commenting out this code as it setting localstorage even when its not neeed and interfering with the initial setup
        //localStorage.setItem("serverUrl", configuration.SERVER_URL);
        //localStorage.setItem("parseKey", configuration.PARSE_KEY);
        localStorage.setItem("imgUrl", configuration.IMG_URL);
        localStorage.setItem("urlOnly", configuration.sURL);
        localStorage.setItem("platform", "prod");
      } else if (url == 'uat-appcreator.qik.ai') {
        localStorage.setItem("serverUrl", configuration.UAT_SERVER_URL);
        localStorage.setItem("parseKey", configuration.UAT_PARSE_KEY);
        localStorage.setItem("imgUrl", configuration.UAT_IMG_URL);
        localStorage.setItem("urlOnly", configuration.uURL);
        localStorage.setItem("platform", "uat");
      } else {
        localStorage.setItem("serverUrl", configuration.DEV_SERVER_URL);
        localStorage.setItem("parseKey", configuration.DEV_PARSE_KEY);
        localStorage.setItem("imgUrl", configuration.DEV_IMG_URL);
        localStorage.setItem("urlOnly", configuration.dURL);
        localStorage.setItem("platform", "dev");
      }
      console.log(localStorage.getItem("serverUrl"));
      console.log(localStorage.getItem("parseKey"));
      console.log(localStorage.getItem("imgUrl"));
      console.log(localStorage.getItem("platform"));
      this.router.navigate(['login']);
      // After the user has logged in, emit the behavior subject changes.
      this.dataSharingService.isUserLoggedIn.next(false);
    });
    this.spinnerService.hide();

  }

  async onBuildSubmit(AppForm: NgForm) {

    this.spinnerService.show();
    try {
      if (this.selectedFile != null) { await this.onUpload(); }
      if (this.selectedFileSplash != null) { await this.onSplashUpload(); }
      if (this.selectedFileMdpi != null) { await this.onMdpiUpload(); }
      if (this.selectedFileHdpi != null) { await this.onHdpiUpload(); }
      if (this.selectedFileXhdpi != null) { await this.onXhdpiUpload(); }
      if (this.selectedFileXXhdpi != null) { await this.onXxhdpiUpload(); }
      if (this.selectedFileXxxhdpi != null) { await this.onXxxhdpiUpload(); }
    } catch { }

    const AppInfo = Parse.Object.extend("app_BuildInfo");
    const appInfo = new AppInfo();
    var appId = { "__type": 'Pointer', "className": 'w_appinfo', "objectId": localStorage.getItem("appId") };
    if (this.isEditShow) {
      this.autoIncNo = this.autoIncNo + 1;
      const VersionInfo = Parse.Object.extend("app_CheckVersion");
      const queryVersion = new Parse.Query(VersionInfo);
      queryVersion.get(AppForm["objectId"]).then((object1) => {
        object1.set("updateAvailable", AppForm["updateAvailable"]);
        object1.set("mandatory", AppForm["mandatory"]);
        object1.set("softupdate", 0);
        object1.set("appUrl", "https://play.google.com/store/apps/details?id=");
        object1.save().then(async (res: any) => {
          const query = new Parse.Query(AppInfo);
          //Update the app info
          query.get(this.objectid).then((object) => {
            var isGoogleLogin = AppForm["googleLogin"];
            if (!(isGoogleLogin)) { isGoogleLogin = false; } else { isGoogleLogin = true; }
            var isFacebookLogin = AppForm["googleLogin"];
            if (!(isFacebookLogin)) { isFacebookLogin = false; } else { isFacebookLogin = true; }

            object.set("fbAppId", AppForm["fbAppId"]);
            object.set("packageName", AppForm["packageName"]);
            object.set("orgID", "");
            object.set("appID", "");
            object.set("colorPrimary", AppForm["colorPrimary"]);
            object.set("colorPrimaryDark", AppForm["colorPrimaryDark"]);
            object.set("colorAccent", AppForm["colorAccent"]);
            object.set("textColor", AppForm["textColor"]);
            object.set("splash_screen", this.imageFileSplash);
            object.set("app_logo", this.imageFile);
            object.set("app_icon_mdpi", this.imageFileMdpi);
            object.set("app_icon_hdpi", this.imageFileHdpi);
            object.set("app_icon_xhdpi", this.imageFileXhdpi);
            object.set("app_icon_xxhdpi", this.imageFileXXhdpi);
            object.set("app_icon_xxxhdpi", this.imageFileXxxhdpi);
            object.set("bnMenuVisible", AppForm["bottomMenuVisible"]);
            object.set("tnMenuVisible", AppForm["topMenuVisible"]);
            object.set("mobAppName", AppForm["appname"]);
            object.set("appNameOnSplashScreen", AppForm["allowAppNameOnSplash"]);
            object.set("vcode", this.autoIncNo);
            object.set("vname", this.autoIncNo.toString());
            object.set("allowLoginWithGoogle", isGoogleLogin);
            object.set("allowLoginWithFB", isFacebookLogin);
            object.set("nativeLoginType", AppForm["loginType"]);
            object.set("wAppInfo", appId);
            object.set("mobAppType", AppForm["appType"]);
            object.set("bnMenuId", "");
            object.set("rootDeviceDetector", AppForm["rootDeviceDetector"]);
            object.set("screenShotDetector", AppForm["screenShotDetector"]);
            console.log("AppForm google services", JSON.stringify(AppForm["google_services"]));
            object.set("google_services", JSON.stringify(AppForm["google_services"]));
            object.save().then(async (res: any) => {
              const AppBuildHistoryInfo = Parse.Object.extend("app_BuildHistoryInfo");
              const appBuildHistoryInfo = new AppBuildHistoryInfo();
              var appBuildId = { "__type": 'Pointer', "className": 'app_BuildInfo', "objectId": this.objectid };
              var userId = { "__type": 'Pointer', "className": '_User', "objectId": localStorage.getItem("userObjectId") };
              appBuildHistoryInfo.set("appBuildId", appBuildId);
              appBuildHistoryInfo.set("loginUserId", userId);
              appBuildHistoryInfo.save().then(async (res: any) => {
                const httpOptions5 = {
                  headers: new HttpHeaders({
                    'Authorization': configuration.Build_Authorization
                  })
                };
                var appInfo = `${localStorage.getItem("serverUrl")}/classes/app_BuildInfo/${this.objectid}?include=wAppInfo`;
                var url = localStorage.getItem("buildApkURL") + "appinfo=" + appInfo + "&emails=" + AppForm["emailID"];
                console.log("url", url);
                return this.http.post(url, '', httpOptions5).toPromise().then(
                  result => {
                    Swal.fire({
                      title: 'Success!',
                      text: 'App build triggered successfully',
                      imageUrl: '../assets/img/buildImg.gif',
                      imageHeight: 200,
                      imageAlt: 'Build Image',
                    })
                  }, (error: any) => {
                    // Save fails
                    this.toastr.error('Failed to trigger build with error code: ' + error.message);
                  });
              }, (error: any) => {
                // Save fails
                this.toastr.error('Failed to trigger build with error code: ' + error.message);
              });
            }, (error: any) => {
              // Save fails
              this.toastr.error('Failed to trigger build with error code: ' + error.message);
            });
          }, (error: any) => {
            // Save fails
            this.toastr.error('Failed to trigger build with error code: ' + error.message);
          });
        }, (error: any) => {
          // Save fails
          this.toastr.error('Failed to trigger build with error code: ' + error.message);
        });
      }, (error: any) => {
        // Save fails
        this.toastr.error('Failed to trigger build with error code: ' + error.message);
      });
    } else {
      //Save version Info
      const VersionInfo = Parse.Object.extend("app_CheckVersion");
      const versionInfo = new VersionInfo();
      versionInfo.set("updateAvailable", false);
      versionInfo.set("mandatory ", false);
      versionInfo.set("softupdate", 0);
      versionInfo.set("appUrl", "https://play.google.com/store/apps/details?id=");
      await versionInfo.save()
        .then((res: any) => {
          var versionId = { "__type": 'Pointer', "className": 'app_CheckVersion', "objectId": res.id };
          //save the app info
          //After disableing checkboxes values getting undefined so written below code
          var isGoogleLogin = AppForm["googleLogin"];
          if (!(isGoogleLogin)) { isGoogleLogin = false; }
          else { isGoogleLogin = true; }
          var isFacebookLogin = AppForm["googleLogin"];
          if (!(isFacebookLogin)) { isFacebookLogin = false; }
          else { isFacebookLogin = true; }

          if (AppForm["appType"] == "web") {
            appInfo.set("allowLoginWithGoogle", false);
            appInfo.set("allowLoginWithFB", false);
            appInfo.set("google_services", JSON.stringify(AppForm["google_services"]));
          } else {
            appInfo.set("allowLoginWithGoogle", isGoogleLogin);
            appInfo.set("allowLoginWithFB", isFacebookLogin);
            appInfo.set("bnMenuVisible", AppForm["bottomMenuVisible"]);
            appInfo.set("tnMenuVisible", AppForm["topMenuVisible"]);
            appInfo.set("google_services", JSON.stringify(AppForm["google_services"]));
          }

          appInfo.set("fbAppId", AppForm["fbAppId"]);
          appInfo.set("packageName", AppForm["packageName"]);
          appInfo.set("colorPrimary", AppForm["colorPrimary"]);
          appInfo.set("colorPrimaryDark", AppForm["colorPrimaryDark"]);
          appInfo.set("colorAccent", AppForm["colorAccent"]);
          appInfo.set("textColor", AppForm["textColor"]);
          appInfo.set("splash_screen", this.imageFileSplash);
          appInfo.set("app_logo", this.imageFile);
          appInfo.set("app_icon_mdpi", this.imageFileMdpi);
          appInfo.set("app_icon_hdpi", this.imageFileHdpi);
          appInfo.set("app_icon_xhdpi", this.imageFileXhdpi);
          appInfo.set("app_icon_xxhdpi", this.imageFileXXhdpi);
          appInfo.set("app_icon_xxxhdpi", this.imageFileXxxhdpi);
          appInfo.set("mobAppName", AppForm["appname"]);
          appInfo.set("appNameOnSplashScreen", AppForm["allowAppNameOnSplash"]);
          appInfo.set("nativeLoginType", AppForm["loginType"]);
          appInfo.set("wAppInfo", appId);
          appInfo.set("mobAppType", AppForm["appType"]);
          appInfo.set("bnMenuId", "");
          appInfo.set("vcode", 1);
          appInfo.set("vname", "1");
          appInfo.set("rootDeviceDetector", AppForm["rootDeviceDetector"]);
          appInfo.set("screenShotDetector", AppForm["screenShotDetector"]);
          appInfo.set("checkVersionId", versionId);
          appInfo.set("codeVersion", "1");
          appInfo.save().then(async (res: any) => {
            debugger
            console.log("RES", res);
            this.objectid = res.id;
            const AppBuildHistoryInfo = Parse.Object.extend("app_BuildHistoryInfo");
            const appBuildHistoryInfo = new AppBuildHistoryInfo();
            var appBuildId = { "__type": 'Pointer', "className": 'app_BuildInfo', "objectId": this.objectid };
            var userId = { "__type": 'Pointer', "className": '_User', "objectId": localStorage.getItem("userObjectId") };
            appBuildHistoryInfo.set("appBuildId", appBuildId);
            appBuildHistoryInfo.set("loginUserId", userId);
            appBuildHistoryInfo.save().then(async (res: any) => {
              const httpOptions5 = {
                headers: new HttpHeaders({
                  'Authorization': configuration.Build_Authorization
                })
              };
              var appInfo = `${localStorage.getItem("serverUrl")}/classes/app_BuildInfo/${this.objectid}?include=wAppInfo`;
              var url = localStorage.getItem("buildApkURL") + "appinfo=" + appInfo + "&emails=" + AppForm["emailID"];
              console.log("url", url);
              return this.http.post(url, '', httpOptions5).toPromise().then(
                result => {
                  Swal.fire({
                    title: 'Success!',
                    text: 'App build triggered successfully',
                    imageUrl: '../assets/img/buildImg.gif',
                    imageHeight: 200,
                    imageAlt: 'Build Image',
                  })
                }, (error) => {
                  // fail
                  this.toastr.error('Failed to trigger build with error code: ' + error.message);
                });
            }, (error: any) => {
              // Save fails
              this.toastr.error('Failed to trigger build with error code: ' + error.message);
            });
          }, (error: any) => {
            // Save fails
            this.toastr.error('Failed to trigger build with error code: ' + error.message);
          });
        }, (error: any) => {
          // Save fails
          this.toastr.error('Failed to trigger build with error code: ' + error.message);
        });
    }
    this.spinnerService.hide();

  }

  onAppTypeSelect(event: any) {

    this.spinnerService.show();
    if (event.target.value == 'web') {
      this.isAppTypeWeb = true;
      this.formApp.controls['loginType'].disable();
      this.formApp.controls['fbLogin'].disable();
      this.formApp.controls['googleLogin'].disable();
    }
    if (event.target.value == 'native') {
      this.isAppTypeWeb = false;
      this.formApp.get('loginType').enable();
      this.formApp.get('fbLogin').enable();
      this.formApp.get('googleLogin').enable();
    }
    if (event.target.value == 'semi-native') {
      this.isAppTypeWeb = false;
      this.formApp.get('loginType').enable();
      this.formApp.get('fbLogin').enable();
      this.formApp.get('googleLogin').enable();
    }
    this.spinnerService.hide();

  }

  /*Image upload for mApp */
  // For App Logo validation & preview
  async onFileChanged(event) {

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var mimeType = file["type"];
      if (mimeType.match(/image\/*/) == null) {
        Swal.fire(
          'Error in Upload!',
          "Only images are supported!",
          'error'
        );
      } else {
        const img = new Image();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          img.src = _event.target.result;
          img.onload = rs => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (width == 512 && height == 512) {
              this.selectedFile = event.target.files[0];
              this.imageFile = reader.result;
            } else {
              Swal.fire(
                'Error in Upload!',
                "Image should be 512 x 512 size!",
                'error'
              );
            }
          };
        };
      }
    }

  }

  // For Mdpi Logo validation & preview
  async onFileMdpiChanged(event) {

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var mimeType = file["type"];
      if (mimeType.match(/image\/*/) == null) {
        Swal.fire(
          'Error in Upload!',
          "Only images are supported!",
          'error'
        );
      } else {
        const img = new Image();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          img.src = _event.target.result;
          img.onload = rs => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (width == 48 && height == 48) {
              this.selectedFileMdpi = event.target.files[0];
              this.imageFileMdpi = reader.result;
            } else {
              Swal.fire(
                'Error in Upload!',
                "Image should be 48 x 48 size!",
                'error'
              );
            }
          };
        };
      }
    }

  }

  // For Xhdpi Logo validation & preview
  async onFilexhdpiChanged(event) {

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var mimeType = file["type"];
      if (mimeType.match(/image\/*/) == null) {
        Swal.fire(
          'Error in Upload!',
          "Only images are supported!",
          'error'
        );
      } else {
        const img = new Image();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          img.src = _event.target.result;
          img.onload = rs => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (width == 96 && height == 96) {
              this.selectedFileXhdpi = event.target.files[0];
              this.imageFileXhdpi = reader.result;
            } else {
              Swal.fire(
                'Error in Upload!',
                "Image should be 96 x 96 size!",
                'error'
              );
            }
          };
        };
      }
    }

  }

  // For Hdpi Logo validation & preview
  async onFileHdpiChanged(event) {

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var mimeType = file["type"];
      if (mimeType.match(/image\/*/) == null) {
        Swal.fire(
          'Error in Upload!',
          "Only images are supported!",
          'error'
        );
      } else {
        const img = new Image();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          img.src = _event.target.result;
          img.onload = rs => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (width == 72 && height == 72) {
              this.selectedFileHdpi = event.target.files[0];
              this.imageFileHdpi = reader.result;
            } else {
              Swal.fire(
                'Error in Upload!',
                "Image should be 72 x 72 size!",
                'error'
              );
            }
          };
        };
      }
    }

  }

  // For XXhdpi Logo validation & preview
  async onFileXXhdpiChanged(event) {

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var mimeType = file["type"];
      if (mimeType.match(/image\/*/) == null) {
        Swal.fire(
          'Error in Upload!',
          "Only images are supported!",
          'error'
        );
      } else {
        const img = new Image();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          img.src = _event.target.result;
          img.onload = rs => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (width == 144 && height == 144) {
              this.selectedFileXXhdpi = event.target.files[0];
              this.imageFileXXhdpi = reader.result;
            } else {
              Swal.fire(
                'Error in Upload!',
                "Image should be 144 x 144 size!",
                'error'
              );
            }
          };
        };
      }
    }

  }

  // For Xxxhdpi Logo validation & preview
  async onFileXxxhdpiChanged(event) {

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var mimeType = file["type"];
      if (mimeType.match(/image\/*/) == null) {
        Swal.fire(
          'Error in Upload!',
          "Only images are supported!",
          'error'
        );
      } else {
        const img = new Image();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          img.src = _event.target.result;
          img.onload = rs => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (width == 192 && height == 192) {
              this.selectedFileXxxhdpi = event.target.files[0];
              this.imageFileXxxhdpi = reader.result;
            } else {
              Swal.fire(
                'Error in Upload!',
                "Image should be 192 x 192 size!",
                'error'
              );
            }
          };
        };
      }
    }

  }

  // For Splash Logo validation & preview
  async onFileSplashChanged(event) {

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      var mimeType = file["type"];
      if (mimeType.match(/image\/*/) == null) {
        Swal.fire(
          'Error in Upload!',
          "Only images are supported!",
          'error'
        );
      } else {
        const img = new Image();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          img.src = _event.target.result;
          img.onload = rs => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);

            if (width == 1080 && height == 1820) {
              this.selectedFileSplash = event.target.files[0];
              this.imageFileSplash = reader.result;
            } else {
              Swal.fire(
                'Error in Upload!',
                "Image should be 1080 x 1820 size!",
                'error'
              );
            }
          };
        };
      }
    }

  }

  //For logo upload
  async onUpload() {

    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);
    await this.http.post(localStorage.getItem("imgUrl"), fd).toPromise().then(
      res => {
        if (res["status"] == "Error") { this.toastr.error(res['message'], "Error while Uploading Image!"); }
        else { this.imageFile = res['imageUrl']; }
      });

  }

  //For splash upload
  async onSplashUpload() {

    const fd = new FormData();
    fd.append('file', this.selectedFileSplash, this.selectedFileSplash.name);
    await this.http.post(localStorage.getItem("imgUrl"), fd).toPromise().then(
      res => {
        if (res["status"] == "Error") { this.toastr.error(res['message'], "Error while Uploading Image!"); }
        else { this.imageFileSplash = res['imageUrl']; }
      });

  }

  async onMdpiUpload() {

    const fd = new FormData();
    fd.append('file', this.selectedFileMdpi, this.selectedFileMdpi.name);
    await this.http.post(localStorage.getItem("imgUrl"), fd).toPromise().then(
      res => {
        if (res["status"] == "Error") { this.toastr.error(res['message'], "Error while Uploading Image!"); }
        else { this.imageFileMdpi = res['imageUrl']; }
      });

  }

  async onHdpiUpload() {

    const fd = new FormData();
    fd.append('file', this.selectedFileHdpi, this.selectedFileHdpi.name);
    await this.http.post(localStorage.getItem("imgUrl"), fd).toPromise().then(
      res => {
        if (res["status"] == "Error") { this.toastr.error(res['message'], "Error while Uploading Image!"); }
        else { this.imageFileHdpi = res['imageUrl']; }
      });

  }

  async onXhdpiUpload() {

    const fd = new FormData();
    fd.append('file', this.selectedFileXhdpi, this.selectedFileXhdpi.name);
    await this.http.post(localStorage.getItem("imgUrl"), fd).toPromise().then(
      res => {
        if (res["status"] == "Error") { this.toastr.error(res['message'], "Error while Uploading Image!"); }
        else { this.imageFileXhdpi = res['imageUrl']; }
      });

  }

  async onXxhdpiUpload() {

    const fd = new FormData();
    fd.append('file', this.selectedFileXXhdpi, this.selectedFileXXhdpi.name);
    await this.http.post(localStorage.getItem("imgUrl"), fd).toPromise().then(
      res => {
        if (res["status"] == "Error") { this.toastr.error(res['message'], "Error while Uploading Image!"); }
        else { this.imageFileXXhdpi = res['imageUrl']; }
      });

  }

  async onXxxhdpiUpload() {

    const fd = new FormData();
    fd.append('file', this.selectedFileXxxhdpi, this.selectedFileXxxhdpi.name);
    await this.http.post(localStorage.getItem("imgUrl"), fd).toPromise().then(
      res => {
        if (res["status"] == "Error") { this.toastr.error(res['message'], "Error while Uploading Image!"); }
        else { this.imageFileXxxhdpi = res['imageUrl']; }
      });

  }

  onPublishApk() {

    this.spinnerService.show();
    this.formPublish.patchValue({ "uatappname": localStorage.getItem('appname') });
    this.modalRef = this.modalService.show(this.modal, { class: 'gray modal-xl' });
    this.spinnerService.hide();

  }

  matchingPasswords({ passwordKey, confirmPasswordKey }: { passwordKey: string; confirmPasswordKey: string; }) {

    return (group: any): {
      [key: string]: any
    } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return { mismatchedPasswords: true };
      }
    }

  }

  async onPublishSubmit(formPublish: NgForm) {

    this.spinnerService.show();
    var bUrl: any;
    if (formPublish["destinationPath"] == "$localhost") { bUrl = localStorage.getItem("serverUrl"); }
    else if (formPublish["destinationPath"] == "$uat") { bUrl = configuration.UAT_SERVER_URL; }
    else if (formPublish["destinationPath"] == "$production") { bUrl = configuration.SERVER_URL; }

    var pubDetails = {
      "appname": formPublish["uatappname"],
      "targetConString": formPublish["destinationPath"],
      "sourceConString": formPublish["sourcePath"],
      "baseurl": bUrl
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': configuration.PARSE_KEY
      })
    };

    var url = `${localStorage.getItem("urlOnly")}/migrateData`;
    return await this.http.post(url, pubDetails, httpOptions).toPromise().then(
      result => {
        this.toastr.success("App Published Successfully on " + formPublish["destinationPath"] + "!", "Success");
        return result;
      }, (error) => {
        this.toastr.error("Some error occurred while publishing!", "Error")
        return error["error"];
      });
    this.spinnerService.hide();

  }

  visible = true; selectable = true; removable = true; addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  fruits: Fruit[] = [];

  add(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) { this.fruits.push({ name: value.trim() }); }

    // Reset the input value
    if (input) { input.value = ''; }

  }

  remove(fruit: Fruit): void {

    const index = this.fruits.indexOf(fruit);
    if (index >= 0) { this.fruits.splice(index, 1); }

  }

  clickProfile() { this.router.navigate(['editProfile']); }

  async onBnMenuChanged(event: any) {

    debugger
    this.spinnerService.show();
    if (event.target.checked == true) {
      this.selectedMenuType = "Mobile BN Menu";
      await this.loadMenuByType(this.selectedMenuType);
      this.formApp.patchValue({ 'topMenuVisible': false });
    } else { this.selectedMenuType = ""; }
    this.spinnerService.hide();

  }

  onUpdateAvailableChanged(event: any) {

    if (event.target.checked == true) { this.isUpdateCheckVersion = true; }
    else { this.isUpdateCheckVersion = false; }

  }

  async onTnMenuChanged(event: any) {

    this.spinnerService.show();
    if (event.target.checked == true) {
      this.selectedMenuType = "Mobile TN Menu";
      await this.loadMenuByType(this.selectedMenuType);
      this.formApp.patchValue({ 'bottomMenuVisible': false });
    } else { this.selectedMenuType = ""; }
    this.spinnerService.hide();

  }

  async loadMenuByType(menuType: string) {

    debugger
    var appPointer = {
      __type: 'Pointer',
      className: 'w_appinfo',
      objectId: localStorage.getItem("appId")
    }

    const menuData = Parse.Object.extend('w_menu');
    const query = new Parse.Query(menuData);
    query.equalTo("appId", appPointer);
    query.equalTo("menuType", menuType);
    this.menuList = [];
    this.menuList = await query.descending("updatedAt").find().then(function (result) {
      debugger
      console.log("result", result);
      return result;
    }, (error) => {
      // The object was not retrieved successfully.
      this.toastr.error(error.message, 'Error!!!');
    });
    console.log("this.menuList", this.menuList);

  }

  changePass() {

    this.spinnerService.show();
    this.formPass.reset();
    this.modalRef1 = this.modalService.show(this.passModal, { class: 'gray modal-xl' });
    this.spinnerService.hide();

  }

  async onPasswordSubmit(formPass: NgForm) {

    this.spinnerService.show();
    if (this.formPass.valid) {
      if (localStorage.getItem("userEmailID") != null) {
        let result: any;
        result = await this.rec.loginApi(localStorage.getItem("userEmailID"), formPass["oldPass"]);
        console.log(result);
        if ("sessionToken" in result) {
          const user = Parse.Object.extend("_User");
          const users = new user();
          const query = new Parse.Query(users);
          await query.get(result["objectId"])
            .then(async (res) => {
              res.set("password", formPass["confirmPass"]);
              await res.save()
                .then(res1 => {
                  this.toastr.success("Password changed successfully! Please login!", "Success");
                  this.modalRef1.hide();
                  this.logout();
                }, (error: any) => { this.toastr.error('Error in updating: ' + error.message, 'Error'); });
            });
        } else { this.toastr.error("Error! Your Old password might be mismatched!", "Error!"); }
      }
    } else {
      this.validateAllFormFields(this.formPass);
      this.toastr.error("Please fill all mandatory fields!", "Error");
    }
    this.spinnerService.hide();

  }

  validateAllFormFields(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) { control.markAsTouched({ onlySelf: true }); }
      else if (control instanceof FormGroup) { this.validateAllFormFields(control); }
      else if (control instanceof FormArray) {
        for (const control1 of control.controls) {
          if (control1 instanceof FormControl) { control1.markAsTouched({ onlySelf: true }); }
          if (control1 instanceof FormGroup) { this.validateAllFormFields(control1); }
        }
      }
    });

  }

  clickOrgInfo() { this.router.navigate(['editOrganization']); }

}



