import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class SetFooterVersionService {

  private _footerVersion = new BehaviorSubject('');
  currentfootVersion = this._footerVersion.asObservable();

  constructor() { }

  changefooterVersion(version: string) { this._footerVersion.next(version) }

}
