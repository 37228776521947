import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ObjectServiceService } from '../../service/object-service.service';
import { MatTableDataSource } from '@angular/material';
import * as Parse from 'parse';
import { ToastrService } from 'ngx-toastr';
import { CustomQueryBuilderComponent } from 'src/app/component/custom-query-builder/custom-query-builder.component'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AppServiceService } from 'src/app/service/app-service.service';

@Component({
  selector: 'app-filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrls: ['./filter-builder.component.css']
})

export class FilterBuilderComponent implements OnInit {

  formFilter: FormGroup; displayPointerForm: FormGroup; valuePointerForm: FormGroup;

  sortedColumnFilters: any = []; classList: any = []; valueFieldListFilter: any = []; ddlType: any = [];
  fieldList: any = []; valueFieldList: any = []; fieldListFilter: any[]; tableList: any = []; fieldsArray: any = [];
  columnFilters: any = []; itemList: any = []; itemNameList: any = []; txtFinalItemList: any = []; appList: any = [];
  strList: any = []; pList: any = []; orderFieldList1: any = []; chkPList: any = []; dPointerList: any = [];
  vPointerList: any = [];

  modalRef: BsModalRef;

  selectedFilterFields: string = ""; uniqueID: string = ""; dataTypeFilter: string; selectedFilterField: string = "";
  selectedFilterValueField: string = ""; selectedFilterDateDefault: string = ""; selectedFilterType: string = "";
  selectedFilterClass: any; filterArrayindex: any; bindingType: string = ""; txtItemName: string = ""; txtItem: string = "";
  backColorModel: string; selectedApp: any; itemErrorMsg: string = ""; dropdowncount: number = 0; controlNm: string = "";
  pField: string = ""; selectedOrder1: string = ""; selectedOrderField1: string = ""; selectedOrderString1: string = "";
  orderErrorMsg1: string = ""; fLabel: string = ""; txtfMinDate: number = 0; txtfMaxDate: number = 0; fPlaceholder: string = "";
  txtfTimeInterval: number = 0; txtfTimeCaption: string = ""; txtfHelpLink: string = ""; txtfHelpBody: string = "";
  txtfTimeFormat: string = ""; txtfDateFormat: string = ""; tLabel: string = ""; txttTimeCaption: string = "";
  txttMinDate: number = 0; txttMaxDate: number = 0; txttTimeInterval: number = 0; tPlaceholder: string = "";
  txttTimeFormat: string = ""; txttDateFormat: string = ""; txttHelpLink: string = ""; txttHelpBody: string = "";
  chkDependantCount: number = 0; chkPField: string = ""; receivedChildMessage: string; messageToSendP: string = '';
  className: string;

  isEditFilter: boolean = false; dependantField: boolean = false; isItemError: boolean = false; isOrderError1: boolean = false;
  dependantShow: boolean = false; fselectedShowTimeSelect: boolean = false; tselectedOnlyTimeSelect: boolean = false;
  fselectedOnlyTimeSelect: boolean = false; tselectedShowTimeSelect: boolean = false; chkDependantShow: boolean = false;
  chkDependField: boolean = false; isDisplayOk: boolean = false; isValueOk: boolean = false;

  @ViewChild('filterfield') modal3: TemplateRef<any>;
  @ViewChild(CustomQueryBuilderComponent, { static: false }) cqueryBuilder: CustomQueryBuilderComponent;
  dataSource = new MatTableDataSource(this.tableList);

  constructor(private modalService: BsModalService, private formBuilder: FormBuilder, private toastr: ToastrService,
    private rec: ObjectServiceService, private res: AppServiceService, private spinnerService: Ng4LoadingSpinnerService) {

    Parse.initialize(localStorage.getItem("parseKey"));
    (Parse as any).serverURL = localStorage.getItem("serverUrl");

    this.formFilter = this.formBuilder.group({
      filtercolxs: [''],
      filtercolmd: [''],
      filtercollg: [''],
      filterFields: [''],
      filterType: [''],
      filterTitle: [''],
      filterDateDefault: [''],
      filterBgColor: [''],
      filterMultiSelectList: [''],
      filterPlaceholder: [''],
      filterDesc: [''],
      filterWhereCondition: [''],
      filterClass: [''],
      filterDDLField: [''],
      vDDLfield: [''],
      isPointer: [false],
      queryField: [''],
      dateFilter: [''],
      filtAggKey: [''],
      filtOrder: ['']
    });

    this.displayPointerForm = this.formBuilder.group({
      displayPointerSet: this.formBuilder.array([this.createDPointerFormGroup(this.selectedFilterClass)])
    });

    this.valuePointerForm = this.formBuilder.group({
      valuePointerSet: this.formBuilder.array([this.createVPointerFormGroup(this.selectedFilterClass)])
    });

  }

  getMessage(message: string) { this.receivedChildMessage = message; }

  async ngOnInit() {

    this.spinnerService.show();
    this.sortedColumnFilters = [];
    debugger
    this.className = localStorage.getItem("className");
    console.log("this.className", this.className);
    await this.loadFieldBinding(this.className);
    if (localStorage.getItem("editRptData")) {
      this.sortedColumnFilters = JSON.parse(localStorage.getItem("filterRows"));
      console.log("this.sortedColumnFilters", this.sortedColumnFilters);
    } else { localStorage.setItem("filterRows", JSON.stringify(this.sortedColumnFilters)); }

    this.spinnerService.hide();

  }

  async loadFilterForReport() {

    this.sortedColumnFilters = [];
    debugger
    this.className = localStorage.getItem("className");
    console.log("this.className", this.className);
    this.loadFieldBinding(this.className);
    if (localStorage.getItem("editRptData")) {
      this.sortedColumnFilters = JSON.parse(localStorage.getItem("filterRows"));
      console.log("this.sortedColumnFilters", this.sortedColumnFilters);
    } else { localStorage.setItem("filterRows", JSON.stringify(this.sortedColumnFilters)); }

  }

  async ngAfterViewInit() { this.cqueryBuilder.ngOnInit(); }

  onfilterFieldsSelect(event: any) {

    debugger;
    var fdDetail = this.valueFieldList.filter(i => i.fd == this.selectedFilterFields);
    this.dataTypeFilter = fdDetail[0].detail.type;

    console.log("this.dataTypeFilter", this.dataTypeFilter);
    this.getDropDownUsingFilterType(this.dataTypeFilter);
    this.selectedFilterType = "";

  }

  async getDropDownUsingFilterType(dataType: string) {

    debugger

    switch (dataType) {
      case "String":
        this.ddlType = [
          { "Name": "TextBox" },
          { "Name": "DropDown" }
        ];
        break;
      case "Number":
        this.ddlType = [
          { "Name": "TextBox" },
          { "Name": "DropDown" }
        ];
        break;
      case "Pointer":
        this.ddlType = [
          { "Name": "TextBox" },
          { "Name": "DropDown" }
        ];
        break;
      case "Date":
        this.ddlType = [
          { "Name": "FromDate" },
          { "Name": "ToDate" },
          { "Name": "AdvancedFromToDate" }
        ];
        break;
      case "Boolean":
        this.ddlType = [
          { "Name": "DropDown" },
          { "Name": "Radio" },
          { "Name": "CheckBox" }
        ];
        break;
      default:
        break;
    }

  }

  async selectFilterClassHandler(event: any) {

    this.spinnerService.show();
    var selectedText = event.target['options'][event.target['options'].selectedIndex].value;
    this.selectedFilterClass = selectedText;

    this.selectedFilterField = ''; this.selectedFilterValueField = '';
    this.fieldListFilter = []; this.valueFieldListFilter = []; this.dPointerList = []; this.vPointerList = [];

    //bind field
    await this.loadDPointerList(this.selectedFilterClass);
    await this.loadVPointerList(this.selectedFilterClass);

    /***** Display Pointer *****/
    const control = <FormArray>this.displayPointerForm.controls['displayPointerSet'];
    if (control.length > 1) {
      for (let i = control.length - 1; i >= 0; i--) {
        if (i != 0) {
          control.removeAt(i)
        }
      }
    }

    this.displayPointerForm.reset();
    (this.displayPointerForm.get('displayPointerSet') as FormArray).at(0).patchValue({
      classNm: this.selectedFilterClass,
      isShow: false
    });
    this.isDisplayOk = false;

    /***** Value Pointer List ******/

    const control1 = <FormArray>this.valuePointerForm.controls['valuePointerSet'];
    if (control1.length > 1) {
      for (let i = control1.length - 1; i >= 0; i--) {
        if (i != 0) {
          control1.removeAt(i)
        }
      }
    }

    this.valuePointerForm.reset();
    (this.valuePointerForm.get('valuePointerSet') as FormArray).at(0).patchValue({
      classNm: this.selectedFilterClass,
      isShow: false
    });
    this.isValueOk = false;
    this.spinnerService.hide();

  }

  async loadFieldBindingFilter(classNm: string) {

    try {
      this.spinnerService.show();
      if (classNm != undefined || classNm != null) {
        var className = classNm;
        var classParam = { "className": className }

        await this.rec.getSchemaByClass(classParam).then(result => {
          let ff1List = []; this.valueFieldListFilter = []; this.fieldListFilter = [];
          ff1List.push(result["result"].fields);

          //array of fields
          for (let key of ff1List) {
            //dyanamice key Item
            for (var i in key) {
              //push value of dynamic key

              if (i == 'createdAt' || i == 'updatedAt' || i == 'ACL') {
                //don't push
              } else {
                this.fieldListFilter.push({ "fd": i, "detail": key[i] });
                this.valueFieldListFilter.push({ "fd": i, "detail": key[i] });
              }
            }
          }
          this.spinnerService.hide();
        });
        console.log("this.fieldListFilter", this.fieldListFilter);
        console.log("this.valueFieldListFilter", this.valueFieldListFilter);
      }
    } catch { }

  }

  async selectTypeHandler() {

    this.spinnerService.show();
    if (this.bindingType == "dynamic") {
      this.classList = []; this.fieldListFilter = []; this.valueFieldListFilter = [];
      this.selectedFilterClass = null; this.selectedFilterField = null; this.selectedFilterValueField = null;
      this.dependantField = false;

      await this.getApps();
      this.selectedApp = localStorage.getItem("appname");
      await this.loadClasses(this.selectedApp);

      if (this.formFilter.value.filterType == "DropDown") { this.checkForDependantList(); }
      if (this.formFilter.value.filterType == "CheckBox") { this.checkForChkDependantList(); }

    }
    else { this.itemList = []; this.itemNameList = []; this.txtFinalItemList = []; }

    this.spinnerService.hide();

  }

  selectFilterOrder(event: any) {

    if (event == true) {
      if (localStorage.getItem("filterOrder")) {
        localStorage.setItem("filterOrder", localStorage.getItem("filterOrder") + ',' + this.selectedFilterFields);
      }
      else { localStorage.setItem("filterOrder", this.selectedFilterFields); }
    } else {
      this.strList = localStorage.getItem("filterOrder").split(',');
      console.log("this.strList", this.strList);
    }
    console.log("selectFilterOrder", localStorage.getItem("filterOrder"));

  }

  deleteFilter(jlist: any[]) {

    this.sortedColumnFilters = this.sortedColumnFilters.filter(item => item !== jlist);
    this.toastr.success("Filter removed successfully ", "Success");
    localStorage.setItem("filterRows", JSON.stringify(this.sortedColumnFilters));
    console.log(" this.sortedColumnFilters", this.sortedColumnFilters);

  }

  dropFilter(event: CdkDragDrop<string[]>) { moveItemInArray(this.sortedColumnFilters, event.previousIndex, event.currentIndex); }

  async addfilter() {

    this.spinnerService.show();

    this.formFilter.reset();
    this.classList = []; this.fieldListFilter = []; this.valueFieldListFilter = []; this.ddlType = [];
    this.selectedFilterType = this.dataTypeFilter = this.selectedFilterFields = this.selectedFilterDateDefault = "";
    this.orderFieldList1 = [];

    this.fLabel = ""; this.fselectedShowTimeSelect = false; this.fselectedOnlyTimeSelect = false;
    this.txtfMinDate = 0; this.txtfMaxDate = 0; this.txtfTimeInterval = 0; this.txtfTimeCaption = "";
    this.txtfTimeFormat = ""; this.txtfDateFormat = ""; this.fPlaceholder = ""; this.txtfHelpBody = "";
    this.txtfHelpLink = "";

    this.tLabel = ""; this.tselectedShowTimeSelect = false; this.tselectedOnlyTimeSelect = false;
    this.txttMinDate = 0; this.txttMaxDate = 0; this.txttTimeInterval = 0; this.txttTimeCaption = "";
    this.txttTimeFormat = ""; this.txttDateFormat = ""; this.tPlaceholder = "";
    this.txttHelpBody = ""; this.txttHelpLink = "";

    this.txtItemName = this.txtItem = "";
    this.isEditFilter = false;
    this.loadClasses(this.selectedApp);
    this.formFilter.patchValue({ 'filtercolxs': '12' });
    this.formFilter.patchValue({ 'filtercolmd': '4' });
    this.formFilter.patchValue({ 'filtercollg': '4' });
    this.backColorModel = '#ffffff';
    this.modalRef = this.modalService.show(this.modal3, { class: 'my-modal9' });
    this.spinnerService.hide();

  }


  onFilterSubmit() {

    debugger
    let jsonObj;
    let _whereCondition = {};
    var whereCondition = this.formFilter.value.filterWhereCondition;
    if (whereCondition !== "" && whereCondition !== undefined) {
      var romoveHashDollar = this.receivedChildMessage.split("#$").join("$")
      var addHashDollar = romoveHashDollar.split("$").join("#$");
      _whereCondition = JSON.parse(addHashDollar);
    } else { _whereCondition = {} }

    this.uniqueID = this.getRandomNum(this.formFilter.value.filterType);

    if (this.formFilter.value.filterType == 'TextBox') {

      var aggrKey = "";
      if (this.formFilter.value.filtAggKey) { aggrKey = this.selectedFilterFields; } else { aggrKey = ""; }
      jsonObj = {
        "colxs": this.formFilter.value.filtercolxs,
        "colmd": this.formFilter.value.filtercolmd,
        "collg": this.formFilter.value.filtercollg,
        "widget": {
          "type": "TextBox",
          "id": this.uniqueID,
          "dataType": this.dataTypeFilter,
          "bgColor": this.backColorModel,
          "label": this.formFilter.value.filterTitle,
          "description": this.formFilter.value.filterDesc,
          "displayKey": "",
          "valueKey": this.selectedFilterFields,
          "filter": [
            {
              "whereCondition": _whereCondition,
              "aggregateKey": aggrKey
            }
          ],
          "placeholder": this.formFilter.value.filterPlaceholder
        }
      };
      if (this.isEditFilter) {
        this.sortedColumnFilters[this.filterArrayindex] = jsonObj;
        this.toastr.success("Filter updated successfully ", "Success");
        this.isEditFilter = false;
      }
      else {
        this.sortedColumnFilters = this.sortedColumnFilters || [];
        this.sortedColumnFilters.push(jsonObj);
        this.toastr.success("Filter added successfully ", "Success");
      }

    } else if (this.formFilter.value.filterType == 'DropDown') {

      let _ddlQuery = {};
      var ddlQuery = this.formFilter.value.queryField;
      if (ddlQuery !== "" && ddlQuery !== undefined && ddlQuery !== null) { _ddlQuery = JSON.parse(ddlQuery) }
      else { _ddlQuery = {} }

      var dropCount = 0;
      this.dropdowncount = this.sortedColumnFilters.filter(x => x.widget.type == "DropDown" || x.widget.type == "StaticDropdown").length;
      if (this.isEditFilter) {
        try { dropCount = this.sortedColumnFilters[this.filterArrayindex].widget.dropdownNo; }
        catch { dropCount = 0; }
      } else { dropCount = 0; }

      if (this.bindingType == "static") {

        jsonObj = {
          "colxs": this.formFilter.value.filtercolxs,
          "colmd": this.formFilter.value.filtercolmd,
          "collg": this.formFilter.value.filtercollg,
          "widget": {
            "type": "StaticDropdown",
            "id": this.uniqueID,
            "dataType": this.dataTypeFilter,
            "bgColor": this.backColorModel,
            "label": this.formFilter.value.filterTitle,
            "description": this.formFilter.value.filterDesc,
            "valueField": this.selectedFilterFields,
            "data": this.txtFinalItemList,
            "filter": [
              {
                "whereCondition": _whereCondition,
                "aggregateKey": ""
              }
            ],
            "placeholder": this.formFilter.value.filterPlaceholder,
            "isDependant": this.dependantField,
            "parent": this.pField,
            "dropdownNo": dropCount
          }
        };

        console.log("jsonObj Static dropdown", jsonObj);

      }
      else {

        debugger;
        this.selectedOrderString1 = "";

        if (this.orderFieldList1 != null) {
          for (var i in this.orderFieldList1) {
            if (this.orderFieldList1[i].order == "Descending") {
              if (this.selectedOrderString1.length > 0) { this.selectedOrderString1 = this.selectedOrderString1 + ",-" + this.orderFieldList1[i].field; }
              else { this.selectedOrderString1 = "-" + this.orderFieldList1[i].field; }
            } else {
              if (this.selectedOrderString1.length > 0) { this.selectedOrderString1 = this.selectedOrderString1 + "," + this.orderFieldList1[i].field; }
              else { this.selectedOrderString1 = this.orderFieldList1[i].field; }
            }
          }
        }

        this.selectedOrderString1 = this.selectedOrderString1.replace(/,\s*$/, "");

        jsonObj = {
          "colxs": this.formFilter.value.filtercolxs,
          "colmd": this.formFilter.value.filtercolmd,
          "collg": this.formFilter.value.filtercollg,
          "widget": {
            "type": "DropDown",
            "id": this.uniqueID,
            "dataType": this.dataTypeFilter,
            "bgColor": this.backColorModel,
            "label": this.formFilter.value.filterTitle,
            "description": this.formFilter.value.filterDesc,
            "displayKey": this.selectedFilterField,
            "valueKey": this.selectedFilterValueField,
            "valueField": this.selectedFilterFields,
            "data": [
              {
                "class": this.formFilter.value.filterClass,
                "whereCondition": _ddlQuery,
                "order": this.selectedOrderString1,
                "keys": [
                  this.selectedFilterField
                ]
              }
            ],
            "filter": [
              {
                "whereCondition": _whereCondition,
                "aggregateKey": ""
              }
            ],
            "placeholder": this.formFilter.value.filterPlaceholder,
            "isDependant": this.dependantField,
            "parent": this.pField,
            "dropdownNo": dropCount
          }
        };
      }
      if (this.isEditFilter) {
        this.sortedColumnFilters[this.filterArrayindex] = jsonObj;
        this.toastr.success("Filter updated successfully ", "Success");
        this.isEditFilter = false;
      }
      else {
        this.sortedColumnFilters = this.sortedColumnFilters || [];
        this.sortedColumnFilters.push(jsonObj);
        this.toastr.success("Filter added successfully ", "Success");
      }

    } else if (this.formFilter.value.filterType == 'FromDate') {

      jsonObj = {
        "colxs": this.formFilter.value.filtercolxs,
        "colmd": this.formFilter.value.filtercolmd,
        "collg": this.formFilter.value.filtercollg,
        "widget": {
          "type": "FromDate",
          "id": this.uniqueID,
          "bgColor": this.backColorModel,
          "label": this.formFilter.value.filterTitle,
          "description": this.formFilter.value.filterDesc,
          "defaultValue": this.selectedFilterDateDefault,
          "valueKey": this.selectedFilterFields,
          "filter": [
            {
              "query": this.selectedFilterField

            }
          ]
        }
      };
      if (this.isEditFilter) {
        this.sortedColumnFilters[this.filterArrayindex] = jsonObj;
        this.toastr.success("Filter updated successfully ", "Success");
        this.isEditFilter = false;
      }
      else {
        this.sortedColumnFilters = this.sortedColumnFilters || [];
        this.sortedColumnFilters.push(jsonObj);
        this.toastr.success("Filter added successfully ", "Success");
      }

    } else if (this.formFilter.value.filterType == 'ToDate') {

      jsonObj = {
        "colxs": this.formFilter.value.filtercolxs,
        "colmd": this.formFilter.value.filtercolmd,
        "collg": this.formFilter.value.filtercollg,
        "widget": {
          "type": "ToDate",
          "id": this.uniqueID,
          "bgColor": this.backColorModel,
          "label": this.formFilter.value.filterTitle,
          "description": this.formFilter.value.filterDesc,
          "defaultValue": this.selectedFilterDateDefault,
          "valueKey": this.selectedFilterFields,
          "filter": [
            {
              "query": this.selectedFilterField,
              "aggregateKey": ""
            }
          ]
        }
      };
      if (this.isEditFilter) {
        this.sortedColumnFilters[this.filterArrayindex] = jsonObj;
        this.toastr.success("Filter updated successfully ", "Success");
        this.isEditFilter = false;
      }
      else {
        this.sortedColumnFilters = this.sortedColumnFilters || [];
        this.sortedColumnFilters.push(jsonObj);
        this.toastr.success("Filter added successfully ", "Success");
      }

    } else if (this.formFilter.value.filterType == 'AdvancedFromToDate') {

      jsonObj = {
        "colxs": this.formFilter.value.filtercolxs,
        "colmd": this.formFilter.value.filtercolmd,
        "collg": this.formFilter.value.filtercollg,
        "widget": {
          "type": "AdvancedFromToDate",
          "id": this.uniqueID,
          "bgColor": this.backColorModel,
          "label": this.formFilter.value.filterTitle,
          "columnName": this.selectedFilterFields,
          "description": this.formFilter.value.filterDesc,
          "aggregateKey": this.formFilter.value.filtAggKey,
          "fromDate": {
            "label": this.fLabel,
            "showTimeSelect": this.fselectedShowTimeSelect,
            "showTimeSelectOnly": this.fselectedOnlyTimeSelect,
            "timeOnlyFormat": this.txtfTimeFormat,
            "timeIntervals": this.txtfTimeInterval,
            "minDate": this.txtfMinDate,
            "maxDate": this.txtfMaxDate,
            "timeCaption": this.txtfTimeCaption,
            "dateFormat": this.txtfDateFormat,
            "placeHolder": this.fPlaceholder,
            "helpbody": this.txtfHelpBody,
            "helplink": this.txtfHelpLink
          },
          "toDate": {
            "label": this.tLabel,
            "showTimeSelect": this.tselectedShowTimeSelect,
            "showTimeSelectOnly": this.tselectedOnlyTimeSelect,
            "timeOnlyFormat": this.txttTimeFormat,
            "timeIntervals": this.txttTimeInterval,
            "minDate": this.txttMinDate,
            "maxDate": this.txttMaxDate,
            "timeCaption": this.txttTimeCaption,
            "placeHolder": this.tPlaceholder,
            "dateFormat": this.txttDateFormat,
            "helpbody": this.txttHelpBody,
            "helplink": this.txttHelpLink
          }
        }
      };
      if (this.isEditFilter) {
        this.sortedColumnFilters[this.filterArrayindex] = jsonObj;
        this.toastr.success("Filter updated successfully ", "Success");
        this.isEditFilter = false;
      }
      else {
        this.sortedColumnFilters = this.sortedColumnFilters || [];
        this.sortedColumnFilters.push(jsonObj);
        this.toastr.success("Filter added successfully ", "Success");
      }

    } else if (this.formFilter.value.filterType == 'Radio') {

      let _radioQuery = {};
      var radioQuery = this.formFilter.value.queryField;
      if (radioQuery !== "" && radioQuery !== undefined && radioQuery !== null) { _radioQuery = JSON.parse(radioQuery) }
      else { _radioQuery = {} }

      var dropCount = 0;
      this.dropdowncount = this.sortedColumnFilters.filter(x => x.widget.type == "Radio" || x.widget.type == "StaticRadio").length;
      if (this.isEditFilter) {
        try { dropCount = this.sortedColumnFilters[this.filterArrayindex].widget.dropdownNo; }
        catch { dropCount = 0; }
      } else { dropCount = 0; }

      if (this.bindingType == "static") {

        jsonObj = {
          "colxs": this.formFilter.value.filtercolxs,
          "colmd": this.formFilter.value.filtercolmd,
          "collg": this.formFilter.value.filtercollg,
          "widget": {
            "type": "StaticRadio",
            "id": this.uniqueID,
            "dataType": this.dataTypeFilter,
            "bgColor": this.backColorModel,
            "label": this.formFilter.value.filterTitle,
            "description": this.formFilter.value.filterDesc,
            "valueField": this.selectedFilterFields,
            "data": this.txtFinalItemList,
            "filter": [
              {
                "whereCondition": _whereCondition,
                "aggregateKey": ""
              }
            ],
            "placeholder": this.formFilter.value.filterPlaceholder,
            "isDependant": this.dependantField,
            "parent": this.pField,
            "dropdownNo": dropCount
          }
        };

        console.log("jsonObj Static dropdown", jsonObj);
      }
      else {

        debugger;
        this.selectedOrderString1 = "";

        if (this.orderFieldList1 != null) {
          for (var i in this.orderFieldList1) {
            if (this.orderFieldList1[i].order == "Descending") {
              if (this.selectedOrderString1.length > 0) { this.selectedOrderString1 = this.selectedOrderString1 + ",-" + this.orderFieldList1[i].field; }
              else { this.selectedOrderString1 = "-" + this.orderFieldList1[i].field; }
            } else {
              if (this.selectedOrderString1.length > 0) { this.selectedOrderString1 = this.selectedOrderString1 + "," + this.orderFieldList1[i].field; }
              else { this.selectedOrderString1 = this.orderFieldList1[i].field; }
            }
          }
        }
        this.selectedOrderString1 = this.selectedOrderString1.replace(/,\s*$/, "");

        jsonObj = {
          "colxs": this.formFilter.value.filtercolxs,
          "colmd": this.formFilter.value.filtercolmd,
          "collg": this.formFilter.value.filtercollg,
          "widget": {
            "type": "Radio",
            "id": this.uniqueID,
            "dataType": this.dataTypeFilter,
            "bgColor": this.backColorModel,
            "label": this.formFilter.value.filterTitle,
            "description": this.formFilter.value.filterDesc,
            "displayKey": this.selectedFilterField,
            "valueKey": this.selectedFilterValueField,
            "valueField": this.selectedFilterFields,
            "data": [
              {
                "class": this.formFilter.value.filterClass,
                "whereCondition": _radioQuery,
                "order": this.selectedOrderString1,
                "keys": [
                  this.selectedFilterField
                ]
              }
            ],
            "filter": [
              {
                "whereCondition": _whereCondition,
                "aggregateKey": ""
              }
            ],
            "placeholder": this.formFilter.value.filterPlaceholder,
            "isDependant": this.dependantField,
            "parent": this.pField,
            "dropdownNo": dropCount
          }
        };
      }
      if (this.isEditFilter) {
        this.sortedColumnFilters[this.filterArrayindex] = jsonObj;
        this.toastr.success("Filter updated successfully ", "Success");
        this.isEditFilter = false;
      }
      else {
        this.sortedColumnFilters = this.sortedColumnFilters || [];
        this.sortedColumnFilters.push(jsonObj);
        this.toastr.success("Filter added successfully ", "Success");
      }

    } else if (this.formFilter.value.filterType == 'CheckBox') {

      let _chkQuery = {};
      var chkQuery = this.formFilter.value.queryField;
      if (chkQuery !== "" && chkQuery !== undefined && chkQuery !== null) { _chkQuery = JSON.parse(chkQuery) }
      else { _chkQuery = {} }

      var dropCount = 0;
      this.dropdowncount = this.sortedColumnFilters.filter(x => x.widget.type == "CheckBox" || x.widget.type == "StaticCheckBox").length;
      if (this.isEditFilter) {
        try { dropCount = this.sortedColumnFilters[this.filterArrayindex].widget.dropdownNo; }
        catch { dropCount = 0; }
      } else { dropCount = 0; }

      if (this.bindingType == "static") {

        jsonObj = {
          "colxs": this.formFilter.value.filtercolxs,
          "colmd": this.formFilter.value.filtercolmd,
          "collg": this.formFilter.value.filtercollg,
          "widget": {
            "type": "StaticCheckBox",
            "id": this.uniqueID,
            "dataType": this.dataTypeFilter,
            "bgColor": this.backColorModel,
            "label": this.formFilter.value.filterTitle,
            "description": this.formFilter.value.filterDesc,
            "valueField": this.selectedFilterFields,
            "data": this.txtFinalItemList,
            "filter": [
              {
                "whereCondition": _whereCondition,
                "aggregateKey": ""
              }
            ],
            "placeholder": this.formFilter.value.filterPlaceholder,
            "isDependant": this.dependantField,
            "parent": this.pField,
            "dropdownNo": dropCount
          }
        };

        console.log("jsonObj Static checkbox", jsonObj);

      }
      else {

        debugger;
        this.selectedOrderString1 = "";

        if (this.orderFieldList1 != null) {
          for (var i in this.orderFieldList1) {
            if (this.orderFieldList1[i].order == "Descending") {
              if (this.selectedOrderString1.length > 0) {
                this.selectedOrderString1 = this.selectedOrderString1 + ",-" + this.orderFieldList1[i].field;
              } else {
                this.selectedOrderString1 = "-" + this.orderFieldList1[i].field;
              }
            } else {
              if (this.selectedOrderString1.length > 0) {
                this.selectedOrderString1 = this.selectedOrderString1 + "," + this.orderFieldList1[i].field;
              } else {
                this.selectedOrderString1 = this.orderFieldList1[i].field;
              }
            }
          }
        }
        this.selectedOrderString1 = this.selectedOrderString1.replace(/,\s*$/, "");

        jsonObj = {
          "colxs": this.formFilter.value.filtercolxs,
          "colmd": this.formFilter.value.filtercolmd,
          "collg": this.formFilter.value.filtercollg,
          "widget": {
            "type": "CheckBox",
            "id": this.uniqueID,
            "dataType": this.dataTypeFilter,
            "bgColor": this.backColorModel,
            "label": this.formFilter.value.filterTitle,
            "description": this.formFilter.value.filterDesc,
            "displayKey": this.selectedFilterField,
            "valueKey": this.selectedFilterValueField,
            "valueField": this.selectedFilterFields,
            "data": [
              {
                "class": this.formFilter.value.filterClass,
                "whereCondition": _chkQuery,
                "order": this.selectedOrderString1,
                "keys": [
                  this.selectedFilterField
                ]
              }
            ],
            "filter": [
              {
                "whereCondition": _whereCondition,
                "aggregateKey": ""
              }
            ],
            "placeholder": this.formFilter.value.filterPlaceholder,
            "isDependant": this.dependantField,
            "parent": this.pField,
            "dropdownNo": dropCount
          }
        };
      }
      if (this.isEditFilter) {
        this.sortedColumnFilters[this.filterArrayindex] = jsonObj;
        this.toastr.success("Filter updated successfully ", "Success");
        this.isEditFilter = false;
      }
      else {
        this.sortedColumnFilters = this.sortedColumnFilters || [];
        this.sortedColumnFilters.push(jsonObj);
        this.toastr.success("Filter added successfully ", "Success");
      }
    }
    localStorage.setItem("filterRows", JSON.stringify(this.sortedColumnFilters));
    console.log(" this.sortedColumnFilters", this.sortedColumnFilters);
    this.selectedOrder1 = this.selectedOrderField1 = this.selectedOrderString1 = this.orderErrorMsg1 = "";
    this.orderFieldList1 = []; this.isOrderError1 = false; this.dependantField = false; this.pField = "";
    this.txtFinalItemList = [];
    this.modalRef.hide();

  }

  getRandomNum(controlName: string) {

    var a = Math.floor(100000 + Math.random() * 900000);
    var stringA = String(a);
    stringA = stringA.substring(0, 4);
    return controlName + stringA;

  }

  async loadClasses(appname: string) {

    debugger
    this.classList = [];
    try {
      var listParam = { "appname": appname }

      let result: any;
      result = await this.rec.getClassList(listParam);
      this.classList = result["result"];
      if (this.classList == undefined || this.classList.length == 0) { this.classList = []; }
      else { }
    } catch { this.classList = []; }

  }

  async getApps() {

    this.appList = []; let result: any;
    var orgParam = { "orgId": localStorage.getItem("orgId") }

    result = await this.res.getAppList(orgParam);
    this.appList = result.result.results;
    this.appList.sort((a, b) => a.appname > b.appname ? 1 : -1);
    if (this.appList == undefined) { this.appList = []; }

  }

  async selectAppHandler(event: any) {

    this.selectedApp = event.target.value;
    await this.loadClasses(event.target.value);

  }

  async editFilter(jlist: any[]) {

    debugger
    this.isEditFilter = true; this.receivedChildMessage = "";
    this.txtFinalItemList = this.orderFieldList1 = [];
    this.isItemError = false;
    this.itemErrorMsg = ""; this.txtItem = ""; this.txtItemName = "";
    console.log("jlist", jlist);
    console.log("type", jlist["widget"].type);

    this.formFilter.patchValue({ 'filtercolxs': jlist["colxs"] });
    this.formFilter.patchValue({ 'filtercolmd': jlist["colmd"] });
    this.formFilter.patchValue({ 'filtercollg': jlist["collg"] });
    this.formFilter.patchValue({ 'filterBgColor': jlist["widget"].bgColor });
    this.backColorModel = jlist["widget"].bgColor;
    this.formFilter.patchValue({ 'filterTitle': jlist["widget"].label });
    this.formFilter.patchValue({ 'filterDesc': jlist["widget"].description });
    this.formFilter.patchValue({ 'filterPlaceholder': jlist["widget"].placeholder });

    if (jlist["widget"].type == "TextBox") {

      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueKey });
      this.selectedFilterFields = jlist["widget"].valueKey;
      this.getDropDownUsingFilterType(jlist["widget"].dataType);
      this.selectedFilterType = "TextBox";
      this.dataTypeFilter = jlist["widget"].dataType;
      this.formFilter.patchValue({ 'filterType': jlist["widget"].type });
      console.log(" this.selectedFilterFields", this.selectedFilterFields);
      console.log("FilterWhereCondition", jlist["widget"].filter[0].whereCondition);
      debugger;
      if (Object.keys(jlist["widget"].filter[0].whereCondition).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].whereCondition });
        var i1 = jlist["widget"].filter[0].whereCondition;
        this.receivedChildMessage = JSON.stringify(i1);
      }

      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].type === jlist["widget"].type);

    } else if (jlist["widget"].type == "DropDown") {

      this.selectedFilterField = ''; this.selectedFilterValueField = '';
      this.bindingType = "dynamic";

      await this.selectTypeHandler();
      await this.loadFieldBindingFilter(jlist["widget"].data[0].class);
      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueField });
      this.selectedFilterFields = jlist["widget"].valueField;
      this.getDropDownUsingFilterType(jlist["widget"].dataType);
      this.dataTypeFilter = jlist["widget"].dataType;
      this.selectedFilterType = jlist["widget"].type;
      this.selectedFilterClass = jlist["widget"].data[0].class;
      this.selectedFilterField = jlist["widget"].displayKey;
      this.selectedFilterValueField = jlist["widget"].valueKey;
      this.formFilter.patchValue({ 'filterDDLField': jlist["widget"].displayKey });
      this.formFilter.patchValue({ 'vDDLfield': jlist["widget"].valueKey });

      try {
        this.selectedOrderString1 = jlist["widget"].data[0].order;
        if (this.selectedOrderString1.length > 0) {
          var splitString = this.selectedOrderString1.split(',');
          for (var i in splitString) {
            if (splitString[i].indexOf('-') != -1) { this.orderFieldList1.push({ "order": "Descending", "field": splitString[i].replace('-', "") }); }
            else { this.orderFieldList1.push({ "order": "Ascending", "field": splitString[i] }); }
          }
        } else { this.orderFieldList1 = []; }
      } catch { this.selectedOrderString1 = ""; }

      if (Object.keys(jlist["widget"].data[0].whereCondition.length > 0)) {
        this.formFilter.patchValue({ 'queryField': JSON.stringify(jlist["widget"].data[0].whereCondition) });
      }

      if (Object.keys(jlist["widget"].filter[0].whereCondition).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].whereCondition });
        var i1 = jlist["widget"].filter[0].whereCondition;
        this.receivedChildMessage = JSON.stringify(i1);
      }

      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);
      this.checkForDependantList();
      this.dependantField = jlist["widget"].isDependant;
      this.bindpList();
      this.pField = jlist["widget"].parent;

    } else if (jlist["widget"].type == "StaticDropdown") {

      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueField });
      this.selectedFilterFields = jlist["widget"].valueField;
      this.getDropDownUsingFilterType(jlist["widget"].dataType);
      this.selectedFilterType = "DropDown";
      this.bindingType = "static";

      if (Object.keys(jlist["widget"].filter[0].whereCondition).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].whereCondition });
        var i1 = jlist["widget"].filter[0].whereCondition;
        this.receivedChildMessage = JSON.stringify(i1);
      }
      this.txtFinalItemList = jlist["widget"].data;

      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);
      this.checkForDependantList();
      this.dependantField = jlist["widget"].isDependant;
      this.bindpList();
      this.pField = jlist["widget"].parent;

    } else if (jlist["widget"].type == "FromDate") {

      this.dataTypeFilter = 'Date';
      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueKey });
      this.selectedFilterType = 'FromDate';
      this.getDropDownUsingFilterType('Date');
      this.selectedFilterDateDefault = jlist["widget"].defaultValue;
      this.selectedFilterFields = jlist["widget"].valueKey;

      if (Object.keys(jlist["widget"].filter[0].query).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].query });
        var i1 = jlist["widget"].filter[0].query;
        this.receivedChildMessage = JSON.stringify(i1);
      }
      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);

    } else if (jlist["widget"].type == "ToDate") {

      this.dataTypeFilter = 'Date';
      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueKey });
      this.selectedFilterType = 'ToDate';
      this.getDropDownUsingFilterType('Date');
      this.selectedFilterDateDefault = jlist["widget"].defaultValue;
      this.formFilter.patchValue({ 'filterType': 'ToDate' });
      this.selectedFilterFields = jlist["widget"].valueKey;

      if (Object.keys(jlist["widget"].filter[0].query).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].query });
        var i1 = jlist["widget"].filter[0].query;
        this.receivedChildMessage = JSON.stringify(i1);
      }
      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);

    } else if (jlist["widget"].type == "AdvancedFromToDate") {

      this.dataTypeFilter = 'Date';
      this.selectedFilterType = 'AdvancedFromToDate';
      this.getDropDownUsingFilterType('Date');
      this.selectedFilterFields = jlist["widget"].columnName;
      this.fLabel = jlist["widget"].fromDate.label;
      this.fselectedShowTimeSelect = jlist["widget"].fromDate.showTimeSelect;
      this.fselectedOnlyTimeSelect = jlist["widget"].fromDate.showTimeSelectOnly;
      this.txtfTimeFormat = jlist["widget"].fromDate.timeOnlyFormat;
      this.txtfTimeInterval = jlist["widget"].fromDate.timeIntervals;
      this.txtfMinDate = jlist["widget"].fromDate.minDate;
      this.txtfMaxDate = jlist["widget"].fromDate.maxDate;
      this.txtfTimeCaption = jlist["widget"].fromDate.timeCaption;
      this.txtfDateFormat = jlist["widget"].fromDate.dateFormat;
      this.fPlaceholder = jlist["widget"].fromDate.placeHolder;
      this.txtfHelpBody = jlist["widget"].fromDate.helpbody;
      this.txtfHelpLink = jlist["widget"].fromDate.helplink;

      this.tLabel = jlist["widget"].toDate.label;
      this.tselectedShowTimeSelect = jlist["widget"].toDate.showTimeSelect;
      this.tselectedOnlyTimeSelect = jlist["widget"].toDate.showTimeSelectOnly;
      this.txttTimeFormat = jlist["widget"].toDate.timeOnlyFormat;
      this.txttTimeInterval = jlist["widget"].toDate.timeIntervals;
      this.txttMinDate = jlist["widget"].toDate.minDate;
      this.txttMaxDate = jlist["widget"].toDate.maxDate;
      this.txttTimeCaption = jlist["widget"].toDate.timeCaption;
      this.tPlaceholder = jlist["widget"].toDate.placeHolder;
      this.txttDateFormat = jlist["widget"].toDate.dateFormat;
      this.txttHelpBody = jlist["widget"].toDate.helpbody;
      this.txttHelpLink = jlist["widget"].toDate.helplink;

      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);

    } else if (jlist["widget"].type == "Radio") {

      this.selectedFilterField = ''; this.selectedFilterValueField = '';
      this.bindingType = "dynamic";
      await this.selectTypeHandler();
      await this.loadFieldBindingFilter(jlist["widget"].data[0].class);
      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueField });
      this.selectedFilterFields = jlist["widget"].valueField;
      this.getDropDownUsingFilterType(jlist["widget"].dataType);
      this.dataTypeFilter = jlist["widget"].dataType;
      this.selectedFilterType = jlist["widget"].type;
      this.selectedFilterClass = jlist["widget"].data[0].class;
      this.selectedFilterField = jlist["widget"].displayKey;
      this.selectedFilterValueField = jlist["widget"].valueKey;
      this.formFilter.patchValue({ 'filterDDLField': jlist["widget"].displayKey });
      this.formFilter.patchValue({ 'vDDLfield': jlist["widget"].valueKey });

      try {
        this.selectedOrderString1 = jlist["widget"].data[0].order;
        if (this.selectedOrderString1.length > 0) {
          var splitString = this.selectedOrderString1.split(',');
          for (var i in splitString) {
            if (splitString[i].indexOf('-') != -1) { this.orderFieldList1.push({ "order": "Descending", "field": splitString[i].replace('-', "") }); }
            else { this.orderFieldList1.push({ "order": "Ascending", "field": splitString[i] }); }
          }
        } else { this.orderFieldList1 = []; }
      } catch { this.selectedOrderString1 = ""; }

      if (Object.keys(jlist["widget"].data[0].whereCondition.length > 0)) {
        this.formFilter.patchValue({ 'queryField': JSON.stringify(jlist["widget"].data[0].whereCondition) });
      }

      if (Object.keys(jlist["widget"].filter[0].whereCondition).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].whereCondition });
        var i1 = jlist["widget"].filter[0].whereCondition;
        this.receivedChildMessage = JSON.stringify(i1);
      }

      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);
      this.dependantField = false;

    } else if (jlist["widget"].type == "StaticRadio") {

      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueField });
      this.selectedFilterFields = jlist["widget"].valueField;
      this.getDropDownUsingFilterType(jlist["widget"].dataType);
      this.selectedFilterType = "Radio";
      this.bindingType = "static";

      if (Object.keys(jlist["widget"].filter[0].whereCondition).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].whereCondition });
        var i1 = jlist["widget"].filter[0].whereCondition;
        this.receivedChildMessage = JSON.stringify(i1);
      }
      this.txtFinalItemList = jlist["widget"].data;

      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);
      this.dependantField = false;

    } else if (jlist["widget"].type == "CheckBox") {

      this.selectedFilterField = ''; this.selectedFilterValueField = '';
      this.bindingType = "dynamic";
      await this.selectTypeHandler();
      await this.loadFieldBindingFilter(jlist["widget"].data[0].class);
      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueField });
      this.selectedFilterFields = jlist["widget"].valueField;
      this.getDropDownUsingFilterType(jlist["widget"].dataType);
      this.dataTypeFilter = jlist["widget"].dataType;
      this.selectedFilterType = jlist["widget"].type;
      this.selectedFilterClass = jlist["widget"].data[0].class;
      this.selectedFilterField = jlist["widget"].displayKey;
      this.selectedFilterValueField = jlist["widget"].valueKey;
      this.formFilter.patchValue({ 'filterDDLField': jlist["widget"].displayKey });
      this.formFilter.patchValue({ 'vDDLfield': jlist["widget"].valueKey });

      try {
        this.selectedOrderString1 = jlist["widget"].data[0].order;
        if (this.selectedOrderString1.length > 0) {
          var splitString = this.selectedOrderString1.split(',');
          for (var i in splitString) {
            if (splitString[i].indexOf('-') != -1) { this.orderFieldList1.push({ "order": "Descending", "field": splitString[i].replace('-', "") }); }
            else { this.orderFieldList1.push({ "order": "Ascending", "field": splitString[i] }); }
          }
        } else { this.orderFieldList1 = []; }
      } catch { this.selectedOrderString1 = ""; }

      if (Object.keys(jlist["widget"].data[0].whereCondition.length > 0)) {
        this.formFilter.patchValue({ 'queryField': JSON.stringify(jlist["widget"].data[0].whereCondition) });
      }

      if (Object.keys(jlist["widget"].filter[0].whereCondition).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].whereCondition });
        var i1 = jlist["widget"].filter[0].whereCondition;
        this.receivedChildMessage = JSON.stringify(i1);
      }

      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);
      this.checkForChkDependantList();
      this.dependantField = jlist["widget"].isDependant;
      this.bindChkPList();
      this.pField = jlist["widget"].parent;

    } else if (jlist["widget"].type == "StaticCheckBox") {

      this.formFilter.patchValue({ 'filterFields': jlist["widget"].valueField });
      this.selectedFilterFields = jlist["widget"].valueField;
      this.getDropDownUsingFilterType(jlist["widget"].dataType);
      this.selectedFilterType = "CheckBox";
      this.bindingType = "static";

      if (Object.keys(jlist["widget"].filter[0].whereCondition).length > 0) {
        this.formFilter.patchValue({ 'filterWhereCondition': jlist["widget"].filter[0].whereCondition });
        var i1 = jlist["widget"].filter[0].whereCondition;
        this.receivedChildMessage = JSON.stringify(i1);
      }
      this.txtFinalItemList = jlist["widget"].data;

      this.filterArrayindex = this.sortedColumnFilters.findIndex(x => x["widget"].id === jlist["widget"].id);
      this.dependantField = false;

    }

    console.log(" this.formFilter", this.formFilter.value);
    console.log(" this.filterArrayindex", this.filterArrayindex);
    this.modalRef = this.modalService.show(this.modal3, { class: 'my-modal9' });

  }

  async loadFieldBinding(classNm: string) {

    try {
      if (classNm != undefined || classNm != null) {
        var className = classNm;
        var classParam = { "className": className }

        await this.rec.getSchemaByClass(classParam).then(result => {
          let ff1List = []; this.valueFieldList = []; this.fieldList = [];

          ff1List.push(result["result"].fields);

          //array of fields
          for (let key of ff1List) {
            //dyanamice key Item
            for (var i in key) {
              //push value of dynamic key

              if (i == 'objectId' || i == 'createdAt' || i == 'updatedAt' || i == 'ACL') {
                //don't push
              } else {
                this.fieldList.push({ "fd": i, "detail": key[i] });
                this.valueFieldList.push({ "fd": i, "detail": key[i] });
              }
            }
          }

        });
        console.log("this.fieldList", this.fieldList);
        console.log("this.valueFieldList", this.valueFieldList);
      }
    } catch { }

  }

  addItem() {

    var item = this.txtItemName + ":" + this.txtItem;
    if (this.txtItem == "" || this.txtItemName == "" || this.txtItem == "" || this.txtItemName == undefined) {
      this.isItemError = true;
      this.itemErrorMsg = "Item required!";
    } else if (this.txtFinalItemList.indexOf(item) !== -1) {
      this.isItemError = true;
      this.itemErrorMsg = "Item already exists!";
    } else {
      this.isItemError = false;
      this.itemErrorMsg = "";
      this.txtFinalItemList.push({ "name": this.txtItemName, "value": this.txtItem });
      this.itemList.push(this.txtItem);
      this.itemNameList.push(this.txtItemName);
      this.txtItem = "";
      this.txtItemName = "";

      console.log(" this.txtFinalItemList", this.txtFinalItemList);
    }

  }

  deleteItem(itemIndex: number) {

    this.txtFinalItemList.splice(itemIndex, 1);
    this.itemList.splice(itemIndex, 1);
    this.itemNameList.splice(itemIndex, 1);

  }

  checkForDependantList() {

    debugger;
    var dropCount = 0;
    console.log("sortedColumns", this.sortedColumnFilters);
    this.dropdowncount = this.sortedColumnFilters.filter(x => x.widget.type == "DropDown" || x.widget.type == "StaticDropdown").length;

    if (this.isEditFilter) {
      try { dropCount = this.sortedColumnFilters[this.filterArrayindex].widget.dropdownNo; }
      catch { }
    } else { dropCount = this.dropdowncount; }

    if (dropCount > 0 || dropCount != undefined) { this.dependantShow = true; }
    else { this.dependantShow = false; }

  }

  onTypeSelected(event: any) {

    var typeSelected = event.target.value;
    this.selectedFilterType = typeSelected;
    this.bindingType = "static";
    // if (typeSelected == "divMulti") {
    //   //$(".divMulti").show();
    // }
    // else {
    //   //$(".divMulti").hide();
    // }

  }

  addOrderList1() {

    if (this.selectedOrder1 == "" || this.selectedOrderField1 == "") {
      this.isOrderError1 = true;
      this.orderErrorMsg1 = "Please select data properly!";
    } else {
      this.orderFieldList1.push({ "order": this.selectedOrder1, "field": this.selectedOrderField1 });
      console.log("OrderList", this.orderFieldList1);
      this.isOrderError1 = false;
      this.orderErrorMsg1 = this.selectedOrder1 = this.selectedOrderField1 = this.selectedOrderString1 = "";
    }

  }

  deleteFieldOrder1(oList: any[]) { this.orderFieldList1 = this.orderFieldList1.filter(item => item !== oList); }

  selectDependant(event: any) {

    this.dependantField = event;
    if (event == true) { this.bindpList(); }
    else { this.pField = null; }
  }

  bindpList() {

    var ddlArr = this.sortedColumnFilters.filter(x => x.widget.type == "DropDown" || x.widget.type == "StaticDropdown");
    if (ddlArr.length > 0) {
      this.pList = [];
      ddlArr.forEach(element => {
        if (this.selectedFilterFields != element.widget.valueField) { this.pList.push(element); }
      });
    }

  }

  checkForChkDependantList() {

    if (this.sortedColumnFilters.length > 0 && this.selectedFilterType == "CheckBox") {
      this.bindChkPList();
      this.chkDependantShow = true;
    } else { this.chkDependantShow = false; }

  }

  selectChkDependant(event: any) {

    this.chkDependField = event;
    if (event == true) {
      this.chkDependantCount++;
      this.bindChkPList();
      console.log("this.chkDependantCount", this.chkDependantCount)
    }
    else {
      this.chkDependantCount--;
      this.chkPField = null;
    }

  }

  bindChkPList() {

    if (this.sortedColumnFilters.length > 0) {
      this.chkPList = [];
      this.sortedColumnFilters.forEach(element => {
        if (element.widget.valueField != undefined) {
          if (this.selectedFilterFields != element.widget.valueField)
            this.chkPList.push({ "id": element.widget.id, "valueField": element.widget.valueField });
        } else if (element.widget.columnName != undefined) {
          if (this.selectedFilterFields != element.widget.columnName)
            this.chkPList.push({ "id": element.widget.id, "valueField": element.widget.columnName });
        } else {
          if (this.selectedFilterFields != element.widget.valueKey)
            this.chkPList.push({ "id": element.widget.id, "valueField": element.widget.valueKey });
        }
      });
    }

  }

  addDisplayPointer(displayPointerForm: NgForm) {

    this.spinnerService.show();
    debugger;
    var pointerVar = "";
    displayPointerForm["displayPointerSet"].forEach(element => {
      if (pointerVar.length > 0) {
        pointerVar = pointerVar + "." + element.displayPointer;
      } else { pointerVar = pointerVar + element.displayPointer; }
    });

    this.selectedFilterField = pointerVar;
    this.spinnerService.hide();

  }

  getDPointerList(classNm: string) {

    const select = this.dPointerList.filter(item => item.className == classNm);
    return select;

  }

  async selectDPointerHandler(event: any, index: any) {

    this.spinnerService.show();
    debugger;
    var selectedText = event.target['options'][event.target['options'].selectedIndex].text;
    var filterRes = this.dPointerList.filter(i => i.fd == selectedText);
    if (filterRes[0].detail.targetClass == "_User") {
      this.isDisplayOk = true;
      (<FormArray>this.displayPointerForm.get('displayPointerSet')).at(index).patchValue({ isShow: true });

      const control = <FormArray>this.displayPointerForm.controls['displayPointerSet'];
      if (control.length > 1) {
        for (let i = control.length - 1; i >= 0; i--) {
          //if (this.displayPointerForm.value.displayPointerSet[i].displayPointer.length == 0) { control.removeAt(i) }
          if (i > index) { control.removeAt(i); }
        }
      }

    } else if (filterRes[0].detail.type !== "Pointer") {
      this.isDisplayOk = true;
      (<FormArray>this.displayPointerForm.get('displayPointerSet')).at(index).patchValue({ isShow: true });

      const control = <FormArray>this.displayPointerForm.controls['displayPointerSet'];
      if (control.length > 1) {
        for (let i = control.length - 1; i >= 0; i--) {
          if (i != 0) { control.removeAt(i) }
        }
      }
    } else {
      //get nested pointer for user
      //first clear the array from selected index to the end.
      const control = <FormArray>this.displayPointerForm.controls['displayPointerSet'];
      console.log("control length", control.length);
      for (let i = control.length - 1; i >= 0; i--) {
        // for (let i = 0; i < control.length; i++) {
        //if (this.displayPointerForm.value.displayPointerSet[i].displayPointer.length == 0) {
        if (i > index) {
          // control.removeAt(i)
          (<FormArray>this.displayPointerForm.get('displayPointerSet')).removeAt(i);
        }
      }
      (<FormArray>this.displayPointerForm.get('displayPointerSet')).push(this.createDPointerFormGroup(filterRes[0].detail.targetClass));
      this.isDisplayOk = false;
    }
    this.spinnerService.hide();

  }

  createDPointerFormGroup(className: string): FormGroup {

    return this.formBuilder.group({
      classNm: [className],
      displayPointer: [''],
      isShow: [false]
    });

  }

  async loadDPointerList(classNm: string) {

    debugger;
    try {
      if (classNm != undefined || classNm != null) {
        var classParam = { "className": classNm }

        await this.rec.getSchemaByClass(classParam).then(async result => {
          let ff1List = [];
          if (result != undefined) { ff1List.push(result["result"].fields); }

          //array of fields
          for (let key of ff1List) {
            //dyanamice key Item
            for (var i in key) {
              //push value of dynamic key          
              if (key[i].type.toLowerCase() == "pointer") {

                var indexPtr = this.dPointerList.findIndex(obj => obj.className == classNm && obj.fd == i);
                console.log("indexPtr", indexPtr);
                if (indexPtr == -1) {
                  this.dPointerList.push({ "className": classNm, "fd": i, "detail": key[i] });
                  if (key[i].targetClass != "_User") {
                    await this.loadDPointerList(key[i].targetClass);
                  }
                }
              } else if (i == 'createdAt' || i == 'updatedAt' || i == 'ACL') {
                //don't push
              } else {
                if (this.selectedFilterClass == classNm) {
                  this.dPointerList.push({ "className": classNm, "fd": i, "detail": key[i] });
                }
              }
            }
          }

        });
      }

    } catch { }
    console.log("D Pointer list", this.dPointerList);
    return this.dPointerList;

  }

  async deleteDPointer() {

    this.spinnerService.show();
    this.selectedFilterField = "";
    this.dPointerList = [];
    await this.loadDPointerList(this.selectedFilterClass);

    const control = <FormArray>this.displayPointerForm.controls['displayPointerSet'];
    if (control.length > 1) {
      for (let i = control.length - 1; i >= 0; i--) {
        if (i != 0) {
          control.removeAt(i)
        }
      }
    }
    this.displayPointerForm.reset();
    (this.displayPointerForm.get('displayPointerSet') as FormArray).at(0).patchValue({
      classNm: this.selectedFilterClass,
      isShow: false
    });
    this.isDisplayOk = false;
    this.spinnerService.hide();

  }

  addValuePointer(valuePointerForm: NgForm) {

    this.spinnerService.show();
    debugger;
    var pointerVar = "";
    valuePointerForm["valuePointerSet"].forEach(element => {
      if (pointerVar.length > 0) {
        pointerVar = pointerVar + "." + element.valuePointer;
      } else { pointerVar = pointerVar + element.valuePointer; }
    });

    this.selectedFilterValueField = pointerVar;
    this.spinnerService.hide();

  }

  getVPointerList(classNm: string) {

    const select = this.vPointerList.filter(item => item.className == classNm);
    return select;

  }

  async selectVPointerHandler(event: any, index: any) {

    this.spinnerService.show();
    debugger;
    var selectedText = event.target['options'][event.target['options'].selectedIndex].text;
    var filterRes = this.vPointerList.filter(i => i.fd == selectedText);
    if (filterRes[0].detail.targetClass == "_User") {
      this.isValueOk = true;
      (<FormArray>this.valuePointerForm.get('valuePointerSet')).at(index).patchValue({ isShow: true });

      const control = <FormArray>this.valuePointerForm.controls['valuePointerSet'];
      if (control.length > 1) {
        for (let i = control.length - 1; i >= 0; i--) {
          if (this.valuePointerForm.value.valuePointerSet[i].valuePointer.length == 0) { control.removeAt(i) }
        }
      }

    } else if (filterRes[0].detail.type !== "Pointer") {
      this.isValueOk = true;
      (<FormArray>this.valuePointerForm.get('valuePointerSet')).at(index).patchValue({ isShow: true });

      const control = <FormArray>this.valuePointerForm.controls['valuePointerSet'];
      if (control.length > 1) {
        for (let i = control.length - 1; i >= 0; i--) {
          if (i != 0) { control.removeAt(i) }
        }
      }
    } else {
      //get nested pointer for user
      //first clear the array from selected index to the end.
      const control = <FormArray>this.valuePointerForm.controls['valuePointerSet'];
      console.log("control length", control.length);
      // for (let i = control.length; i > control.length - 1; i--) { control.removeAt(i) }
      for (let i = 0; i < control.length; i++) {
        if (this.valuePointerForm.value.valuePointerSet[i].valuePointer.length == 0) {
          control.removeAt(i)
        }
      }
      (<FormArray>this.valuePointerForm.get('valuePointerSet')).push(this.createVPointerFormGroup(filterRes[0].detail.targetClass));
      this.isValueOk = false;
    }
    this.spinnerService.hide();

  }

  createVPointerFormGroup(className: string): FormGroup {

    return this.formBuilder.group({
      classNm: [className],
      valuePointer: [''],
      isShow: [false]
    });

  }

  async loadVPointerList(classNm: string) {

    debugger;
    try {
      if (classNm != undefined || classNm != null) {
        var classParam = { "className": classNm }

        await this.rec.getSchemaByClass(classParam).then(async result => {
          let ff1List = [];
          if (result != undefined) { ff1List.push(result["result"].fields); }

          //array of fields
          for (let key of ff1List) {
            //dyanamice key Item
            for (var i in key) {
              //push value of dynamic key          
              if (key[i].type.toLowerCase() == "pointer") {

                var indexPtr = this.vPointerList.findIndex(obj => obj.className == classNm && obj.fd == i);
                console.log("indexPtr", indexPtr);
                if (indexPtr == -1) {
                  this.vPointerList.push({ "className": classNm, "fd": i, "detail": key[i] });
                  if (key[i].targetClass != "_User") {
                    await this.loadVPointerList(key[i].targetClass);
                  }
                }
              } else if (i == 'createdAt' || i == 'updatedAt' || i == 'ACL') {
                //don't push
              } else {
                if (this.selectedFilterClass == classNm) {
                  this.vPointerList.push({ "className": classNm, "fd": i, "detail": key[i] });
                }
              }
            }
          }

        });
      }

    } catch { }
    console.log("V Pointer list", this.vPointerList);
    return this.vPointerList;

  }

  async deleteVPointer() {

    this.spinnerService.show();
    this.selectedFilterValueField = "";
    this.vPointerList = [];
    await this.loadVPointerList(this.selectedFilterClass);

    const control = <FormArray>this.valuePointerForm.controls['valuePointerSet'];
    if (control.length > 1) {
      for (let i = control.length - 1; i >= 0; i--) {
        if (i != 0) {
          control.removeAt(i)
        }
      }
    }
    this.valuePointerForm.reset();
    (this.valuePointerForm.get('valuePointerSet') as FormArray).at(0).patchValue({
      classNm: this.selectedFilterClass,
      isShow: false
    });
    this.isValueOk = false;
    this.spinnerService.hide();

  }

}
