import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl, FormArray } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { AccessAppService } from 'src/app/shared/accessApp.service';
import * as Parse from 'parse';

@Component({
  selector: 'app-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.css']
})

export class EditOrganizationComponent implements OnInit {

  orgForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private ngxService: NgxUiLoaderService,
    private toastr: ToastrService, private accessAppService: AccessAppService) {

    localStorage.setItem("accessUrl", "#");
    localStorage.setItem("appdisabled", "true");
    this.accessAppService.accessUrl.next(String(localStorage.getItem('accessUrl')));
    this.accessAppService.isAccessBtnDisabled.next(true);

  }

  async ngOnInit() {

    $(function () {
      $(".f-div2").hide();
      $(".f-div1").show();
    });

    this.pageLoader();
    Parse.initialize(localStorage.getItem("parseKey"));
    (Parse as any).serverURL = localStorage.getItem("serverUrl");

    this.orgForm = this.formBuilder.group({
      orgName: [''],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.maxLength(6)]]
    });

    if (localStorage.getItem("orgId") != undefined) {

      const OrgInfoData = Parse.Object.extend("w_organization");
      const orgInfoData = new OrgInfoData();
      const query = new Parse.Query(orgInfoData);

      // Retrieve the object by id
      await query.get(localStorage.getItem("orgId"))
        .then((x) => {
          let res = x.toJSON();
          this.orgForm.patchValue({
            orgName: res.orgName,
            country: res.country,
            state: res.state,
            city: res.city,
            address: res.address,
            pincode: res.pincode
          });
        });
    }

  }

  pageLoader(): void {

    // start foreground spinner of the master loader with 'default' taskId
    this.ngxService.start();

    // Stop the foreground loading after 5s
    setTimeout(() => {
      // stop foreground spinner of the master loader with 'default' taskId
      this.ngxService.stop();
    }, 2000);

    // OR
    this.ngxService.startBackground('do-background-things');

    // Do something here...
    this.ngxService.stopBackground('do-background-things');

    // start foreground spinner of the loader "loader-01" with 'default' taskId
    this.ngxService.startLoader('loader-01');

    // Stop the foreground loading after 5s
    setTimeout(() => {
      // stop foreground spinner of the loader "loader-01" with 'default' taskId
      this.ngxService.stopLoader('loader-01');
    }, 2000);

  }

  async updateOrganization(orgForm: NgForm) {

    this.pageLoader();
    if (this.orgForm.valid) {
      const org = Parse.Object.extend("w_organization");
      const orgInfo = new org();
      const query = new Parse.Query(orgInfo);
      await query.get(localStorage.getItem("orgId"))
        .then(async (x) => {
          x.set("country", orgForm["country"]),
            x.set("state", orgForm["state"]),
            x.set("city", orgForm["city"]),
            x.set("address", orgForm["address"]),
            x.set("pincode", orgForm["pincode"])
          await x.save()
            .then((res: any) => {
              this.toastr.success('Organization details updated!', 'Success!!!');
              this.showDetails();
            });
        });
    } else {
      this.validateAllFormFields(this.orgForm);
      this.toastr.error("Please fill all mandatory fields!", "Error");
    }

  }

  editDetails() {

    $(function () {
      $(".f-div1").hide();
      $(".f-div2").show();
    });

  }

  showDetails() {

    $(function () {
      $(".f-div2").hide();
      $(".f-div1").show();
    });

  }

  validateAllFormFields(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) { control.markAsTouched({ onlySelf: true }); }
      else if (control instanceof FormGroup) { this.validateAllFormFields(control); }
      else if (control instanceof FormArray) {
        for (const control1 of control.controls) {
          if (control1 instanceof FormControl) {
            control1.markAsTouched({ onlySelf: true });
          }
          if (control1 instanceof FormGroup) { this.validateAllFormFields(control1); }
        }
      }
    });

  }

}
