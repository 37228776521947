<div class="XFGT">
  <div style="display: block;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12"><br /><br /><br /><br />
        <div class="info-box bg-b-LightGreen"><span class="info-box-icon push-bottom">
            <i class="fa" style="font-size: 24px; color: rgb(255, 255, 255);"></i></span>
          <div class="info-box-content"><span class="info-box-text" style="color: rgb(255, 255, 255);">
              Requests </span><span class="info-box-number" style="color: rgb(255, 255, 255);">68</span>
            <br><span>&nbsp;</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>