<div id="mySidebar" class="sidebar" *ngIf="isUserLoggedIn">
  <ul style="list-style-type: none; padding: 0px;" class="responsive-css-style">
    <li>
      <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">Super Admin
        <span style="margin-left: 44px;" id="only-for-firefox">&times;</span></a>
    </li>
    <li> <a href="javascript:void(0)" (click)="clickApp()">Web Apps</a></li>
    <li> <a href="javascript:void(0)" (click)="clickMApp()">Mobile Apps</a> </li>
    <li *ngIf="accessType=='platformAdmin'"> <a href="javascript:void(0)" (click)="clickSchema()">Validate Schema</a>
    </li>
    <li *ngIf="accessType=='superAdmin' || accessType=='platformAdmin'"><a href="javascript:void(0)"
        (click)="clickEditor()">App Editors</a> </li>
    <li *ngIf="accessType=='platformAdmin'"><a href="javascript:void(0)" (click)="clickOrg()">Organization Info</a></li>
    <li> <a href="javascript:void(0)" (click)="clickQApp()">Query Builder</a> </li>
    <li *ngIf="accessType=='superAdmin' || accessType=='platformAdmin'"><a href="javascipt:void(0)"
        (click)="clickPlans()">Plans</a></li>
  </ul>
</div>
<!-- /#sidebar-wrapper -->
<!-- Page Content -->
<app-top-menu></app-top-menu>
<div id="page-content-wrapper">
  <div class="container-fluid" style="min-height:480px;">
    <div class="row content " id="main" onclick="mainNav()">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-left">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<!-- /#page-content-wrapper -->
<!--Page Footer-->
<app-page-footer></app-page-footer>
<!--  End of Page Footer-->