import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class SetFooterService {

  constructor() {
    if (localStorage.getItem('appName') == null) { this.currentMessage(); }
  }

  private messageSource = new Subject<any>();

  currentMessage(): Observable<any> { return this.messageSource.asObservable(); }

  changeMessage(message: string) {
    if (localStorage.getItem('appName') == null) { this.messageSource.next(message) }
    else { this.messageSource.next(localStorage.getItem('appName')) }
  }
}
