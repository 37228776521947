<div class="wrapperf">
  <form action="" id="wizard" [formGroup]="orgForm" (ngSubmit)="createOrg(orgForm.value)" class="wizard">
    <section>
      <div class="inner1">
        <div class="main_head">
          <div class="main-logo">
            <img src="../assets/img/mainlogin_logo.svg" width="100%" />
          </div>
        </div>
        <div class="inner">
          <div class="image-holder">
            <img src="../assets/img/login.svg" alt="">
          </div>
          <div class="form-content">
            <div class="form-header">
              <h3>Registration</h3>
            </div>
            <p>Please fill with your details</p>
            <div class="form-row">
              <div class="form-holder">
                <input type="text" class="form-control" formControlName="orgName" placeholder="Organization Name*" />
                <div
                  *ngIf="orgForm.controls['orgName'].invalid && (orgForm.controls['orgName'].dirty || orgForm.controls['orgName'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['orgName'].errors?.required"> Organization Name is required. </div>
                  <div *ngIf="orgForm.controls['orgName'].errors?.pattern"> Valid characters: AlphaNumerics, Spaces and
                    (Special Characters allowed: Point(.) & hyphen (-))!
                  </div>
                  <div *ngIf="orgForm.controls['orgName'].errors?.maxlength"> 100 characters are allowed! </div>
                </div>
              </div>
              <div class="form-holder">
                <input type="text" class="form-control" formControlName="contactName"
                  placeholder="Contact Person Name*" />
                <div
                  *ngIf="orgForm.controls['contactName'].invalid && (orgForm.controls['contactName'].dirty || orgForm.controls['contactName'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['contactName'].errors?.required"> Contact Person Name is required. </div>
                  <div *ngIf="orgForm.controls['contactName'].errors?.pattern"> Valid characters: Alphabets and Spaces!
                  </div>
                  <div *ngIf="orgForm.controls['contactName'].errors?.maxlength"> 100 characters are allowed! </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder">
                <input type="email" class="form-control" formControlName="emailId" placeholder="Email ID*" />
                <div
                  *ngIf="orgForm.controls['emailId'].invalid && (orgForm.controls['emailId'].dirty || orgForm.controls['emailId'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['emailId'].errors?.required"> Email ID is required. </div>
                  <div *ngIf="orgForm.controls['emailId'].errors?.email"> Invalid Email ID! </div>
                  <div *ngIf="orgForm.controls['emailId'].errors?.maxlength"> 50 characters are allowed! </div>
                </div>
              </div>
              <div class="form-holder">
                <input type="number" class="form-control" formControlName="ContactNo" placeholder="Contact No.*" />
                <div
                  *ngIf="orgForm.controls['ContactNo'].invalid && (orgForm.controls['ContactNo'].dirty || orgForm.controls['ContactNo'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['ContactNo'].errors?.required"> Contact No. is required. </div>
                  <div *ngIf="orgForm.controls['ContactNo'].errors?.pattern"> Invalid Contact No.! </div>
                  <div *ngIf="orgForm.controls['ContactNo'].errors?.maxlength"> Invalid Contact No. ! </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder">
                <input type="password" class="form-control" formControlName="password" placeholder="Password*" />
                <div
                  *ngIf="orgForm.controls['password'].invalid && (orgForm.controls['password'].dirty || orgForm.controls['password'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['password'].errors?.required"> Password is required. </div>
                  <div *ngIf="orgForm.controls['password'].errors?.pattern"> Password length must be between 6 to 15
                    characters (Special Characters allowed: _ @ & #)! </div>
                </div>
              </div>
              <div class="form-holder">
                <input type="password" class="form-control" formControlName="confirmPass"
                  placeholder="Confirm Password*" />
                <div class='error' *ngIf="orgForm.controls.confirmPass.touched">
                  <div class="error_msg errormessageadduser" *ngIf="orgForm.hasError('mismatchedPasswords')">
                    Passwords do not match
                  </div>
                </div>
                <div
                  *ngIf="orgForm.controls['confirmPass'].invalid && (orgForm.controls['confirmPass'].dirty || orgForm.controls['confirmPass'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['confirmPass'].errors?.required"> Confirm Password is required. </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder w-100">
                <textarea class="form-control" cols="1" rows="4" placeholder="Address*"
                  formControlName="address"></textarea>
                <div
                  *ngIf="orgForm.controls['address'].invalid && (orgForm.controls['address'].dirty || orgForm.controls['address'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['address'].errors?.required"> Address is required. </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder">
                <input type="number" class="form-control" formControlName="pincode" placeholder="Pincode*" />
                <div
                  *ngIf="orgForm.controls['pincode'].invalid && (orgForm.controls['pincode'].dirty || orgForm.controls['pincode'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['pincode'].errors?.required"> Pincode is required. </div>
                  <div *ngIf="orgForm.controls['pincode'].errors?.maxlength"> Invalid Pincode! </div>
                </div>
              </div>
              <div class="form-holder">
                <input type="text" class="form-control" formControlName="country" placeholder="Country" />
                <div
                  *ngIf="orgForm.controls['country'].invalid && (orgForm.controls['country'].dirty || orgForm.controls['country'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['country'].errors?.required"> Country is required. </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder">
                <input type="text" class="form-control" formControlName="state" placeholder="State" />
                <div
                  *ngIf="orgForm.controls['state'].invalid && (orgForm.controls['state'].dirty || orgForm.controls['state'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['state'].errors?.required"> State is required. </div>
                </div>
              </div>
              <div class="form-holder">
                <input type="text" class="form-control" formControlName="city" placeholder="City" />
                <div
                  *ngIf="orgForm.controls['city'].invalid && (orgForm.controls['city'].dirty || orgForm.controls['city'].touched)"
                  class="error_msg">
                  <div *ngIf="orgForm.controls['city'].errors?.required"> City is required. </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder">
                <select class="form-control" [(ngModel)]="selectedUserName" #name="ngModel"
                  [ngModelOptions]="{standalone: true}">
                  <option value="select" selected disabled label="Select Username as..."></option>
                  <option value="email">Email ID</option>
                  <option value="phone">Contact No</option>
                </select>
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error_msg">
                  <span *ngIf="name.errors.required">Please select Option!</span>
                </div>
              </div>
              <div class="form-holder">
                <button type="submit" class="btn btn-info" [disabled]="orgForm.pristine || !orgForm.valid">
                  Submit</button>&nbsp;&nbsp;
                <a routerLink="/login" class="btn btn-success btn-sm">
                  Back to Login</a> </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>