import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listdashboard',
  templateUrl: './listdashboard.component.html',
  styleUrls: ['./listdashboard.component.css']
})
export class ListdashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
