import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { configuration } from 'src/app/config/config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ObjectServiceService {

  constructor(private http: HttpClient) { }

  // Url to access our Web API’s
  private baseUrl: string = `${localStorage.getItem("serverUrl")}/schemas/`;

  getSingleObject(classNm: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    return this.http.get<any>(this.baseUrl + classNm, httpOptions).pipe(
      map(result => { return result; })
    );

  }

  getClassData(classNm: string) {

    this.baseUrl = `${localStorage.getItem("serverUrl")}/classes/${classNm}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    return this.http.get<any>(this.baseUrl, httpOptions).pipe(
      map(result => { return result; }
      ));

  }

  async getClassList(body: any): Promise<any> {

    //here body param will have appname as input
    const httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/Classlist`;
    let result: any;

    console.log("await method starts...");
    result = await this.http.post(url, body, httpOptions2).toPromise();
    console.log("await method called");
    return result;

  }

  async getTargetClassList(body: any) {

    //here body param will have appname as input
    const httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/TargetClasslist`;
    return await this.http.post(url, body, httpOptions2).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error["error"];
      });

  }

  async getSchemaByClass(body: any) {

    //here body param will have classname as input
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/schemas`;
    return await this.http.post(url, body, httpOptions1).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error["error"];
      });

  }

  async addSchema(body: any) {

    //here body param will have schema structure to add
    const httpOptions4 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };
    debugger;
    var url = `${localStorage.getItem("serverUrl")}/functions/AddSchemas`;
    return await this.http.post(url, body, httpOptions4).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error["error"];
      });

  }

  async updateSchema(body: any) {

    //here body param will have schema structure to update
    const httpOptions3 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/UpdateSchemas`;
    return await this.http.post(url, body, httpOptions3).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error["error"];
      });

  }

  async deleteSchema(body: any) {

    //here body param will have schema structure to delete
    const httpOptions5 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/DeleteSchemas`; configuration.SERVER_URL + "/functions/DeleteSchemas";
    return await this.http.post(url, body, httpOptions5).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error["error"];
      });

  }

  async getRolesByAppname(appname: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
      })
    };

    let url = `${localStorage.getItem("serverUrl")}/roles?where={"name":{"$regex":"${appname}","$ne":"${appname}appeditor"}}`;
    return await this.http.get(url, httpOptions).toPromise().then(
      (result => {
        return result;
      })
    );

  }

  async deleteClassRecord(className: string, id: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/classes/${className}/${id}`;
    return await this.http.delete<any>(url, httpOptions).toPromise().then(result => {
      return result;
    }, (error) => {
      return error;
    });

  }

  async updateClassRecord(body: any, id: string, className: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/classes/${className}/${id}`;
    return await this.http.put<any>(url, body, httpOptions).toPromise().then(result => {
      return result;
    }, (error) => {
      return error;
    });

  }

  async deleteRole(objectId: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/roles/${objectId}`;
    return await this.http.delete<any>(url, httpOptions).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error;
      });

  }

  async moveUserToRole(body: any) {

    const httpOptions3 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/MoveUserToRole`;
    return await this.http.post(url, body, httpOptions3).toPromise().then(
      result => {
        console.log(result);
        return result;
      }, (error) => {
        console.log(error);
        return error["error"];
      });

  }

  async addUserToRole(body: any) {

    const httpOptions3 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/AddUserToRole`;
    return await this.http.post(url, body, httpOptions3).toPromise().then(
      result => {
        console.log(result);
        return result;
      }, (error) => {
        console.log(error);
        return error["error"];
      });

  }

  async userRoles(userId: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/roles?where={"users":{"__type":"Pointer","className":"_User","objectId":"${userId}"}}`;
    console.log(url);
    return await this.http.get(url, httpOptions).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error;
      });

  }

  async deleteUserFromRole(body: any) {

    const httpOptions5 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/DeleteUserFromRole`;
    return await this.http.post(url, body, httpOptions5).toPromise().then(
      result => {
        return result;
      }, (error) => {
        return error["error"];
      });

  }

  async deployFunction(body: any) {

    //here body param will have appname as input
    const httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'sessionToken': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/functions/deployFunction`;
    return await this.http.post(url, body, httpOptions2).toPromise().then(
      result => {
        return result;
      }, (error) => {
        console.log(error);
        return error;
      });

  }

  async getOrgList(orgIds: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    var url = `${localStorage.getItem("serverUrl")}/classes/w_organization?where={"objectId":{"$in":${orgIds}}}`;
    console.log(url);
    return await this.http.get(url, httpOptions).toPromise().then(
      result => {
        return result["results"];
      }, (error) => {
        return error;
      });

  }

  getAggregateData(query: string) {

    this.baseUrl = `${localStorage.getItem("serverUrl")}/functions/UniqueQuery`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Parse-Application-Id': localStorage.getItem("parseKey"),
        'X-Parse-Session-Token': localStorage.getItem("accessToken")
      })
    };

    return this.http.post(this.baseUrl, query, httpOptions).toPromise().then(
      result => {
        return result["result"];
      }, (error) => {
        console.log(error);
        return error;
      });

  }

}
