import { Injectable } from "@angular/core";

@Injectable()

export class Controls {
    controls: any = [
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "textbox",
            controlType: [
                { "type": "text" },
                { "type": "email" },
                // { "type": "url" },
                { "type": "number" },
                { "type": "tel" },
                { "type": "date" },
                { "type": "password" },
                { "type": "color" },
                /*{ "type": "datetime-local" },
                { "type": "hidden" },
                { "type": "month" },*/
                { "type": "range" }
                /*{ "type": "search" },
                { "type": "time" },
                { "type": "week" }*/
            ],
            controlProperty: [
                { "title": "" },
                { "placeholder": "" },
                { "minlength": "0" },
                { "maxLength": "50" },
                { "autofocus": "false" },
                { "enabled": "true" },
                { "help_text": "" },
                { "description": "" }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "textarea",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "placeholder": "" },
                { "minlength": "0" },
                { "maxLength": "500" },
                { "rows": "2" },
                { "autofocus": "false" },
                { "enabled": "true" },
                { "help_text": "" },
                { "description": "" }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "dropdown",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "binding_type": [] },
                { "enum": [] },
                { "enumNames": [] },
                { "query": [] },
                { "default": "Select" },
                { "autofocus": "false" },
                { "enabled": "true" },
                { "help_text": "" },
                { "description": "" }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "checkbox",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "binding_type": [] },
                { "enum": [] },
                { "enumNames": [] },
                { "query": [] },
                { "autofocus": "false" },
                { "enabled": "true" },
                { "help_text": "" },
                { "description": "" },
                { "isSortable": "false" },
                { "selectAll": "false" },
                { "selectedData": [] }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "radio",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "binding_type": [] },
                { "enum": [] },
                { "enumNames": [] },
                { "query": [] },
                { "autofocus": "false" },
                { "enabled": "true" },
                { "help_text": "" },
                { "description": "" }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "image",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "filetypes": [] },
                { "enableWebcam": "false" },
                { "fileSize": "" },
                { "exactSize": {} },
                { "width": "" }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "hiddenField",
            controlType: [],
            controlProperty: [
                { "hiddendata": [] }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "autoIncrement",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "autodata": [] }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "advancetextbox",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "defaultcountry": "" },
                { "advancedata": [] },
                { "defaultcurrency": "" },
                { "allowDecimals": "false" },
                { "showTimeSelect": "false" },
                { "showTimeSelectOnly": "false" },
                { "minDate": "2" },
                { "maxDate": "2" },
                { "timeIntervals": "2" },
                { "timeCaption": "Time" },
                { "timeFormat": "" },
                { "dateFormat": "" },
                { "decimalsLimit": "2" },
                { "maxlength": "7" },
                // {"currencyColumn":""},
                { "minlength": "3" },
                { "placeholder": "" },
                { "helpbody": "" },
                { "helplink": "" },
                { "enabled": "true" }
            ],
            component: [
                { "type": "MobileNumber" },
                { "type": "AutoSuggest" },
                { "type": "AutoCapitalise" },
                { "type": "ShowPercentSign" },
                { "type": "GeoLocation" },
                { "type": "CurrencyInput" },
                { "type": "TextBox" },
                { "type": "TextArea" },
                { "type": "DateTime" },
                { "type": "HtmlEditor" }
            ]
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "file",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "filetypes": [] },
                { "maxfilesizeKB": "" }
            ],
            component: [],
            uploadTypeData: [
                { "type": "regular" },
                { "type": "directtos3" },
                { "type": "s3viajw" }
            ]
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "subform",
            controlType: [],
            controlProperty: [
                { "title": "" }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "folder",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "folderdata": [] }
            ],
            component: [],
            uploadTypeData: []
        },
        {
            controlId: (String(Math.floor(100000 + Math.random() * 900000))).substring(0, 4),
            controlName: "arrayofobjects",
            controlType: [],
            controlProperty: [
                { "title": "" },
                { "addable": "false" },
                { "orderable": "false" },
                { "removable": "false" },
                { "minItems": "0" }
            ],
            component: [],
            uploadTypeData: []
        }
    ]
}
