import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, Event, NavigationEnd, RouterEvent } from '@angular/router';
import { DataSharingService } from 'src/app/shared/data-sharing.service';
import { configuration } from './config/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {

  title = 'XAP.AI-Admin'; accessType: string;

  // Define a variable to use for showing/hiding the Login button
  isUserLoggedIn: boolean; loader = true;

  timeout;

  constructor(private dataSharingService: DataSharingService, private route: Router) {

    // Subscribe here, this will automatically update 
    // "isUserLoggedIn" whenever a change to the subject is made.
    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
    });

    this.dataSharingService.userType.subscribe(value => {
      this.accessType = value;
    });

    route.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });

    console.log(this.accessType);

    var url = window.location.hostname;
    console.log(url);
    if (url == 'appcreator.qik.ai') {
      //commenting out this code as it setting localstorage even when its not neeed and interfering with the initial setup
      //localStorage.setItem("serverUrl", configuration.SERVER_URL);
      //localStorage.setItem("parseKey", configuration.PARSE_KEY);
      localStorage.setItem("imgUrl", configuration.IMG_URL);
      localStorage.setItem("urlOnly", configuration.sURL);
      localStorage.setItem("platform", "prod");
      localStorage.setItem("target", configuration.sTarget);
    } else if (url == 'uat-appcreator.qik.ai') {
      localStorage.setItem("serverUrl", configuration.UAT_SERVER_URL);
      localStorage.setItem("parseKey", configuration.UAT_PARSE_KEY);
      localStorage.setItem("imgUrl", configuration.UAT_IMG_URL);
      localStorage.setItem("urlOnly", configuration.uURL);
      localStorage.setItem("platform", "uat");
      localStorage.setItem("target", configuration.uTarget);
    } else {
      localStorage.setItem("serverUrl", configuration.DEV_SERVER_URL);
      localStorage.setItem("parseKey", configuration.DEV_PARSE_KEY);
      localStorage.setItem("imgUrl", configuration.DEV_IMG_URL);
      localStorage.setItem("urlOnly", configuration.dURL);
      localStorage.setItem("platform", "dev");
      localStorage.setItem("target", configuration.dTarget);
    }
    console.log(localStorage.getItem("serverUrl"));
    console.log(localStorage.getItem("parseKey"));
    console.log(localStorage.getItem("imgUrl"));
    console.log(localStorage.getItem("platform"));
    console.log(localStorage.getItem("target"));

  }

  ngOnInit() { }

  clickApp() { this.route.navigate(['appInfo']); }

  clickQApp() { this.route.navigate(['queryBuilder']); }

  clickFilterApp() { this.route.navigate(['filterBuilder']); };

  clickMApp() { this.route.navigate(['mobile']); }

  clickSchema() { this.route.navigate(['validateSchemas']) };

  clickEditor() { this.route.navigate(['appEditors']) };

  clickOrg() { this.route.navigate(["orgInfo"]) };

  clickPlans() { this.route.navigate(['plans']) };

  navigationInterceptor(event: Event): void {

    if (event instanceof NavigationStart) { this.loader = true; }

    if (event instanceof NavigationEnd) {
      // Hide loading indicator
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout);
        this.loader = false;
      }, 1000);

    }

  }

}
