<div class="f-div1">
    <div class="row" style="margin-top: 11px;">
        <div class="col-lg-3 col-md-3 col-sm-3 col-12 ">
            <h5>Organization Details</h5><br>
        </div>
    </div>
    <div class="row">
        <div class="card card-box" style="padding-top:20px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <form [formGroup]="orgForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-holder">Organization Name <span class="reduvs">*</span>
                                <input type="text" class="form-control" formControlName="orgName"
                                    placeholder="Organization Name" disabled />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-holder">Country
                                <input type="text" class="form-control" formControlName="country" placeholder="Country"
                                    disabled />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-holder">State
                                <input type="text" class="form-control" formControlName="state" placeholder="State"
                                    disabled />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-holder">City
                                <input type="text" class="form-control" formControlName="city" placeholder="City"
                                    disabled />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-holder">Pincode <span class="reduvs">*</span>
                                <input type="number" class="form-control" formControlName="pincode"
                                    placeholder="Pincode" disabled />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-holder">Address <span class="reduvs">*</span>
                                <textarea class="form-control" formControlName="address" placeholder="Address"
                                    disabled></textarea>
                            </div>
                        </div>
                        <div class="col-md-12 text-right">
                            <div class="form-group"> <button type="button" class="btn btn-info" (click)="editDetails()">
                                    Edit</button>
                                <br /><br />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="f-div2">
    <div class="row" style="margin-top: 11px;">
        <div class="col-lg-3 col-md-3 col-sm-3 col-12 ">
            <h5>Edit Organization</h5><br>
        </div>
    </div>
    <div class="row">
        <div class="card card-box" style="padding-top:20px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <form [formGroup]="orgForm" (ngSubmit)="updateOrganization(orgForm.value)">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-holder">Organization Name <span class="reduvs">*</span>
                                <input type="text" class="form-control" formControlName="orgName" disabled />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-holder">Country
                                <input type="text" class="form-control" formControlName="country" />
                                <div *ngIf="orgForm.controls['country'].invalid && (orgForm.controls['country'].dirty || orgForm.controls['country'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="orgForm.controls['country'].errors?.required"> Country is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-holder">State
                                <input type="text" class="form-control" formControlName="state" />
                                <div *ngIf="orgForm.controls['state'].invalid && (orgForm.controls['state'].dirty || orgForm.controls['state'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="orgForm.controls['state'].errors?.required"> State is required. </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-holder">City
                                <input type="text" class="form-control" formControlName="city" />
                                <div *ngIf="orgForm.controls['city'].invalid && (orgForm.controls['city'].dirty || orgForm.controls['city'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="orgForm.controls['city'].errors?.required"> City is required. </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-holder">Pincode <span class="reduvs">*</span>
                                <input type="number" class="form-control" formControlName="pincode" />
                                <div *ngIf="orgForm.controls['pincode'].invalid && (orgForm.controls['pincode'].dirty || orgForm.controls['pincode'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="orgForm.controls['pincode'].errors?.required"> Pincode is required.
                                    </div>
                                    <div *ngIf="orgForm.controls['pincode'].errors?.maxlength"> Invalid Pincode! </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-holder">Address <span class="reduvs">*</span>
                                <textarea class="form-control" cols="1" rows="4" formControlName="address"
                                    placeholder="Address"></textarea>
                                <div *ngIf="orgForm.controls['address'].invalid && (orgForm.controls['address'].dirty || orgForm.controls['address'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="orgForm.controls['address'].errors?.required"> Address is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-right">
                                <div class="form-group">
                                    <button type="submit" class="btn btn-info">Update</button> &nbsp;&nbsp;
                                    <button type="button" class="btn btn-warning" (click)="showDetails()">Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>