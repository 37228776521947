import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import * as Parse from 'parse';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  emailForm: FormGroup;

  constructor(private fb: FormBuilder, private spinnerService: Ng4LoadingSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit() {

    this.spinnerService.show();
    Parse.initialize(localStorage.getItem("parseKey"));
    (Parse as any).serverURL = localStorage.getItem("serverUrl");

    // Initialize FormGroup using FormBuilder
    this.emailForm = this.fb.group({
      Username: ['', Validators.required]
    });
    this.spinnerService.hide();

  }

  async onFormSubmit(emailForm: NgForm) {

    this.spinnerService.show();
    debugger;
    await Parse.User.requestPasswordReset(emailForm["Username"])
      .then((res) => {
        console.log(res);
        if (Object.keys(res).length == 0) { this.toastr.success("Reset password link has been sent to your email id "); }
        // Password reset request was sent successfully
      }).catch((error) => {
        // Show the error message somewhere
        this.toastr.error("Error: " + error.code + " " + error.message);
      });
    this.spinnerService.hide();

  }

}
