import { Component, OnInit } from '@angular/core';
import { SetFooterService } from 'src/app/shared/set-footer.service';
import { SetFooterVersionService } from 'src/app/shared/set-footer-version.service';
import {environment} from 'src/app/environment';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.css']
})

export class PageFooterComponent implements OnInit {

  appName: any; message: string; _version: any;
  public appVersion: string = environment.VERSION;

  constructor(private data: SetFooterService, private ver: SetFooterVersionService) {
    
    this.data.currentMessage().subscribe(message => {
      if (localStorage.getItem('appName') == null) {       this.appName = message;      } 
      else {        this.appName = localStorage.getItem('appName');      }
    });

  }

  ngOnInit() {
  }

}
