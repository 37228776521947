<div class="f-div1">
  <div class="row" style="margin-top: 11px;">
    <div class="col-lg-3 col-md-3 col-sm-3 col-12 ">
      <h5>Profile Details</h5><br>
    </div>
  </div>
  <div class="row">
    <div class="card card-box" style="padding-top:20px;">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <form [formGroup]="profileForm">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
              <div class="form-holder">Email ID<span class="reduvs">*</span>
                <input type="email" class="form-control" formControlName="username" placeholder="Email ID*" disabled />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
              <div class="form-holder">Name<span class="reduvs">*</span>
                <input type="text" class="form-control" formControlName="name" placeholder="Name*" />
                <div
                  *ngIf="profileForm.controls['name'].invalid && (profileForm.controls['name'].dirty || profileForm.controls['name'].touched)"
                  class="alert alert-danger">
                  <div *ngIf="profileForm.controls['name'].errors?.required"> Name is required. </div>
                  <div *ngIf="profileForm.controls['name'].errors?.pattern"> Valid characters: Alphabets and Spaces!
                  </div>
                  <div *ngIf="profileForm.controls['name'].errors?.maxlength"> 100 characters are allowed! </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
              <div class="form-holder">Contact No.<span class="reduvs">*</span>
                <input type="number" class="form-control" formControlName="phone" placeholder="Contact No.*"
                  maxlength="10" />
                <div
                  *ngIf="profileForm.controls['phone'].invalid && (profileForm.controls['phone'].dirty || profileForm.controls['phone'].touched)"
                  class="alert alert-danger">
                  <div *ngIf="profileForm.controls['phone'].errors?.required"> Contact No. is required. </div>
                  <div *ngIf="profileForm.controls['phone'].errors?.pattern"> Invalid Contact No.! </div>
                  <div *ngIf="profileForm.controls['phone'].errors?.maxlength"> Invalid Contact No. ! </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-right">
              <div class="form-group"> <button type="button" class="btn btn-info" (click)="editDetails()"> Edit</button>
                <br /><br />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="f-div2">
  <div class="row" style="margin-top: 11px;">
    <div class="col-lg-3 col-md-3 col-sm-3 col-12 ">
      <h5>Edit Profile</h5><br>
    </div>
  </div>
  <div class="row">
    <div class="card card-box" style="padding-top:20px;">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <form [formGroup]="profileForm" (ngSubmit)="updateProfile(profileForm.value)">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
              <div class="form-holder">Email ID <span class="reduvs">*</span>
                <input type="text" class="form-control" formControlName="username" disabled />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="form-holder"> Name <span class="reduvs">*</span>
                <input type="text" class="form-control" formControlName="name" />
                <div
                  *ngIf="profileForm.controls['name'].invalid && (profileForm.controls['name'].dirty || profileForm.controls['name'].touched)"
                  class="error_msg alert alert-danger">
                  <div *ngIf="profileForm.controls['name'].errors?.required"> Contact Person Name is required.
                  </div>
                  <div *ngIf="profileForm.controls['name'].errors?.pattern"> Valid characters: Alphabets and
                    Spaces!
                  </div>
                  <div *ngIf="profileForm.controls['name'].errors?.maxlength"> 100 characters are allowed! </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 ">
              <div class="form-holder"> Contact No. <span class="reduvs">*</span>
                <input type="number" class="form-control" formControlName="phone" maxlength="10" />
                <div
                  *ngIf="profileForm.controls['phone'].invalid && (profileForm.controls['phone'].dirty || profileForm.controls['phone'].touched)"
                  class="alert alert-danger">
                  <div *ngIf="profileForm.controls['phone'].errors?.required"> Contact No. is required. </div>
                  <div *ngIf="profileForm.controls['phone'].errors?.pattern"> Invalid Contact No.! </div>
                  <div *ngIf="profileForm.controls['phone'].errors?.maxlength"> Invalid Contact No. ! </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-info">Update</button> &nbsp;&nbsp;
                <button type="button" class="btn btn-warning" (click)="showDetails()">Details </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>