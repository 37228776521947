export const configuration = {
   //server config
   SERVER_URL: 'https://server3.qik.ai/thirdapp',
   PARSE_KEY: 'qbKGrKRiIDFxqrYhcIkY',
   IMG_URL: 'https://server3.qik.ai/thirdapp/image_upload',
   sURL: 'https://server3.qik.ai/thirdapp',
   sTarget: '$production',
   buildAPKURL: 'http://ci.nxglabs.in/job/NXGLabsGithub/job/Xap.ai-Android/job/master/buildWithParameters?',

   //uat config
   UAT_SERVER_URL: 'https://uat-server.qik.ai/app',
   UAT_PARSE_KEY: 'nxg-parse-appid',
   UAT_IMG_URL: 'https://uat-server.qik.ai/image_upload',
   uURL: 'https://uat-server.qik.ai',
   uTarget: '$uat',
   UAT_buildAPKURL: 'http://ci.nxglabs.in/job/NXGLabsGithub/job/Xap.ai-Android/job/uat/buildWithParameters?',

   // UAT_SERVER_URL: 'https://server.qik.ai/app',
   //  UAT_PARSE_KEY: 'shbjmmhfcp',
   //  UAT_IMG_URL: 'https://server.qik.ai/image_upload',
   //  uURL: 'https://server.qik.ai',
   //  uTarget: '$uat',
   //  UAT_buildAPKURL: 'http://ci.nxglabs.in/job/NXGLabsGithub/job/Xap.ai-Android/job/uat/buildWithParameters?',


   //dev config

   //  DEV_SERVER_URL: 'https://uat-server.qik.ai/app',
   //  DEV_PARSE_KEY: 'nxg-parse-appid',
   //  DEV_IMG_URL: 'https://uat-server.qik.ai/image_upload',
   //  dURL: 'https://uat-server.qik.ai',
   //  dTarget: '$uat',
   //  DEV_buildAPKURL: 'http://ci.nxglabs.in/job/NXGLabsGithub/job/Xap.ai-Android/job/dev/buildWithParameters?',

   DEV_SERVER_URL: 'https://server2.qik.ai/app',
   DEV_PARSE_KEY: 'shbjnnhfcp',
   DEV_IMG_URL: 'https://server2.qik.ai/image_upload',
   dURL: 'https://server2.qik.ai',
   dTarget: '$production',
   DEV_buildAPKURL: 'http://ci.nxglabs.in/job/NXGLabsGithub/job/Xap.ai-Android/job/master/buildWithParameters?',


   Build_Authorization: 'Basic YW1vbEBueGdsYWJzLmluOjExOTA4ZjUzN2ZmOTk2NDEwYzc4ZjA2MjUzODg4NjI0NzY=',
   //: 'http://ci.nxglabs.in/job/NXGLabsGithub/job/Xap.ai-Android/job/master/buildWithParameters?'

};
